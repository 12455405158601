import { IconType } from 'react-icons';
import * as React from 'react';

// util to convert svg icons to IconType from react-icons
export function wrapIcon(icon: React.FC<React.SVGProps<SVGSVGElement>>) {
  const WrappedIcon: IconType = ({ size = 20, ...rest }) => {
    const Icon = icon;
    return React.createElement(Icon, { height: size, width: size, ...rest });
  };

  (WrappedIcon as React.FC).displayName = icon.displayName;

  return WrappedIcon;
}

// merge multiple react refs
// https://github.com/gregberge/react-merge-refs/blob/master/src/index.js
export function mergeRefs(refs: any[]) {
  return (value: any) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        ref.current = value;
      }
    });
  };
}
