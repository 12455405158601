/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type QueryInput = {
    entityId: string;
    sessionId?: string | null;
};
export type AppOrganizationQueryVariables = {
    input: QueryInput;
};
export type AppOrganizationQueryResponse = {
    readonly organization: {
        readonly " $fragmentRefs": FragmentRefs<"SideNav_organization">;
    } | null;
};
export type AppOrganizationQuery = {
    readonly response: AppOrganizationQueryResponse;
    readonly variables: AppOrganizationQueryVariables;
};



/*
query AppOrganizationQuery(
  $input: QueryInput!
) {
  organization(input: $input) {
    ...SideNav_organization
    id
  }
}

fragment OrgDropdown_organization on Organization {
  logo
  name
}

fragment SideNav_organization on Organization {
  ...OrgDropdown_organization
  offices {
    id
    name
    floorplans {
      id
      name
      floor {
        index
        id
      }
      boundary {
        lineStrings {
          __typename
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AppOrganizationQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "SideNav_organization"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AppOrganizationQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "logo",
                            "storageKey": null
                        },
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Office",
                            "kind": "LinkedField",
                            "name": "offices",
                            "plural": true,
                            "selections": [
                                (v3 /*: any*/),
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Floorplan",
                                    "kind": "LinkedField",
                                    "name": "floorplans",
                                    "plural": true,
                                    "selections": [
                                        (v3 /*: any*/),
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Floor",
                                            "kind": "LinkedField",
                                            "name": "floor",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "index",
                                                    "storageKey": null
                                                },
                                                (v3 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Polygon",
                                            "kind": "LinkedField",
                                            "name": "boundary",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "LineString",
                                                    "kind": "LinkedField",
                                                    "name": "lineStrings",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "__typename",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "45938c35a901fd156c38cb578da4d608",
            "id": null,
            "metadata": {},
            "name": "AppOrganizationQuery",
            "operationKind": "query",
            "text": "query AppOrganizationQuery(\n  $input: QueryInput!\n) {\n  organization(input: $input) {\n    ...SideNav_organization\n    id\n  }\n}\n\nfragment OrgDropdown_organization on Organization {\n  logo\n  name\n}\n\nfragment SideNav_organization on Organization {\n  ...OrgDropdown_organization\n  offices {\n    id\n    name\n    floorplans {\n      id\n      name\n      floor {\n        index\n        id\n      }\n      boundary {\n        lineStrings {\n          __typename\n        }\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'b37760b9809f87f97478967f93438d30';
export default node;
