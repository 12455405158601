import * as React from 'react';
import { Switch, useHistory } from 'react-router-dom';
import graphql from 'babel-plugin-relay/macro';
import { useFragment, fetchQuery, useRelayEnvironment } from 'react-relay/hooks';
import { readInlineData, Environment } from 'react-relay';
import { createOperationDescriptor, getRequest } from 'relay-runtime';
import useAsyncFn from 'react-use/lib/useAsyncFn';

import Layout from './components/Layout';
import Loading from './components/Loading';
import FillCenter from './components/FillCenter';
import SideNav from './components/SideNav';
import { routes, ROOT } from './routes';
import AppRoute from './components/AppRoute';
import { UserType } from './graphql/generated';
import {
  setCurrentAccount as lsSetCurrentAccount,
  getCurrentAccount as lsGetCurrentAccount,
  getCurrentOrg as lsGetCurrentOrg,
  setCurrentOrg as lsSetCurrentOrg,
} from './utils/localStorageUtils';
import { Analytics } from './services/analytics';
import { EngagementService } from './services/engagement';
import { App_initializeServices_user$key } from './__generated__/App_initializeServices_user.graphql';
import { App_user$key } from './__generated__/App_user.graphql';
import { useTreatment_DEPRECATED } from './contexts/treatmentsContext';
import { SPLITS } from './constants/featureFlipperConstants';
import { useAuth } from './contexts/authContext';
import { AppOrganizationQuery } from './__generated__/AppOrganizationQuery.graphql';
import { ErrorReporting } from './services/errorReporting';

type AppContextType = {
  userId?: string;
  customerId?: string;
  selectedOrganizationId?: string;
  currentAccount?: UserType;
  selectOrganization: (id: string) => void;
  switchAccounts: (type: UserType, pushRoot?: boolean) => void;
};

const AppContext = React.createContext<AppContextType | undefined>(undefined);

export const useApp = () => {
  const context = React.useContext(AppContext);
  if (!context) {
    throw new Error('useApp must be used within App');
  }
  return context;
};

const fetchOrganizationAsync = async (environment: Environment, id: string) => {
  const query = graphql`
    query AppOrganizationQuery($input: QueryInput!) {
      organization(input: $input) {
        ...SideNav_organization
      }
    }
  `;

  const variables = {
    input: {
      entityId: id,
    },
  };

  const request = getRequest(query);
  const operation = createOperationDescriptor(request, variables);
  environment.retain(operation);

  const response = await fetchQuery<AppOrganizationQuery>(environment, query, variables).toPromise();

  return response?.organization;
};

const initializeServices = (userRef: App_initializeServices_user$key) => {
  const user = readInlineData(
    graphql`
      fragment App_initializeServices_user on User @inline {
        id
        firstName
        name
        email

        organizations {
          id
          name
          headcount
        }
      }
    `,
    userRef
  );

  Analytics.identify(user.id);
  Analytics.addUserProperties({
    companyId: user.organizations[0].id,
    companyName: user.organizations[0].name,
    firstName: user.firstName,
    orgSize: user.organizations[0].headcount,
  });

  EngagementService.identify({
    name: user.name,
    email: user.email,
  });

  ErrorReporting.identify({
    id: user.id,
    email: user.email,
    name: user.name,
  });
};

type AppProps = {
  user?: App_user$key;

  firstOrganizationId?: string;
};

const App: React.FC<AppProps> = (props) => {
  const history = useHistory();
  const auth = useAuth();
  const environment = useRelayEnvironment();

  const [orgState, startFetchOrg] = useAsyncFn(fetchOrganizationAsync, [], { loading: true });

  const isOrgChooserOn = useTreatment_DEPRECATED(SPLITS.ORGANIZATION_CHOOSER);
  const isEEV2ReservationOn = useTreatment_DEPRECATED(SPLITS.EEV2_RESERVATION);

  const [selectedOrganizationId, setSelectedOrganizationId] = React.useState<string>();
  const [currentAccount, setCurrentAccount] = React.useState<UserType>();
  const [hideSideNav, setHideSideNav] = React.useState(false);

  const derivedSelectedOrganizationId = React.useMemo(() => {
    if (selectedOrganizationId) {
      return selectedOrganizationId;
    }

    const persistedOrgId = lsGetCurrentOrg();
    if (isOrgChooserOn && persistedOrgId) {
      return persistedOrgId;
    }

    return props.firstOrganizationId;
  }, [isOrgChooserOn, props.firstOrganizationId, selectedOrganizationId]);

  const user = useFragment(
    graphql`
      fragment App_user on User {
        id
        type

        customer {
          id
        }

        ...App_initializeServices_user
        ...SideNav_user
      }
    `,
    props.user || null
  );

  const isAppStateReady = !!currentAccount && !!derivedSelectedOrganizationId && !!user?.id;

  React.useEffect(
    function fetchOrg() {
      if (auth.isAuthenticated && derivedSelectedOrganizationId) {
        startFetchOrg(environment, derivedSelectedOrganizationId);
      }
    },
    [auth.isAuthenticated, environment, derivedSelectedOrganizationId, startFetchOrg]
  );

  React.useEffect(
    function initializeAppState() {
      if (!user) {
        return;
      }

      // initialize analytics and error reporting
      initializeServices(user);

      // if is wom, default to wom account
      if (user.type === UserType.TypeAdmin) {
        if (!isEEV2ReservationOn) {
          const persistedCurrentAccount = lsGetCurrentAccount();
          const currentAccount = persistedCurrentAccount || UserType.TypeAdmin;
          setCurrentAccount(currentAccount);
          lsSetCurrentAccount(currentAccount);
        } else {
          setCurrentAccount(UserType.TypeAdmin);
        }
      } else {
        setCurrentAccount(UserType.TypeEmployee);
        lsSetCurrentAccount(UserType.TypeEmployee);
      }
    },
    [isEEV2ReservationOn, user]
  );

  const renderSideNav = React.useCallback(() => {
    if (!orgState.value || !user || hideSideNav) {
      // TODO: need placeholder for SideNav
      return;
    }

    return (
      // TODO: need design for sidenav loading state
      <React.Suspense fallback={null}>
        <SideNav organization={orgState.value} user={user} />
      </React.Suspense>
    );
  }, [hideSideNav, orgState.value, user]);

  const renderContent = React.useCallback(() => {
    return (
      <React.Suspense
        fallback={
          <FillCenter>
            <Loading />
          </FillCenter>
        }
      >
        <Switch>
          {routes.map((route, i) => (
            // NOTE: set the isReady prop since for all pages, we need currentAccount and selectedOrganizationId
            <AppRoute key={i} {...route} isReady={isAppStateReady} onHideSideNavChange={setHideSideNav} />
          ))}
        </Switch>
      </React.Suspense>
    );
  }, [isAppStateReady]);

  React.useEffect(
    function throwError() {
      if (orgState.error) {
        throw orgState.error;
      }
    },
    [orgState.error]
  );

  return (
    <AppContext.Provider
      value={{
        userId: user?.id,
        customerId: user?.customer.id,
        currentAccount,
        selectedOrganizationId: derivedSelectedOrganizationId,
        selectOrganization: (id) => {
          lsSetCurrentOrg(id);
          setSelectedOrganizationId(id);
          history.push(ROOT);
        },
        switchAccounts: (type, pushRoot = true) => {
          if (isEEV2ReservationOn) {
            return;
          }

          setCurrentAccount(type);
          lsSetCurrentAccount(type);
          if (pushRoot) {
            history.push(ROOT);
          }
        },
      }}
    >
      <Layout sideNav={renderSideNav()}>{renderContent()}</Layout>
    </AppContext.Provider>
  );
};

// App is the component that is at the children of all of our context providers
// this is a perf improvement so context changes don't every subtree that isnt subscribed
// insert tweet from dan abramov
// https://github.com/facebook/react/issues/15156
export default React.memo(App);
