import * as React from 'react';
import { Box } from '@chakra-ui/react';
import { FaRegUserCircle } from 'react-icons/fa';
import { IconType } from 'react-icons/lib';

import { presets } from '../theme';

const AvatarIcon: IconType = (props) => {
  return (
    <Box borderRadius="50%" backgroundColor={presets.BACKGROUND_COLOR}>
      <FaRegUserCircle {...props} />
    </Box>
  );
};

export default AvatarIcon;
