import * as React from 'react';
import { Box } from '@chakra-ui/react';

import { presets } from '../theme';

const Separator = () => <Box borderBottom={1} borderColor={presets.BORDER_COLOR} borderStyle="solid" />;

type MiddleSeparatorProps = {
  basis: number;
};

export const MiddleSeparator = (props: MiddleSeparatorProps) => (
  <Box position="relative">
    <Box position="absolute" height={`${props.basis}rem`} width="1px" left="50%" backgroundColor={presets.BORDER_COLOR}></Box>
  </Box>
);

export default Separator;
