// import './wdyr';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Router, Route, Switch } from 'react-router-dom';
import { IconContext } from 'react-icons';
import { ChakraProvider } from '@chakra-ui/react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ToastContainer } from 'react-toastify';
import { RelayEnvironmentProvider } from 'react-relay/hooks';
import 'normalize.css';
import 'react-toastify/dist/ReactToastify.css';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import RootErrorBoundary from '@app/components/RootErrorBoundary';
import { AuthProvider } from '@app/contexts/authContext';
import { NavProvider } from '@app/contexts/navContext';
import { HRDProvider } from '@app/contexts/hrdContext';
import GenericErrorBoundary from '@app/components/GenericErrorBoundary';
import { theme } from '@app/theme';
import { Analytics } from '@app/services/analytics';
import { anonEnvironment, environment } from '@app/RelayEnvironment';
import { DEFAULT_TOAST_OPTIONS } from '@app/utils/toastUtils';
import AppDataRoot from '@app/AppDataRoot';
import AnonApp from '@app/AnonApp';
import { ErrorReporting } from '@app/services/errorReporting';
import { ANON_ROOT } from '@app/routes';
import { history } from '@app/history';
import '@app/index.scss';

const AppWithProviders = () => (
  <RootErrorBoundary>
    <ChakraProvider theme={theme}>
      <IconContext.Provider value={{ className: 'react-icons' }}>
        <DndProvider backend={HTML5Backend}>
          <ToastContainer {...DEFAULT_TOAST_OPTIONS} />

          <GenericErrorBoundary>
            <Router history={history}>
              <Switch>
                <Route path={ANON_ROOT}>
                  {/* @ts-ignore FIXME: @types/react-relay isn't updated yet */}
                  <RelayEnvironmentProvider environment={anonEnvironment}>
                    <AnonApp />
                  </RelayEnvironmentProvider>
                </Route>

                <Route>
                  <HRDProvider>
                    <AuthProvider>
                      <NavProvider>
                        {/* @ts-ignore FIXME: @types/react-relay isn't updated yet */}
                        <RelayEnvironmentProvider environment={environment}>
                          <AppDataRoot />
                        </RelayEnvironmentProvider>
                      </NavProvider>
                    </AuthProvider>
                  </HRDProvider>
                </Route>
              </Switch>
            </Router>
          </GenericErrorBoundary>
        </DndProvider>
      </IconContext.Provider>
    </ChakraProvider>
  </RootErrorBoundary>
);

if (process.env.NODE_ENV === 'development' && new URLSearchParams(window.location.search).get('mock') === 'true') {
  const { worker } = require('./mocks/browser');
  worker.start();
}

// load analytics
Analytics.load();

// load error reporting
ErrorReporting.load();

const rootElement = document.getElementById('root');
if (rootElement?.hasChildNodes()) {
  ReactDOM.hydrate(<AppWithProviders />, rootElement);
} else {
  ReactDOM.render(<AppWithProviders />, rootElement);
}

// unregister service worker
serviceWorkerRegistration.unregister();
