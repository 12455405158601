import _cloneDeep from 'lodash.clonedeep';
import { validate } from 'uuid';

import {
  ACCESS_TOKEN_KEY,
  ID_TOKEN_KEY,
  LOGIN_COUNT_KEY,
  SIDE_NAV_KEY,
  LAST_FLOORPLAN_KEY,
  SIDE_NAV_SECTIONS_COLLAPSED_KEY,
  APP_VERSION_KEY,
  FLOORPLAN_PAGE_STATE_KEY,
  CURRENT_ACCOUNT,
  CURRENT_ORG,
  HIDDEN_OFFICE_IDS_KEY,
  REFRESH_COUNT_KEY,
} from '../constants/localStorageConstants';
import { VizLayerType } from '../types/vizTypes';
import { UserType } from '../graphql/generated';
import { ErrorReporting } from '../services/errorReporting';

export const setCurrentOrg = (id: string) => {
  localStorage.setItem(CURRENT_ORG, id);
};

export const getCurrentOrg = () => {
  const id = localStorage.getItem(CURRENT_ORG);
  if (id && validate(id)) {
    return id;
  }

  return null;
};

export const setCurrentAccount = (account: UserType) => {
  localStorage.setItem(CURRENT_ACCOUNT, account);
};

export const getCurrentAccount = () => {
  const account = localStorage.getItem(CURRENT_ACCOUNT);

  if (account === UserType.TypeAdmin || account === UserType.TypeEmployee) {
    return account;
  }

  return null;
};

export const getAccessToken = () => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  return accessToken;
};

export const setAccessToken = (accessToken: string) => {
  return localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
};

export const removeAccessToken = () => {
  return localStorage.removeItem(ACCESS_TOKEN_KEY);
};

export const getIdToken = () => {
  const idToken = localStorage.getItem(ID_TOKEN_KEY);
  return idToken;
};

export const setIdToken = (idToken: string) => {
  return localStorage.setItem(ID_TOKEN_KEY, idToken);
};

export const removeIdToken = () => {
  return localStorage.removeItem(ID_TOKEN_KEY);
};

export const incrementLoginCount = () => {
  localStorage.setItem(LOGIN_COUNT_KEY, String(getLoginCount() + 1));
};

export const getLoginCount = () => {
  const loginCount = localStorage.getItem(LOGIN_COUNT_KEY);

  if (!loginCount) {
    return 0;
  }

  let count = 0;
  try {
    count = parseInt(loginCount);
  } catch {
    count = 0;
  }

  count = Number.isNaN(count) ? 0 : count;

  return count;
};

export const removeLoginCount = () => {
  return localStorage.removeItem(LOGIN_COUNT_KEY);
};

export const incrementRefreshCount = () => {
  localStorage.setItem(REFRESH_COUNT_KEY, String(getRefreshCount() + 1));
};

export const getRefreshCount = () => {
  const refreshCount = localStorage.getItem(REFRESH_COUNT_KEY);

  if (!refreshCount) {
    return 0;
  }

  let count = 0;
  try {
    count = parseInt(refreshCount);
  } catch {
    count = 0;
  }

  count = Number.isNaN(count) ? 0 : count;

  return count;
};

export const removeRefreshCount = () => {
  return localStorage.removeItem(REFRESH_COUNT_KEY);
};

export const getSideNav = () => {
  return localStorage.getItem(SIDE_NAV_KEY);
};

export const hideSideNav = () => {
  localStorage.setItem(SIDE_NAV_KEY, 'hidden');
};

export const showSideNav = () => {
  localStorage.removeItem(SIDE_NAV_KEY);
};

const getSideNavSubsectionCollapsed: () => { [key: string]: boolean } = () => {
  try {
    return JSON.parse(localStorage.getItem(SIDE_NAV_SECTIONS_COLLAPSED_KEY) || '{}');
  } catch (e) {
    ErrorReporting.report(e);
  }
};

export const setSideNavSubsectionCollapsed = (floorplanId: string, isCollapsed: boolean) => {
  const collapsedFloorplans = getSideNavSubsectionCollapsed();
  if (isCollapsed) {
    collapsedFloorplans[floorplanId] = isCollapsed;
  } else if (collapsedFloorplans[floorplanId]) {
    delete collapsedFloorplans[floorplanId];
  }

  localStorage.setItem(SIDE_NAV_SECTIONS_COLLAPSED_KEY, JSON.stringify(collapsedFloorplans));
};

export const getSideNavSubsectionCollapsedForFloorplan = (floorplanId: string) => {
  return !!getSideNavSubsectionCollapsed()[floorplanId];
};

export const setLastFloorplan = (lastFloorplanId: string) => {
  localStorage.setItem(LAST_FLOORPLAN_KEY, lastFloorplanId);
};

export const getLastFloorplan = () => {
  return localStorage.getItem(LAST_FLOORPLAN_KEY);
};

export const removeLastFloorplan = () => {
  localStorage.removeItem(LAST_FLOORPLAN_KEY);
};

export const getAppVersion = () => {
  return localStorage.getItem(APP_VERSION_KEY);
};

export const setAppVersion = (version: string) => {
  localStorage.setItem(APP_VERSION_KEY, version);
};

export type FloorplanPageState = {
  view: 'seating' | 'spaces';
  layers: VizLayerType[];
};

export const DEFAULT_FLOORPLAN_PAGE_STATE: FloorplanPageState = {
  view: 'spaces',
  layers: [],
};

export const setFloorplanPageState = (state: FloorplanPageState) => {
  localStorage.setItem(FLOORPLAN_PAGE_STATE_KEY, JSON.stringify(state));
};

export const getFloorplanPageState = () => {
  const stateStr = localStorage.getItem(FLOORPLAN_PAGE_STATE_KEY);

  const state = _cloneDeep(DEFAULT_FLOORPLAN_PAGE_STATE);

  if (!stateStr) {
    return state;
  }

  try {
    const parsed = JSON.parse(stateStr);

    if (parsed.view === 'seating' || parsed.view === 'spaces') {
      state.view = parsed.view;
    }

    if (Array.isArray(parsed.layers)) {
      state.layers = parsed.layers;
    }
  } catch (e) {}

  return state;
};

export const getHiddenOfficeIds = (): string[] => {
  const hiddenOfficeIds = localStorage.getItem(HIDDEN_OFFICE_IDS_KEY);
  try {
    return hiddenOfficeIds ? JSON.parse(hiddenOfficeIds) : [];
  } catch (e) {
    return [];
  }
};

export const getRawHiddenOfficeIds = () => {
  return localStorage.getItem(HIDDEN_OFFICE_IDS_KEY);
};

export const setHiddenOfficeIds = (id: string) => {
  const ids = getHiddenOfficeIds();
  ids.push(id);
  localStorage.setItem(HIDDEN_OFFICE_IDS_KEY, JSON.stringify(ids));
};

export const clearHiddenOfficeIds = () => {
  localStorage.setItem(HIDDEN_OFFICE_IDS_KEY, JSON.stringify([]));
};

export const hasHiddenOfficeId = (id: string) => {
  const ids = getHiddenOfficeIds();
  return ids.includes(id);
};
