import * as React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import FillCenter from './components/FillCenter';
import Layout from './components/Layout';
import Loading from './components/Loading';
import { anonRoutes } from './routes';
import { EngagementService } from './services/engagement';

type AnonAppProps = {};

const AnonApp: React.FC<AnonAppProps> = (props) => {
  let { path: parentPath } = useRouteMatch();

  React.useEffect(function initializeServices() {
    EngagementService.identify({});
  }, []);

  return (
    <Layout>
      <React.Suspense
        fallback={
          <FillCenter>
            <Loading />
          </FillCenter>
        }
      >
        <Switch>
          {anonRoutes.map((route, i) => {
            const { path, ...rest } = route;

            return <Route key={i} path={parentPath + path} {...rest} />;
          })}
        </Switch>
      </React.Suspense>
    </Layout>
  );
};

export default React.memo(AnonApp);
