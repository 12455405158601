import * as React from 'react';
import auth0, { WebAuth } from 'auth0-js';
import { useHistory } from 'react-router-dom';
import add from 'date-fns/add';
import isAfter from 'date-fns/isAfter';

import * as routes from '../routes';
import Loading from '../components/Loading';
import FillCenter from '../components/FillCenter';
import {
  removeAccessToken as lsRemoveAccessToken,
  removeIdToken as lsRemoveIdToken,
  setAccessToken as lsSetAccessToken,
  setIdToken as lsSetIdToken,
} from '../utils/localStorageUtils';

import { useHRD } from './hrdContext';

const RETURN_TO_URL = 'https://nashi.io';

type AuthContextType = {
  isAuthenticated: boolean;
  isLoading: boolean;
  accessToken?: string;
  idToken?: string;
  login: (options?: auth0.AuthorizeOptions) => void;
  handleCallback: () => void;
  logout: () => void;
  checkSession: (targetUrl?: string) => void;
  error?: Error;
};

const AuthContext = React.createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};

// 30 seconds
const REFRESH_INTERVAL = 1000 * 30;

export const AuthProvider: React.FC = (props) => {
  const history = useHistory();
  const hrd = useHRD();

  const [isLoading, setIsLoading] = React.useState(false);
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [accessToken, setAccessToken] = React.useState<string>();
  const [idToken, setIdToken] = React.useState<string>();
  const [expiresAt, setExpiresAt] = React.useState<Date>();
  const [error, setError] = React.useState<Error>();

  const [webAuth, setWebAuth] = React.useState<WebAuth>();

  // clean url to remove the /callback
  React.useEffect(
    function cleanUrl() {
      if (!!error) {
        history.replace(routes.ROOT);
      }
    },
    [error, history]
  );

  // NOTE: only create webauth once
  React.useEffect(() => {
    if (!hrd.authConfig) {
      return;
    }

    if (webAuth) {
      return;
    }

    const newWebAuth = new WebAuth({
      domain: hrd.authConfig.domain,
      clientID: hrd.authConfig.clientId,
      redirectUri: hrd.authConfig.redirectUrl,
      audience: hrd.authConfig.audience,
      responseType: 'token id_token',
      scope: 'openid email profile',
    });

    (window as any).__NASHI_AUTH__ = newWebAuth;

    setWebAuth(newWebAuth);
  }, [hrd.authConfig, webAuth]);

  const parseAuthResult = React.useCallback(
    (err: auth0.Auth0Error | null, authResult: auth0.Auth0Result | null, onSuccess?: () => void) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        const { idToken, accessToken, expiresIn } = authResult;

        setAccessToken(accessToken);
        setIdToken(idToken);
        setExpiresAt(add(new Date(), { seconds: expiresIn }));

        lsSetAccessToken(accessToken);
        lsSetIdToken(idToken);
        onSuccess?.();
      } else if (err) {
        if (err.code === 'timeout' || err.code === 'login_required') {
          return;
        }

        setIsAuthenticated(false);
        setAccessToken(undefined);
        setIdToken(undefined);
        setIsLoading(false);
        setExpiresAt(undefined);

        lsRemoveAccessToken();
        lsRemoveIdToken();

        setError(new Error(err.error));
      }
    },
    []
  );

  const renewAuth = React.useCallback(() => {
    if (!webAuth) {
      return;
    }

    webAuth.checkSession({}, parseAuthResult);
  }, [parseAuthResult, webAuth]);

  React.useEffect(
    function renewAuthOnInterval() {
      const intervalId = setInterval(() => {
        if (!expiresAt) {
          return;
        }

        const now = new Date();

        // if we're within a minute of expiring, renew
        const expired = isAfter(add(now, { minutes: 1 }), expiresAt);
        if (expired) {
          renewAuth();
        }
      }, REFRESH_INTERVAL);

      return () => clearInterval(intervalId);
    },
    [expiresAt, renewAuth]
  );

  React.useEffect(
    function throwError() {
      if (error) {
        throw error;
      }
    },
    [error]
  );

  const checkSession = React.useCallback(
    (targetUrl?: string, onSuccess?: () => void, onFail?: () => void) => {
      if (!webAuth) {
        return;
      }

      setIsLoading(true);

      // renews the session if possible
      // https://auth0.github.io/auth0.js/global.html#checkSession
      webAuth.checkSession({}, (err, authResult: auth0.Auth0Result | null) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          const { idToken, accessToken, expiresIn } = authResult;

          lsSetAccessToken(accessToken);
          lsSetIdToken(idToken);

          setIsAuthenticated(true);
          setAccessToken(accessToken);
          setIdToken(idToken);
          setIsLoading(false);
          setExpiresAt(add(new Date(), { seconds: expiresIn }));

          history.push(targetUrl || routes.ROOT);

          onSuccess?.();
        } else if (err) {
          lsRemoveAccessToken();
          lsRemoveIdToken();

          setIsAuthenticated(false);
          setAccessToken(undefined);
          setIdToken(undefined);
          setIsLoading(false);
          setExpiresAt(undefined);

          onFail?.();

          if (err.code === 'login_required') {
            setError(undefined);
          } else if (err.code === 'consent_required') {
            setError(new Error(err.error));
          } else {
            setError(new Error(err.error));
          }
        }
      });
    },
    [history, webAuth]
  );

  const login = React.useCallback(
    (options?: auth0.AuthorizeOptions) => {
      if (!webAuth) {
        return;
      }

      checkSession(
        options?.appState.targetUrl,
        // on success
        () => {},
        // on fail
        () => {
          webAuth?.authorize(options);
        }
      );
    },
    [checkSession, webAuth]
  );

  const handleCallback = React.useCallback(() => {
    if (!webAuth) {
      return;
    }
    setIsLoading(true);

    // NOTE: this is for handling auth started with /authorize (auth.authorize)
    // is needed to validate the id token that we get back from the auth callback
    // https://auth0.github.io/auth0.js/global.html#parseHash
    webAuth.parseHash(
      {
        __enableIdPInitiatedLogin: true,
      },
      (err, authResult) => {
        parseAuthResult(err, authResult, () => {
          history.push(authResult?.appState?.targetUrl || routes.ROOT);
        });
      }
    );
  }, [history, parseAuthResult, webAuth]);

  const logout = React.useCallback(() => {
    if (!webAuth) {
      return;
    }

    webAuth.logout({
      returnTo: RETURN_TO_URL,
    });

    setIsAuthenticated(false);
    setAccessToken(undefined);
    setIdToken(undefined);
    setError(undefined);

    lsRemoveAccessToken();
    lsRemoveIdToken();
  }, [webAuth]);

  /* NOTE: this is here to guarantee that the root App is not mounted until the auth instance is created */
  const renderChildren = React.useCallback(() => {
    if (webAuth) {
      return props.children;
    }

    return (
      <FillCenter>
        <Loading />
      </FillCenter>
    );
  }, [props.children, webAuth]);

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        isAuthenticated,
        accessToken,
        idToken,
        login,
        handleCallback,
        logout,
        error,
        checkSession,
      }}
    >
      {renderChildren()}
    </AuthContext.Provider>
  );
};
