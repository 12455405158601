import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { useTreatment_DEPRECATED } from '../contexts/treatmentsContext';
import { AppRouteType } from '../types/appRouteType';
import { useApp } from '../App';
import { ROOT } from '../routes';

import FillCenter from './FillCenter';
import Loading from './Loading';
import PrivateRoute from './PrivateRoute';

type AppRouteProps = AppRouteType & {
  isReady?: boolean;
  onHideSideNavChange?: (val: boolean) => void;
};

const AppRoute: React.FC<AppRouteProps> = (props) => {
  const { auth, ff, isReady, allowedUserTypes, ffFallback, hideSideNav, onHideSideNavChange, ...rest } = props;

  const isffOn = useTreatment_DEPRECATED(ff);
  const app = useApp();

  const additionalProps: Partial<RouteProps> = React.useMemo(() => {
    if (!isReady) {
      return {
        render: () => (
          <FillCenter>
            <Loading />
          </FillCenter>
        ),
      };
    }

    if (!!allowedUserTypes && !allowedUserTypes?.includes(app.currentAccount!)) {
      return {
        render: () => <Redirect to={ROOT} />,
      };
    }

    if (ff && !isffOn) {
      if (ffFallback) {
        return { render: ffFallback };
      }

      return {
        render: () => <Redirect to={ROOT} />,
      };
    }

    return {};
  }, [isReady, allowedUserTypes, app.currentAccount, ff, isffOn, ffFallback]);

  React.useEffect(() => {
    onHideSideNavChange?.(!!hideSideNav);
  }, [hideSideNav, onHideSideNavChange]);

  if (props.auth) {
    return <PrivateRoute {...rest} {...additionalProps} />;
  }

  return <Route {...rest} {...additionalProps} />;
};

export default AppRoute;
