export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Time: any;
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String'];
  country: Scalars['String'];
  state: Scalars['String'];
  streetLines: Array<Scalars['String']>;
  zip: Scalars['String'];
};

export type AddressInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  state: Scalars['String'];
  streetLines: Array<Scalars['String']>;
  zip: Scalars['String'];
};

export type AdminInput = {
  customerId: Scalars['String'];
  email: Scalars['String'];
};

/** An Anchor can be a physical object within an area/floorplan */
export type Anchor = Entity & {
  __typename?: 'Anchor';
  /** Area that contains this Anchor */
  area?: Maybe<Area>;
  /** Comments on this anchor */
  comments: Array<Comment>;
  /** Floorplan that contains this Anchor */
  floorplan: Floorplan;
  id: Scalars['ID'];
  location: Point;
  name: Scalars['String'];
};

/** An Area is a virtual boundary that has a type within a floorplan */
export type Area = Entity & {
  __typename?: 'Area';
  /** Anchors contained in this area */
  anchors: Array<Anchor>;
  assignable: Scalars['Boolean'];
  boundary: Polygon;
  capacity: Scalars['Int'];
  /** Comments on this area */
  comments: Array<Comment>;
  description: Scalars['String'];
  /** Floorplan containing this area */
  floorplan: Floorplan;
  id: Scalars['ID'];
  name: Scalars['String'];
  /** Permissions where this Area is an object */
  permissions?: Maybe<Array<Permission>>;
  /** Seats contained in this area */
  seats: Array<Seat>;
  type: AreaType;
  usable: Scalars['Boolean'];
};

export enum AreaType {
  TypeCollaborationArea = 'TYPE_COLLABORATION_AREA',
  TypeDeadspace = 'TYPE_DEADSPACE',
  TypeMeetingRoom = 'TYPE_MEETING_ROOM',
  TypePrivateOffice = 'TYPE_PRIVATE_OFFICE',
  TypeUnspecified = 'TYPE_UNSPECIFIED',
  TypeUtility = 'TYPE_UTILITY',
  TypeWorkspace = 'TYPE_WORKSPACE'
}

/** A Building is a physical building */
export type Building = Entity & {
  __typename?: 'Building';
  address: Address;
  /** Comments on this building */
  comments: Array<Comment>;
  crossStreets: Array<Scalars['String']>;
  /** Floors contained in this building */
  floors: Array<Floor>;
  id: Scalars['ID'];
  landlord?: Maybe<Scalars['String']>;
  location: Point;
  /** Permissions where this Building is an Object */
  permissions?: Maybe<Array<Permission>>;
  type: BuildingType;
};

export enum BuildingType {
  TypeMultiTenant = 'TYPE_MULTI_TENANT',
  TypeSingleTenant = 'TYPE_SINGLE_TENANT',
  TypeUnspecified = 'TYPE_UNSPECIFIED'
}

export type Comment = Entity & {
  __typename?: 'Comment';
  created?: Maybe<Scalars['Time']>;
  id: Scalars['ID'];
  persona: Persona;
  resolved?: Maybe<Scalars['Time']>;
  resolvedBy?: Maybe<Scalars['ID']>;
  status: CommentStatus;
  text: Scalars['String'];
  thread: Array<Comment>;
  type: CommentType;
  updated?: Maybe<Scalars['Time']>;
};

export type CommentEntityInput = {
  anchorId?: InputMaybe<Scalars['ID']>;
  areaId?: InputMaybe<Scalars['ID']>;
  buildingId?: InputMaybe<Scalars['ID']>;
  floorId?: InputMaybe<Scalars['ID']>;
  floorplanId?: InputMaybe<Scalars['ID']>;
  seatId?: InputMaybe<Scalars['ID']>;
};

export enum CommentStatus {
  StatusResolved = 'STATUS_RESOLVED',
  StatusUnresolved = 'STATUS_UNRESOLVED',
  StatusUnspecified = 'STATUS_UNSPECIFIED'
}

export enum CommentType {
  TypeReaction = 'TYPE_REACTION',
  TypeText = 'TYPE_TEXT',
  TypeUnspecified = 'TYPE_UNSPECIFIED'
}

export type CreateAnchorInput = {
  /** Area containing this Object */
  areaId?: InputMaybe<Scalars['ID']>;
  /** Floorplan containing this Object */
  floorplanId: Scalars['ID'];
  /** GeoJSON that represents the location of this object */
  location: PointInput;
  name: Scalars['String'];
  /** Session ID to use during creation. */
  sessionId?: InputMaybe<Scalars['ID']>;
};

export type CreateAreaInput = {
  /** GeoJSON bounding polygon for this area */
  boundary: PolygonInput;
  capacity: Scalars['Int'];
  /** Floorplan containing this area */
  floorplanId: Scalars['ID'];
  name: Scalars['String'];
  /** Session ID to use during creation. */
  sessionId?: InputMaybe<Scalars['ID']>;
  type: AreaType;
};

export type CreateBuildingInput = {
  address: AddressInput;
  landlord: Scalars['String'];
  location: PointInput;
  type: BuildingType;
};

export type CreateCommentInput = {
  entity: CommentEntityInput;
  personaId: Scalars['ID'];
  text: Scalars['String'];
  type: CommentType;
};

export type CreateCustomerInput = {
  address: AddressInput;
  configuration: CustomerConfigurationInput;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export type CreateFloorInput = {
  boundary: PolygonInput;
  buildingId: Scalars['ID'];
  index: Scalars['Int'];
};

export type CreateFloorplanInput = {
  boundary: PolygonInput;
  description?: InputMaybe<Scalars['String']>;
  doors?: InputMaybe<Array<DoorInput>>;
  floorNumber: Scalars['Int'];
  floorplanUrl?: InputMaybe<Scalars['String']>;
  lineWalls?: InputMaybe<Array<LineWallInput>>;
  name: Scalars['String'];
  officeId: Scalars['ID'];
  overlayPosition?: InputMaybe<OverlayPositionInput>;
  previewUrl?: InputMaybe<Scalars['String']>;
  referencePoints?: InputMaybe<Array<ReferencePointInput>>;
  scale: Scalars['Float'];
  scaleLine?: InputMaybe<LineStringInput>;
  settings?: InputMaybe<FloorplanSettingsInput>;
  walls?: InputMaybe<Array<WallInput>>;
};

export type CreateGuestInput = {
  email: Scalars['String'];
  name: Scalars['String'];
};

export type CreateHcmInput = {
  authClientId?: InputMaybe<Scalars['String']>;
  authClientSecret?: InputMaybe<Scalars['String']>;
  authDataSource?: InputMaybe<Scalars['String']>;
  authName?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  teamType: TeamType;
  type: HcmVendorType;
  version?: InputMaybe<Scalars['Int']>;
};

export type CreateOfficeInput = {
  address: AddressInput;
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  phone?: InputMaybe<Scalars['String']>;
  settings?: InputMaybe<OfficeSettingsInput>;
};

export type CreateOrganizationInput = {
  address: AddressInput;
  customerId: Scalars['ID'];
  logo?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  settings?: InputMaybe<OrganizationSettingsInput>;
};

export type CreatePermissionInput = {
  name?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  type: PermissionType;
};

export type CreatePersonInput = {
  email: Scalars['String'];
  name: Scalars['String'];
};

export type CreatePersonaInput = {
  createdDate: Scalars['Time'];
  displayName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Time']>;
  integrations?: InputMaybe<IntegrationSettingsInput>;
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  startDate?: InputMaybe<Scalars['Time']>;
  teamIds: Array<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
  type: PersonaType;
};

export type CreateReservationInput = {
  actionBy?: InputMaybe<Scalars['ID']>;
  organizationId: Scalars['ID'];
  personaId: Scalars['ID'];
  seatId: Scalars['ID'];
  startTime: Scalars['Time'];
  type: ReservationType;
};

export type CreateScheduleInput = {
  allDay?: InputMaybe<Scalars['Boolean']>;
  begin?: InputMaybe<Scalars['Time']>;
  end?: InputMaybe<Scalars['Time']>;
  exclusions?: InputMaybe<Array<TimeSpecInput>>;
  inclusions?: InputMaybe<Array<TimeSpecInput>>;
  name?: InputMaybe<Scalars['String']>;
  weekdays?: InputMaybe<Array<Scalars['Int']>>;
};

export type CreateSeatInput = {
  /** Area containing this seat */
  areaId?: InputMaybe<Scalars['ID']>;
  /** GeoJSON bounding polygon for this seat */
  boundary: PolygonInput;
  /** Dimensions of the seat (width x height x depth) */
  dimensions?: InputMaybe<DimensionsInput>;
  disabled: Scalars['Boolean'];
  /** Floorplan containing this seat */
  floorplanId: Scalars['ID'];
  /** GeoJSON location for the seat with respect to the desk */
  location?: InputMaybe<PointInput>;
  name: Scalars['String'];
  /** Rotation of the seat */
  rotation?: InputMaybe<Scalars['Float']>;
  /** Session ID to use during creation. */
  sessionId?: InputMaybe<Scalars['ID']>;
};

export type CreateSessionInput = {
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type CreateSurveyInput = {
  description: Scalars['String'];
  officeId: Scalars['ID'];
  title: Scalars['String'];
  ttl: Scalars['String'];
  type: SurveyType;
};

export type CreateTagInput = {
  name: Scalars['String'];
  organizationId: Scalars['ID'];
};

export type CreateTeamInput = {
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  parentId?: InputMaybe<Scalars['ID']>;
  type: TeamType;
};

export type CreateUserInput = {
  customerId: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  type: UserType;
};

export type Customer = Entity & {
  __typename?: 'Customer';
  address: Address;
  configuration: CustomerConfiguration;
  id: Scalars['ID'];
  name: Scalars['String'];
  organizations: Array<Organization>;
  phone: Scalars['String'];
  users: Array<User>;
};

export type CustomerConfiguration = {
  __typename?: 'CustomerConfiguration';
  auth0Audience: Scalars['String'];
  auth0ClientId: Scalars['String'];
  auth0Domain: Scalars['String'];
  auth0GoogleConnectionId: Scalars['String'];
  auth0OktaConnectionId: Scalars['String'];
  domains: Array<Scalars['String']>;
  subdomainUrl: Scalars['String'];
};

export type CustomerConfigurationInput = {
  domains: Array<Scalars['String']>;
  logoUrl?: InputMaybe<Scalars['String']>;
  subdomainUrl: Scalars['String'];
};

export type DeleteAnchorInput = {
  id: Scalars['ID'];
  /** Session ID to use during deletion. */
  sessionId?: InputMaybe<Scalars['ID']>;
};

export type DeleteAreaInput = {
  id: Scalars['ID'];
  /** Session ID to use during deletion. */
  sessionId?: InputMaybe<Scalars['ID']>;
};

export type DeleteReservationInput = {
  actionBy?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
};

export type DeleteSeatInput = {
  id: Scalars['ID'];
  /** Specifies whether past reservations should be ignored when deleting a Seat. */
  ignorePastReservations?: InputMaybe<Scalars['Boolean']>;
  /** Session ID to use during deletion. */
  sessionId?: InputMaybe<Scalars['ID']>;
};

export type DeskDimension = {
  __typename?: 'DeskDimension';
  height: Scalars['Float'];
  id: Scalars['ID'];
  label: Scalars['String'];
  width: Scalars['Float'];
};

export type DeskDimensionInput = {
  height?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  label?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Float']>;
};

export type Dimensions = {
  __typename?: 'Dimensions';
  depth: Scalars['Float'];
  height: Scalars['Float'];
  width: Scalars['Float'];
};

export type DimensionsInput = {
  depth: Scalars['Float'];
  height: Scalars['Float'];
  width: Scalars['Float'];
};

export type Door = {
  __typename?: 'Door';
  boundary: Polygon;
  id: Scalars['ID'];
};

export type DoorInput = {
  boundary?: InputMaybe<PolygonInput>;
  id: Scalars['ID'];
};

/** An object with an id */
export type Entity = {
  id: Scalars['ID'];
};

/** A Floor is a physical floor within a building */
export type Floor = Entity & {
  __typename?: 'Floor';
  boundary?: Maybe<Polygon>;
  /** Building that contains this floor */
  building: Building;
  /** Comments on this floor */
  comments?: Maybe<Array<Comment>>;
  /** Floorplans that this floor contains */
  floorplans: Array<Floorplan>;
  id: Scalars['ID'];
  index: Scalars['Int'];
  /** Permissions where this Floor is an Object */
  permissions?: Maybe<Array<Permission>>;
};

/** A Floorplan is a virtual boundary within a floor */
export type Floorplan = Entity & {
  __typename?: 'Floorplan';
  /** Anchors contained in this floorplan */
  anchors: Array<Anchor>;
  /** Areas contained in this floorplan */
  areas: Array<Area>;
  boundary: Polygon;
  /** Comments on this floorplan */
  comments: Array<Comment>;
  description: Scalars['String'];
  /** Doors contained in this floorplan */
  doors: Array<Door>;
  /** Floor that contains this floorplan */
  floor: Floor;
  floorplanUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Line-segment based walls contained in this floorplan */
  lineWalls: Array<LineWall>;
  name: Scalars['String'];
  /** Office that contains this floorplan */
  office: Office;
  overlayPosition?: Maybe<OverlayPosition>;
  /** Permissions where this Floorplan is an Object */
  permissions?: Maybe<Array<Permission>>;
  previewUrl?: Maybe<Scalars['String']>;
  /** ReferencePoints associated with this floorplan */
  referencePoints: Array<ReferencePoint>;
  /** Measured divided by Geo distance */
  scale: Scalars['Float'];
  /** ScaleLine is a drawn line representing the Floorplan's scale */
  scaleLine?: Maybe<LineString>;
  /** Seats contained in this floorplan */
  seats: Array<Seat>;
  /** Settings on this floorplan */
  settings?: Maybe<FloorplanSettings>;
  /** Walls contained in this floorplan */
  walls: Array<Wall>;
};

export type FloorplanEventData = {
  __typename?: 'FloorplanEventData';
  floorplanId: Scalars['ID'];
  name: Scalars['String'];
};

export type FloorplanSettings = {
  __typename?: 'FloorplanSettings';
  distancing?: Maybe<SettingsDistancing>;
};

export type FloorplanSettingsInput = {
  distancing?: InputMaybe<SettingsDistancingInput>;
};

export type Guest = Entity & {
  __typename?: 'Guest';
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  surveys: Array<Survey>;
};

export type Hcm = {
  __typename?: 'HCM';
  authClientId: Scalars['String'];
  authClientSecret: Scalars['String'];
  authDataSource: Scalars['String'];
  authName: Scalars['String'];
  created?: Maybe<Scalars['Time']>;
  id: Scalars['ID'];
  refreshed?: Maybe<Scalars['Time']>;
  teamType: TeamType;
  type: HcmVendorType;
  updated?: Maybe<Scalars['Time']>;
  version?: Maybe<Scalars['Int']>;
};

export enum HcmVendorType {
  VendorTypeAirtableAirtable = 'VENDOR_TYPE_AIRTABLE_AIRTABLE',
  VendorTypeBamboohr = 'VENDOR_TYPE_BAMBOOHR',
  VendorTypeCsvAirtable = 'VENDOR_TYPE_CSV_AIRTABLE',
  VendorTypeOnelogin = 'VENDOR_TYPE_ONELOGIN',
  VendorTypePingboard = 'VENDOR_TYPE_PINGBOARD',
  VendorTypeRippling = 'VENDOR_TYPE_RIPPLING',
  VendorTypeUnspecified = 'VENDOR_TYPE_UNSPECIFIED',
  VendorTypeWorkday = 'VENDOR_TYPE_WORKDAY',
  VendorTypeZenefits = 'VENDOR_TYPE_ZENEFITS'
}

export type Integration = {
  __typename?: 'Integration';
  domain?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  token: Scalars['String'];
  type: IntegrationType;
  userToken: Scalars['String'];
};

export type IntegrationSetting = {
  __typename?: 'IntegrationSetting';
  email?: Maybe<Scalars['String']>;
};

export type IntegrationSettingInput = {
  email?: InputMaybe<Scalars['String']>;
};

export type IntegrationSettings = {
  __typename?: 'IntegrationSettings';
  slack?: Maybe<IntegrationSetting>;
};

export type IntegrationSettingsInput = {
  slack?: InputMaybe<IntegrationSettingInput>;
};

export enum IntegrationType {
  TypeSlack = 'TYPE_SLACK',
  TypeUnspecified = 'TYPE_UNSPECIFIED'
}

/** GeoJSON line string */
export type LineString = {
  __typename?: 'LineString';
  points: Array<Point>;
};

export type LineStringInput = {
  points: Array<PointInput>;
};

export type LineWall = {
  __typename?: 'LineWall';
  id: Scalars['ID'];
  points: Array<Point>;
  thickness: Scalars['Float'];
};

export type LineWallInput = {
  id: Scalars['ID'];
  points: Array<InputMaybe<PointInput>>;
  thickness: Scalars['Float'];
};

export type Member = Persona | User;

/** The mutation root of Nashi's GraphQL interface */
export type Mutation = {
  __typename?: 'Mutation';
  addAdmin: User;
  addAlternateEmails?: Maybe<Persona>;
  addGoogleSocialConnection?: Maybe<Customer>;
  addObjectsToPermission: Permission;
  addQuestion?: Maybe<Survey>;
  answerQuestion: SurveyAnswerResult;
  answerQuestionGuest: SurveyAnswerResult;
  assignPermissionToSubjects: Permission;
  /** Assignment operations */
  assignPersonToPersona: Persona;
  assignSeatsToPersona: Persona;
  assignSeatsToReservation: Reservation;
  assignSeatsToTeam: Team;
  assignSurveyToGuest: Guest;
  assignSurveyToPersona: Persona;
  assignTagsToPersona: Persona;
  assignTagsToSeat: Seat;
  /** Anchor operations */
  createAnchor?: Maybe<Anchor>;
  /** Area operations */
  createArea?: Maybe<Area>;
  /** Building operations */
  createBuilding?: Maybe<Building>;
  /** Comment operations */
  createComment?: Maybe<Comment>;
  /** Customer operations */
  createCustomer?: Maybe<Customer>;
  /** Floor operations */
  createFloor?: Maybe<Floor>;
  /** Floorplan operations */
  createFloorplan?: Maybe<Floorplan>;
  /** Guest operations */
  createGuest?: Maybe<Guest>;
  /** HCM operations */
  createHCM?: Maybe<Hcm>;
  /** Office operations */
  createOffice?: Maybe<Office>;
  createOktaSSOConnection?: Maybe<Customer>;
  /** Organization operations */
  createOrganization?: Maybe<Organization>;
  /** Permission operations */
  createPermission: Permission;
  /** Person operations */
  createPerson?: Maybe<Person>;
  /** Persona operations */
  createPersona?: Maybe<Persona>;
  /** Reservation operations */
  createReservation: Reservation;
  createSchedule?: Maybe<Schedule>;
  /** Seat operations */
  createSeat?: Maybe<Seat>;
  /** Session operations */
  createSession?: Maybe<Session>;
  /** Survey operations */
  createSurvey?: Maybe<Survey>;
  /** Tag operations */
  createTag?: Maybe<Tag>;
  /** Team operations */
  createTeam?: Maybe<Team>;
  deleteAnchor?: Maybe<Anchor>;
  deleteArea?: Maybe<Area>;
  deleteBuilding?: Maybe<Building>;
  deleteComment?: Maybe<Comment>;
  deleteCustomer?: Maybe<Customer>;
  deleteFloor?: Maybe<Floor>;
  deleteFloorplan?: Maybe<Floorplan>;
  deleteGuest?: Maybe<Guest>;
  deleteHCM?: Maybe<Hcm>;
  deleteOffice?: Maybe<Office>;
  deleteOktaSSOConnection?: Maybe<Customer>;
  deleteOrganization?: Maybe<Organization>;
  deletePermission: Permission;
  deletePerson?: Maybe<Person>;
  deletePersona?: Maybe<Persona>;
  deleteReservation: Reservation;
  deleteSchedule?: Maybe<Schedule>;
  deleteSeat?: Maybe<Seat>;
  deleteSession?: Maybe<Scalars['Boolean']>;
  deleteSurvey?: Maybe<Survey>;
  deleteSurveyAnswers?: Maybe<Scalars['Boolean']>;
  deleteTag?: Maybe<Tag>;
  deleteTeam?: Maybe<Team>;
  generateGuestSurveyToken: Scalars['String'];
  removeAdmin: User;
  removeAlternateEmails?: Maybe<Persona>;
  removeGoogleSocialConnection?: Maybe<Customer>;
  removeObjectsFromPermission: Permission;
  removeQuestion?: Maybe<Survey>;
  replyToComment?: Maybe<Comment>;
  saveSession?: Maybe<Scalars['Boolean']>;
  setOfficeSchedule?: Maybe<Schedule>;
  /** Schedule operations */
  setPersonaOfficeSchedule?: Maybe<Schedule>;
  syncHCM?: Maybe<Scalars['Boolean']>;
  unassignPermissionFromSubjects: Permission;
  /** Unassignment operations */
  unassignPersonFromPersona: Persona;
  unassignSeatsFromPersona: Persona;
  unassignSeatsFromReservation: Reservation;
  unassignSeatsFromTeam: Team;
  unassignSurveyFromPersona: Persona;
  unassignTagsFromPersona: Persona;
  unassignTagsFromSeat: Seat;
  updateAnchor?: Maybe<Anchor>;
  updateArea?: Maybe<Area>;
  updateBuilding?: Maybe<Building>;
  updateComment?: Maybe<Comment>;
  updateCustomer?: Maybe<Customer>;
  updateFloor?: Maybe<Floor>;
  updateFloorplan?: Maybe<Floorplan>;
  updateGuest?: Maybe<Guest>;
  updateHCM?: Maybe<Hcm>;
  updateOffice?: Maybe<Office>;
  updateOrganization?: Maybe<Organization>;
  updatePerson?: Maybe<Person>;
  updatePersona?: Maybe<Persona>;
  updateReservation: Reservation;
  updateSchedule?: Maybe<Schedule>;
  updateSeat?: Maybe<Seat>;
  updateSurvey?: Maybe<Survey>;
  updateTag?: Maybe<Tag>;
  updateTeam?: Maybe<Team>;
  /** User operations */
  updateUser: User;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationAddAdminArgs = {
  input: AdminInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationAddAlternateEmailsArgs = {
  emails: Array<Scalars['String']>;
  id: Scalars['ID'];
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationAddGoogleSocialConnectionArgs = {
  customerId: Scalars['ID'];
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationAddObjectsToPermissionArgs = {
  input: PermissionObjectsInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationAddQuestionArgs = {
  input: QuestionInput;
  surveyId: Scalars['ID'];
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationAnswerQuestionArgs = {
  input: SurveyAnswersInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationAnswerQuestionGuestArgs = {
  input: SurveyAnswersGuestInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationAssignPermissionToSubjectsArgs = {
  input: PermissionSubjectsInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationAssignPersonToPersonaArgs = {
  input: PersonPersonaInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationAssignSeatsToPersonaArgs = {
  input: SeatsPersonaInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationAssignSeatsToReservationArgs = {
  input: ReservationSeatsInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationAssignSeatsToTeamArgs = {
  input: SeatsTeamInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationAssignSurveyToGuestArgs = {
  input: SurveyGuestInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationAssignSurveyToPersonaArgs = {
  input: SurveyPersonaInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationAssignTagsToPersonaArgs = {
  input: TagsPersonaInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationAssignTagsToSeatArgs = {
  input: TagsSeatInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationCreateAnchorArgs = {
  input: CreateAnchorInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationCreateAreaArgs = {
  input: CreateAreaInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationCreateBuildingArgs = {
  input: CreateBuildingInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationCreateCommentArgs = {
  input: CreateCommentInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationCreateFloorArgs = {
  input: CreateFloorInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationCreateFloorplanArgs = {
  input: CreateFloorplanInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationCreateGuestArgs = {
  input: CreateGuestInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationCreateHcmArgs = {
  input: CreateHcmInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationCreateOfficeArgs = {
  input: CreateOfficeInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationCreateOktaSsoConnectionArgs = {
  input: OktaSsoConnectionInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationCreatePermissionArgs = {
  input: CreatePermissionInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationCreatePersonArgs = {
  input: CreatePersonInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationCreatePersonaArgs = {
  input: CreatePersonaInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationCreateReservationArgs = {
  input: CreateReservationInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationCreateScheduleArgs = {
  input: CreateScheduleInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationCreateSeatArgs = {
  input: CreateSeatInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationCreateSessionArgs = {
  input: CreateSessionInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationCreateSurveyArgs = {
  input: CreateSurveyInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationCreateTagArgs = {
  input: CreateTagInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationCreateTeamArgs = {
  input: CreateTeamInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationDeleteAnchorArgs = {
  input: DeleteAnchorInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationDeleteAreaArgs = {
  input: DeleteAreaInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationDeleteBuildingArgs = {
  id: Scalars['ID'];
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationDeleteCommentArgs = {
  id: Scalars['ID'];
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationDeleteCustomerArgs = {
  id: Scalars['ID'];
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationDeleteFloorArgs = {
  id: Scalars['ID'];
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationDeleteFloorplanArgs = {
  id: Scalars['ID'];
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationDeleteGuestArgs = {
  id: Scalars['ID'];
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationDeleteHcmArgs = {
  id: Scalars['ID'];
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationDeleteOfficeArgs = {
  id: Scalars['ID'];
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationDeleteOktaSsoConnectionArgs = {
  customerId: Scalars['ID'];
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationDeleteOrganizationArgs = {
  id: Scalars['ID'];
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationDeletePermissionArgs = {
  id: Scalars['ID'];
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationDeletePersonArgs = {
  id: Scalars['ID'];
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationDeletePersonaArgs = {
  id: Scalars['ID'];
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationDeleteReservationArgs = {
  input: DeleteReservationInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationDeleteScheduleArgs = {
  id: Scalars['ID'];
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationDeleteSeatArgs = {
  input: DeleteSeatInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationDeleteSessionArgs = {
  id: Scalars['ID'];
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationDeleteSurveyArgs = {
  id: Scalars['ID'];
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationDeleteSurveyAnswersArgs = {
  organizationID: Scalars['ID'];
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationDeleteTagArgs = {
  id: Scalars['ID'];
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationDeleteTeamArgs = {
  id: Scalars['ID'];
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationGenerateGuestSurveyTokenArgs = {
  officeId: Scalars['ID'];
  organizationId: Scalars['ID'];
  surveyId: Scalars['ID'];
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationRemoveAdminArgs = {
  id: Scalars['ID'];
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationRemoveAlternateEmailsArgs = {
  emails: Array<Scalars['String']>;
  id: Scalars['ID'];
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationRemoveGoogleSocialConnectionArgs = {
  customerId: Scalars['ID'];
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationRemoveObjectsFromPermissionArgs = {
  input: PermissionObjectsInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationRemoveQuestionArgs = {
  questionIndex: Scalars['Int'];
  surveyId: Scalars['ID'];
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationReplyToCommentArgs = {
  input: ReplyToCommentInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationSaveSessionArgs = {
  id: Scalars['ID'];
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationSetOfficeScheduleArgs = {
  input: CreateScheduleInput;
  officeId: Scalars['ID'];
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationSetPersonaOfficeScheduleArgs = {
  input: CreateScheduleInput;
  officeId: Scalars['ID'];
  personaId: Scalars['ID'];
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationSyncHcmArgs = {
  organizationId: Scalars['ID'];
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationUnassignPermissionFromSubjectsArgs = {
  input: PermissionSubjectsInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationUnassignPersonFromPersonaArgs = {
  input: PersonPersonaInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationUnassignSeatsFromPersonaArgs = {
  input: SeatsPersonaInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationUnassignSeatsFromReservationArgs = {
  input: ReservationSeatsInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationUnassignSeatsFromTeamArgs = {
  input: SeatsTeamInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationUnassignSurveyFromPersonaArgs = {
  input: SurveyPersonaInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationUnassignTagsFromPersonaArgs = {
  input: TagsPersonaInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationUnassignTagsFromSeatArgs = {
  input: TagsSeatInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationUpdateAnchorArgs = {
  input: UpdateAnchorInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationUpdateAreaArgs = {
  input: UpdateAreaInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationUpdateBuildingArgs = {
  input: UpdateBuildingInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationUpdateCommentArgs = {
  input: UpdateCommentInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationUpdateFloorArgs = {
  input: UpdateFloorInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationUpdateFloorplanArgs = {
  input: UpdateFloorplanInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationUpdateGuestArgs = {
  input: UpdateGuestInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationUpdateHcmArgs = {
  input: UpdateHcmInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationUpdateOfficeArgs = {
  input: UpdateOfficeInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationUpdatePersonArgs = {
  input: UpdatePersonInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationUpdatePersonaArgs = {
  input: UpdatePersonaInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationUpdateReservationArgs = {
  input: UpdateReservationInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationUpdateScheduleArgs = {
  input: UpdateScheduleInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationUpdateSeatArgs = {
  input: UpdateSeatInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationUpdateSurveyArgs = {
  input: UpdateSurveyInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationUpdateTagArgs = {
  input: UpdateTagInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationUpdateTeamArgs = {
  input: UpdateTeamInput;
};


/** The mutation root of Nashi's GraphQL interface */
export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type Office = Entity & {
  __typename?: 'Office';
  address: Address;
  floorplans: Array<Floorplan>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organization: Organization;
  /** Schedules for Personas in this Office */
  personaSchedules?: Maybe<Array<PersonaOfficeSchedule>>;
  phone?: Maybe<Scalars['String']>;
  /** Schedule for this Office */
  schedule?: Maybe<Schedule>;
  settings?: Maybe<OfficeSettings>;
  /** Surveys that this Office owns */
  surveys: Array<Survey>;
};

export type OfficeEventData = {
  __typename?: 'OfficeEventData';
  name: Scalars['String'];
  officeId: Scalars['ID'];
};

export type OfficeSettings = {
  __typename?: 'OfficeSettings';
  pass?: Maybe<SettingsPass>;
  space?: Maybe<SettingsSpace>;
};

export type OfficeSettingsInput = {
  pass?: InputMaybe<SettingsPassInput>;
  space?: InputMaybe<SettingsSpaceInput>;
};

export type OfficeSettingsPass = {
  __typename?: 'OfficeSettingsPass';
  qr?: Maybe<SettingsPassQr>;
};

export type OktaSsoConnectionInput = {
  customerId: Scalars['ID'];
  oktaClientId: Scalars['String'];
  oktaClientSecret: Scalars['String'];
  oktaDomain: Scalars['String'];
};

/** An account on Nashi that has floorplans, admins, and members that supports hierarchy */
export type Organization = Entity & {
  __typename?: 'Organization';
  address: Address;
  /** Organizations that descent from this Organization */
  children: Array<Organization>;
  /** Customer that owns this organization */
  customer: Customer;
  /** HCM Information for this Organization */
  hcm?: Maybe<Hcm>;
  headcount: Scalars['Int'];
  id: Scalars['ID'];
  /** Integrations for this organization */
  integrations: Array<Integration>;
  logo: Scalars['String'];
  name: Scalars['String'];
  /** Offices that are owned by this Organization */
  offices: Array<Office>;
  /** The parent Organization for this Organization */
  parent?: Maybe<Organization>;
  phone?: Maybe<Scalars['String']>;
  /** Settings for this organization */
  settings?: Maybe<OrganizationSettings>;
  /** Teams that belong to this Organization */
  teams: Array<Team>;
};

export type OrganizationSettings = {
  __typename?: 'OrganizationSettings';
  space?: Maybe<SettingsSpace>;
};

export type OrganizationSettingsInput = {
  pass?: InputMaybe<SettingsPassInput>;
  space?: InputMaybe<SettingsSpaceInput>;
};

export type OverlayPosition = {
  __typename?: 'OverlayPosition';
  nwCorner: Point;
  rotation: Scalars['Float'];
  seCorner: Point;
};

export type OverlayPositionInput = {
  nwCorner: PointInput;
  rotation: Scalars['Float'];
  seCorner: PointInput;
};

export type Permission = Entity & {
  __typename?: 'Permission';
  id: Scalars['ID'];
  name: Scalars['String'];
  objects?: Maybe<PermissionObjects>;
  organization: Organization;
  subjects?: Maybe<PermissionSubjects>;
  type: PermissionType;
};

export type PermissionObjectIDsInput = {
  areaIds?: InputMaybe<Array<Scalars['ID']>>;
  buildingIds?: InputMaybe<Array<Scalars['ID']>>;
  floorIds?: InputMaybe<Array<Scalars['ID']>>;
  floorplanIds?: InputMaybe<Array<Scalars['ID']>>;
  seatIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type PermissionObjects = {
  __typename?: 'PermissionObjects';
  areas: Array<Area>;
  buildings: Array<Building>;
  floorplans: Array<Floorplan>;
  floors: Array<Floor>;
  seats: Array<Seat>;
};

export type PermissionObjectsInput = {
  objects?: InputMaybe<PermissionObjectIDsInput>;
  permissionId: Scalars['ID'];
};

export type PermissionSubjectIDsInput = {
  personaIds?: InputMaybe<Array<Scalars['ID']>>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
  teamIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type PermissionSubjects = {
  __typename?: 'PermissionSubjects';
  personas: Array<Persona>;
  tags: Array<Tag>;
  teams: Array<Team>;
};

export type PermissionSubjectsInput = {
  permissionId: Scalars['ID'];
  subjects?: InputMaybe<PermissionSubjectIDsInput>;
};

export enum PermissionType {
  TypeAssignable = 'TYPE_ASSIGNABLE',
  TypeReservable = 'TYPE_RESERVABLE',
  TypeUnspecified = 'TYPE_UNSPECIFIED'
}

/** A User is an individual's account on Nashi */
export type Person = Entity & {
  __typename?: 'Person';
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  /** Persona associated with this user */
  personas: Array<Persona>;
};

export type PersonPersonaInput = {
  personId: Scalars['ID'];
  personaId: Scalars['ID'];
};

/** A Persona stores organizational specific metadata for a user */
export type Persona = Entity & {
  __typename?: 'Persona';
  alternateEmails: Array<Scalars['String']>;
  createdDate: Scalars['Time'];
  displayName: Scalars['String'];
  email: Scalars['String'];
  endDate?: Maybe<Scalars['Time']>;
  id: Scalars['ID'];
  /** Integration settings for this Persona */
  integrations?: Maybe<IntegrationSettings>;
  name: Scalars['String'];
  /** Organization this persona belongs to */
  organization: Organization;
  /** Permissions where this Persona is a Subject */
  permissions: Array<Permission>;
  /** Person associated with this persona */
  person?: Maybe<Person>;
  photoURL?: Maybe<Scalars['String']>;
  /** Reservations for this Persona */
  reservations: Array<Reservation>;
  /** Get reservations created or updated by this Persona */
  reservationsReservedBy?: Maybe<Array<Reservation>>;
  reservationsUpdatedBy?: Maybe<Array<Reservation>>;
  /** Schedules for this Persona */
  schedules?: Maybe<Array<PersonaOfficeSchedule>>;
  /** Seats that this persona is assigned to */
  seats: Array<Seat>;
  settings?: Maybe<PersonaSettings>;
  startDate?: Maybe<Scalars['Time']>;
  tags: Array<Tag>;
  /** Teams that this persona is a part of */
  teams: Array<Team>;
  title: Scalars['String'];
  type: PersonaType;
};

export type PersonaEventData = {
  __typename?: 'PersonaEventData';
  email: Scalars['String'];
  name: Scalars['String'];
  personaId: Scalars['ID'];
};

export type PersonaOfficeSchedule = {
  __typename?: 'PersonaOfficeSchedule';
  office?: Maybe<Office>;
  persona?: Maybe<Persona>;
  schedule: Schedule;
};

export type PersonaSettings = {
  __typename?: 'PersonaSettings';
  primaryOfficeID: Scalars['String'];
  updateSlackStatus: Scalars['Boolean'];
};

export type PersonaSettingsInput = {
  primaryOfficeID: Scalars['String'];
  updateSlackStatus: Scalars['Boolean'];
};

export enum PersonaType {
  TypeHired = 'TYPE_HIRED',
  TypeHiring = 'TYPE_HIRING',
  TypeOpenRequest = 'TYPE_OPEN_REQUEST',
  TypeUnspecified = 'TYPE_UNSPECIFIED'
}

/** GeoJSON Point */
export type Point = {
  __typename?: 'Point';
  /** Longitude */
  x: Scalars['Float'];
  /** Latitude */
  y: Scalars['Float'];
};

export type PointInput = {
  /** Longitude */
  x: Scalars['Float'];
  /** Latitude */
  y: Scalars['Float'];
};

/** GeoJSON polygon */
export type Polygon = {
  __typename?: 'Polygon';
  lineStrings: Array<LineString>;
};

export type PolygonInput = {
  lineStrings: Array<LineStringInput>;
};

/** The query root of Nashi's GraphQL interface */
export type Query = {
  __typename?: 'Query';
  /** Get the list of admins for a given customer */
  admins: Array<User>;
  /** Get all Organizations */
  allOrganizations: Array<Organization>;
  /** Get Anchor by id */
  anchor?: Maybe<Anchor>;
  /** Get signed URLs for the provided architectural floor plan upload file paths */
  architecturalPlanUploadUrls: Array<Scalars['String']>;
  /** Get Area by id */
  area?: Maybe<Area>;
  /** Get Building by id */
  building?: Maybe<Building>;
  /** Get Comment by id */
  comment: Array<Comment>;
  /** Get a Customer for a given domain */
  customer?: Maybe<Customer>;
  /** Get Floor by id */
  floor?: Maybe<Floor>;
  /** Get Floorplan by id */
  floorplan?: Maybe<Floorplan>;
  /** Get signed URLs for the provided Floorplan map upload file paths */
  floorplanMapUploadUrls: Array<Scalars['String']>;
  /** Get guest by name and email */
  guest: Guest;
  /** Get all users and personas for an organization */
  members: Array<Member>;
  /** Get Office by id */
  office?: Maybe<Office>;
  /** Get signed URLs for the provided Organization-specific asset upload file paths */
  orgAssetUploadUrls: Array<Scalars['String']>;
  /** Get Organization by id */
  organization?: Maybe<Organization>;
  /** Get Permission by id */
  permission: Permission;
  /** Get Person by id */
  person?: Maybe<Person>;
  /** Get Persona by id */
  persona?: Maybe<Persona>;
  /** Get the list of personas for a given organization */
  personasByOrganization: Array<Persona>;
  /** Get Reservation by id */
  reservation: Reservation;
  /** Get reservation events */
  reservationEvents: Array<ReservationEvent>;
  /** Get the pass for a reservation */
  reservationPass?: Maybe<Scalars['String']>;
  /** Get Reservations acted on for other personas */
  reservationsReservedBy: Array<ReservationBy>;
  reservationsUpdatedBy: Array<ReservationBy>;
  /** Get Schedule by id */
  schedule?: Maybe<Schedule>;
  /** Get all the teams, personas, and teams that match the NQL query */
  search: SearchResult;
  /** Get all the possible values for a NQL attribute and match the search citerion */
  searchAttributeValues: Array<Scalars['String']>;
  /** Get the attributes Nashi Query Language (NQL) supports and match the search citerion */
  searchAttributes: Array<SearchAttribute>;
  /** Get all the tags that match any of the search queries */
  searchTags: Array<Tag>;
  /** Get Seat by id */
  seat?: Maybe<Seat>;
  /** Get the open sessions for a user */
  sessions: Array<Session>;
  /** Get all of the slack channels for an organization */
  slackChannels: Array<SlackChannel>;
  /** Get Survey by id */
  survey?: Maybe<Survey>;
  /** Get survey answers for a guest */
  surveyAnswersForGuests: Array<SurveyAnswers>;
  /** Get survey answers for a persona */
  surveyAnswersForPersonas: Array<SurveyAnswers>;
  /** Get Tag by id */
  tag?: Maybe<Tag>;
  /** Get Tags for an Organization and entities */
  tagsForEntities: Array<Tag>;
  /** Get Team by id */
  team?: Maybe<Team>;
  /** Get the currently authenticated User */
  user: User;
  /** Get the list of users for a given customer */
  usersByCustomer: Array<User>;
  /** Get the API version */
  version: Scalars['String'];
  /** Get visitor events */
  visitorEvents: Array<VisitorEvent>;
  /** Generate a visitor events report (WARNING: LARGE PAYLOAD) */
  visitorEventsReport: Array<VisitorEvent>;
};


/** The query root of Nashi's GraphQL interface */
export type QueryAdminsArgs = {
  customerId: Scalars['ID'];
};


/** The query root of Nashi's GraphQL interface */
export type QueryAnchorArgs = {
  input: QueryInput;
};


/** The query root of Nashi's GraphQL interface */
export type QueryArchitecturalPlanUploadUrlsArgs = {
  filePaths: Array<Scalars['String']>;
};


/** The query root of Nashi's GraphQL interface */
export type QueryAreaArgs = {
  input: QueryInput;
};


/** The query root of Nashi's GraphQL interface */
export type QueryBuildingArgs = {
  input: QueryInput;
};


/** The query root of Nashi's GraphQL interface */
export type QueryCommentArgs = {
  input: QueryInput;
};


/** The query root of Nashi's GraphQL interface */
export type QueryCustomerArgs = {
  domain: Scalars['String'];
};


/** The query root of Nashi's GraphQL interface */
export type QueryFloorArgs = {
  input: QueryInput;
};


/** The query root of Nashi's GraphQL interface */
export type QueryFloorplanArgs = {
  input: QueryInput;
};


/** The query root of Nashi's GraphQL interface */
export type QueryFloorplanMapUploadUrlsArgs = {
  filePaths: Array<Scalars['String']>;
};


/** The query root of Nashi's GraphQL interface */
export type QueryGuestArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
};


/** The query root of Nashi's GraphQL interface */
export type QueryMembersArgs = {
  organizationId: Scalars['ID'];
};


/** The query root of Nashi's GraphQL interface */
export type QueryOfficeArgs = {
  input: QueryInput;
};


/** The query root of Nashi's GraphQL interface */
export type QueryOrgAssetUploadUrlsArgs = {
  filePaths: Array<Scalars['String']>;
  orgId: Scalars['String'];
};


/** The query root of Nashi's GraphQL interface */
export type QueryOrganizationArgs = {
  input: QueryInput;
};


/** The query root of Nashi's GraphQL interface */
export type QueryPermissionArgs = {
  input: QueryInput;
};


/** The query root of Nashi's GraphQL interface */
export type QueryPersonArgs = {
  input: QueryInput;
};


/** The query root of Nashi's GraphQL interface */
export type QueryPersonaArgs = {
  input: QueryInput;
};


/** The query root of Nashi's GraphQL interface */
export type QueryPersonasByOrganizationArgs = {
  organizationId: Scalars['ID'];
};


/** The query root of Nashi's GraphQL interface */
export type QueryReservationArgs = {
  input: QueryInput;
};


/** The query root of Nashi's GraphQL interface */
export type QueryReservationEventsArgs = {
  officeId: Scalars['ID'];
  organizationId: Scalars['ID'];
};


/** The query root of Nashi's GraphQL interface */
export type QueryReservationPassArgs = {
  reservationId: Scalars['ID'];
};


/** The query root of Nashi's GraphQL interface */
export type QueryReservationsReservedByArgs = {
  personaIds: Array<Scalars['ID']>;
};


/** The query root of Nashi's GraphQL interface */
export type QueryReservationsUpdatedByArgs = {
  personaIds: Array<Scalars['ID']>;
};


/** The query root of Nashi's GraphQL interface */
export type QueryScheduleArgs = {
  input: QueryInput;
};


/** The query root of Nashi's GraphQL interface */
export type QuerySearchArgs = {
  input: SearchInput;
};


/** The query root of Nashi's GraphQL interface */
export type QuerySearchAttributeValuesArgs = {
  input: SearchAttributeValuesInput;
};


/** The query root of Nashi's GraphQL interface */
export type QuerySearchAttributesArgs = {
  input: SearchAttributesInput;
};


/** The query root of Nashi's GraphQL interface */
export type QuerySearchTagsArgs = {
  input: SearchTagsInput;
};


/** The query root of Nashi's GraphQL interface */
export type QuerySeatArgs = {
  input: QueryInput;
};


/** The query root of Nashi's GraphQL interface */
export type QuerySessionsArgs = {
  userId: Scalars['ID'];
};


/** The query root of Nashi's GraphQL interface */
export type QuerySlackChannelsArgs = {
  organizationId: Scalars['ID'];
};


/** The query root of Nashi's GraphQL interface */
export type QuerySurveyArgs = {
  input: QueryInput;
};


/** The query root of Nashi's GraphQL interface */
export type QuerySurveyAnswersForGuestsArgs = {
  guestIds: Array<Scalars['ID']>;
  surveyId: Scalars['ID'];
};


/** The query root of Nashi's GraphQL interface */
export type QuerySurveyAnswersForPersonasArgs = {
  personaIds: Array<Scalars['ID']>;
  surveyId: Scalars['ID'];
};


/** The query root of Nashi's GraphQL interface */
export type QueryTagArgs = {
  input: QueryInput;
};


/** The query root of Nashi's GraphQL interface */
export type QueryTagsForEntitiesArgs = {
  input: TagEntitiesInput;
};


/** The query root of Nashi's GraphQL interface */
export type QueryTeamArgs = {
  input: QueryInput;
};


/** The query root of Nashi's GraphQL interface */
export type QueryUsersByCustomerArgs = {
  customerId: Scalars['ID'];
};


/** The query root of Nashi's GraphQL interface */
export type QueryVisitorEventsArgs = {
  endTime?: InputMaybe<Scalars['Time']>;
  officeId: Scalars['ID'];
  organizationId: Scalars['ID'];
  startTime?: InputMaybe<Scalars['Time']>;
};


/** The query root of Nashi's GraphQL interface */
export type QueryVisitorEventsReportArgs = {
  endTime?: InputMaybe<Scalars['Time']>;
  officeId: Scalars['ID'];
  organizationId: Scalars['ID'];
  startTime?: InputMaybe<Scalars['Time']>;
};

export type QueryInput = {
  entityId: Scalars['ID'];
  sessionId?: InputMaybe<Scalars['ID']>;
};

export type Question = {
  __typename?: 'Question';
  hint: Scalars['String'];
  index: Scalars['Int'];
  options: Array<QuestionOption>;
  question: Scalars['String'];
  required: Scalars['Boolean'];
};

export type QuestionInput = {
  hint: Scalars['String'];
  index: Scalars['Int'];
  options: Array<QuestionOptionInput>;
  question: Scalars['String'];
  required: Scalars['Boolean'];
};

export type QuestionOption = {
  __typename?: 'QuestionOption';
  answer: Scalars['String'];
  correct: Scalars['Boolean'];
  index: Scalars['Int'];
};

export type QuestionOptionInput = {
  answer: Scalars['String'];
  correct: Scalars['Boolean'];
  index: Scalars['Int'];
};

export type ReferencePoint = {
  __typename?: 'ReferencePoint';
  id: Scalars['ID'];
  point: Point;
};

export type ReferencePointInput = {
  id: Scalars['ID'];
  point?: InputMaybe<PointInput>;
};

export type ReplyToCommentInput = {
  commentId: Scalars['ID'];
  personaId: Scalars['ID'];
  text: Scalars['String'];
  type: CommentType;
};

export type Reservation = {
  __typename?: 'Reservation';
  confirmAt?: Maybe<Scalars['Time']>;
  confirmBy?: Maybe<Scalars['Time']>;
  confirmedAt?: Maybe<Scalars['Time']>;
  created: Scalars['Time'];
  endTime: Scalars['Time'];
  /** Indicates whether the subject of this reservation has a pass. */
  hasPass: Scalars['Boolean'];
  id: Scalars['ID'];
  metadata?: Maybe<ReservationMetadata>;
  organizationId: Scalars['String'];
  persona?: Maybe<Persona>;
  seat?: Maybe<Seat>;
  startOfDay?: Maybe<Scalars['Time']>;
  startTime: Scalars['Time'];
  type: ReservationType;
  updated?: Maybe<Scalars['Time']>;
};

export type ReservationBy = {
  __typename?: 'ReservationBy';
  personaId: Scalars['ID'];
  reservations: Array<Reservation>;
};

export type ReservationEvent = {
  __typename?: 'ReservationEvent';
  actionBy?: Maybe<Persona>;
  answeredSurvey: Scalars['Boolean'];
  canceled?: Maybe<Scalars['Time']>;
  confirmed?: Maybe<Scalars['Time']>;
  employee: Persona;
  floorplan?: Maybe<Floorplan>;
  id: Scalars['ID'];
  pass: Scalars['Boolean'];
  reservation: Reservation;
  seat: Seat;
  status: ReservationEventStatus;
};

export enum ReservationEventStatus {
  Confirmed = 'CONFIRMED',
  Expired = 'EXPIRED',
  OtherCanceled = 'OTHER_CANCELED',
  Pending = 'PENDING',
  UserCanceled = 'USER_CANCELED'
}

export type ReservationMetadata = {
  __typename?: 'ReservationMetadata';
  reservedBy: Scalars['ID'];
  updatedBy: Scalars['ID'];
};

export type ReservationSeatsInput = {
  reservationId: Scalars['ID'];
  seatIds: Array<Scalars['ID']>;
};

export enum ReservationType {
  TypeHoteling = 'TYPE_HOTELING',
  TypeUnspecified = 'TYPE_UNSPECIFIED'
}

export type Schedule = {
  __typename?: 'Schedule';
  allDay: Scalars['Boolean'];
  begin?: Maybe<Scalars['Time']>;
  end?: Maybe<Scalars['Time']>;
  exclusions?: Maybe<Array<TimeSpec>>;
  id: Scalars['ID'];
  inclusions?: Maybe<Array<TimeSpec>>;
  name: Scalars['String'];
  weekdays?: Maybe<Array<Scalars['Int']>>;
};

export type SearchAttribute = {
  __typename?: 'SearchAttribute';
  comparisonOperators: Array<Scalars['String']>;
  dataType?: Maybe<SearchAttributeType>;
  name: Scalars['String'];
};

export enum SearchAttributeType {
  TypeBool = 'TYPE_BOOL',
  TypeEmail = 'TYPE_EMAIL',
  TypeNumber = 'TYPE_NUMBER',
  TypeString = 'TYPE_STRING',
  TypeTag = 'TYPE_TAG',
  TypeUnspecified = 'TYPE_UNSPECIFIED',
  TypeUuid = 'TYPE_UUID'
}

export type SearchAttributeValuesInput = {
  attributeName: Scalars['String'];
  maximumResult?: InputMaybe<Scalars['Int']>;
  organizationId: Scalars['ID'];
  query: Scalars['String'];
  sessionId?: InputMaybe<Scalars['ID']>;
};

export type SearchAttributesInput = {
  query: Scalars['String'];
};

export type SearchInput = {
  maximumResult?: InputMaybe<Scalars['Int']>;
  organizationId: Scalars['ID'];
  query: Scalars['String'];
  sessionId?: InputMaybe<Scalars['ID']>;
};

export type SearchResult = {
  __typename?: 'SearchResult';
  personas: Array<Persona>;
  seats: Array<Seat>;
  teams: Array<Team>;
};

export type SearchTagsInput = {
  maximumResult?: InputMaybe<Scalars['Int']>;
  organizationId: Scalars['ID'];
  query: Array<Scalars['String']>;
};

/** A Seat can be a physical object within an area/floorplan */
export type Seat = Entity & {
  __typename?: 'Seat';
  /** Area that contains this Seat */
  area: Area;
  boundary: Polygon;
  /** Comments on this seat */
  comments: Array<Comment>;
  dimensions?: Maybe<Dimensions>;
  disabled: Scalars['Boolean'];
  /** Floorplan that contains this Seat */
  floorplan: Floorplan;
  id: Scalars['ID'];
  location?: Maybe<Point>;
  name: Scalars['String'];
  /** Permission where this Seat is an Object */
  permission?: Maybe<Permission>;
  /** Personas assigned to this Seat */
  personas: Array<Persona>;
  /** Reservations for this Seat */
  reservations: Array<Reservation>;
  rotation: Scalars['Float'];
  tags: Array<Tag>;
  /** The path of Teams up to the Organization that are assigned to this Seat */
  teamPathIds: Array<Scalars['ID']>;
  /** The path of Teams up to the Organization that have reserved this Seat */
  teamResPathIds: Array<Scalars['ID']>;
  /** Teams assigned to this Seat */
  teams: Array<Team>;
};

export type SeatEventData = {
  __typename?: 'SeatEventData';
  name: Scalars['String'];
  seatId: Scalars['ID'];
};

export type SeatRecommendation = {
  __typename?: 'SeatRecommendation';
  score: Scalars['Int'];
  seatId: Scalars['ID'];
};

export type SeatsForReservationRecommendationInput = {
  colocatedSeatIds: Array<Scalars['ID']>;
  floorplanId: Scalars['ID'];
  personaId: Scalars['ID'];
  preferences: Array<Scalars['String']>;
};

export type SeatsForTagsRecommendationInput = {
  areaId: Scalars['ID'];
  personaId: Scalars['ID'];
};

export type SeatsPersonaInput = {
  floorplanId: Scalars['ID'];
  personaId: Scalars['ID'];
  seatIds: Array<Scalars['ID']>;
  sessionId?: InputMaybe<Scalars['ID']>;
};

export type SeatsTeamInput = {
  floorplanId: Scalars['ID'];
  seatIds: Array<Scalars['ID']>;
  sessionId?: InputMaybe<Scalars['ID']>;
  teamId: Scalars['ID'];
};

export type Session = {
  __typename?: 'Session';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SettingsDistancing = {
  __typename?: 'SettingsDistancing';
  enabled: Scalars['Boolean'];
  radius: Scalars['Float'];
};

export type SettingsDistancingInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  radius?: InputMaybe<Scalars['Float']>;
};

export type SettingsPass = {
  __typename?: 'SettingsPass';
  action?: Maybe<SettingsPassAction>;
  cancelReason: Scalars['String'];
  discardAnswers: Scalars['Boolean'];
  enabled: Scalars['Boolean'];
  notifyIds: Array<Scalars['ID']>;
  notifySlackIds: Array<Scalars['String']>;
  qr?: Maybe<SettingsPassQr>;
  requireDesk: Scalars['Boolean'];
  retentionPeriod: Scalars['String'];
  surveyId: Scalars['ID'];
  title: Scalars['String'];
};

export type SettingsPassAction = {
  __typename?: 'SettingsPassAction';
  notify: Scalars['Boolean'];
};

export type SettingsPassActionInput = {
  notify: Scalars['Boolean'];
};

export type SettingsPassInput = {
  action?: InputMaybe<SettingsPassActionInput>;
  cancelReason?: InputMaybe<Scalars['String']>;
  discardAnswers?: InputMaybe<Scalars['Boolean']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  notifyIds?: InputMaybe<Array<Scalars['ID']>>;
  notifySlackIds?: InputMaybe<Array<Scalars['String']>>;
  qr?: InputMaybe<SettingsPassQrInput>;
  requireDesk?: InputMaybe<Scalars['Boolean']>;
  retentionPeriod: Scalars['String'];
  surveyId?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
};

export type SettingsPassQr = {
  __typename?: 'SettingsPassQR';
  code?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['Time']>;
};

export type SettingsPassQrInput = {
  code?: InputMaybe<Scalars['String']>;
  time?: InputMaybe<Scalars['Time']>;
};

export type SettingsSpace = {
  __typename?: 'SettingsSpace';
  checkinWindow: Scalars['String'];
  deskDimensions: Array<DeskDimension>;
  distancing: SettingsDistancing;
  enabled: Scalars['Boolean'];
  furthestDayOut: Scalars['String'];
  maxDailyCapacity: Scalars['Float'];
  notifyIds: Array<Scalars['ID']>;
  notifySlackIds: Array<Scalars['String']>;
  requireCheckin: Scalars['Boolean'];
  showPhotoInSeating: Scalars['Boolean'];
};

export type SettingsSpaceInput = {
  checkinWindow?: InputMaybe<Scalars['String']>;
  deskDimensions?: InputMaybe<Array<DeskDimensionInput>>;
  distancing?: InputMaybe<SettingsDistancingInput>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  furthestDayOut?: InputMaybe<Scalars['String']>;
  maxDailyCapacity?: InputMaybe<Scalars['Float']>;
  notifyIds?: InputMaybe<Array<Scalars['ID']>>;
  notifySlackIds?: InputMaybe<Array<Scalars['String']>>;
  requireCheckin?: InputMaybe<Scalars['Boolean']>;
  showPhotoInSeating?: InputMaybe<Scalars['Boolean']>;
};

export type SlackChannel = {
  __typename?: 'SlackChannel';
  id: Scalars['String'];
  isMember: Scalars['Boolean'];
  isPrivate: Scalars['Boolean'];
  name: Scalars['String'];
};

export type Survey = Entity & {
  __typename?: 'Survey';
  answerCount: Scalars['Int'];
  description: Scalars['String'];
  finalized: Scalars['Boolean'];
  id: Scalars['ID'];
  questions: Array<Question>;
  title: Scalars['String'];
  ttl: Scalars['String'];
  type: SurveyType;
};

export type SurveyAnswerQuestion = {
  __typename?: 'SurveyAnswerQuestion';
  answeredAt: Scalars['Time'];
  optionIndex: Scalars['Int'];
  questionIndex: Scalars['Int'];
};

export type SurveyAnswerQuestionInput = {
  optionIndex: Scalars['Int'];
  questionIndex: Scalars['Int'];
};

export type SurveyAnswerResult = {
  __typename?: 'SurveyAnswerResult';
  correct: Scalars['Boolean'];
  passUrl?: Maybe<Scalars['String']>;
};

export type SurveyAnswers = {
  __typename?: 'SurveyAnswers';
  answers: Array<SurveyAnswerQuestion>;
  completedAt: Scalars['Time'];
  correct?: Maybe<Scalars['Boolean']>;
  floorplanId: Scalars['ID'];
  officeId: Scalars['ID'];
  subjectId: Scalars['ID'];
  surveyId: Scalars['ID'];
};

export type SurveyAnswersGuestInput = {
  answers: Array<SurveyAnswerQuestionInput>;
  guestId: Scalars['ID'];
  officeId: Scalars['ID'];
  surveyId: Scalars['ID'];
};

export type SurveyAnswersInput = {
  answers: Array<SurveyAnswerQuestionInput>;
  officeId: Scalars['ID'];
  personaId: Scalars['ID'];
  surveyId: Scalars['ID'];
};

export type SurveyGuestInput = {
  guestId: Scalars['ID'];
  surveyId: Scalars['ID'];
};

export type SurveyPersonaInput = {
  personaId: Scalars['ID'];
  surveyId: Scalars['ID'];
};

export enum SurveyType {
  TypeNashiPass = 'TYPE_NASHI_PASS',
  TypeUnspecified = 'TYPE_UNSPECIFIED'
}

export type Tag = Entity & {
  __typename?: 'Tag';
  /** Anchors with this tag */
  anchors: Array<Anchor>;
  /** Areas with this tag */
  areas: Array<Area>;
  /** Floorplans with this tag */
  floorplans: Array<Floorplan>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organization: Organization;
  /** Permissions where this Tag is a Subject */
  permissions?: Maybe<Array<Permission>>;
  /** Personas with this tag */
  personas: Array<Persona>;
  /** Seats with this tag */
  seats: Array<Seat>;
  /** Teams with this tag */
  teams: Array<Team>;
};

export type TagEntitiesInput = {
  organizationId: Scalars['ID'];
};

export type TagsPersonaInput = {
  personaId: Scalars['ID'];
  tagIds: Array<Scalars['ID']>;
};

export type TagsSeatInput = {
  seatId: Scalars['ID'];
  sessionId?: InputMaybe<Scalars['ID']>;
  tagIds: Array<Scalars['ID']>;
};

/** A Team is a grouping of users within an organization that supports hierarchy */
export type Team = Entity & {
  __typename?: 'Team';
  ancestors: Array<Team>;
  assignedCount: Scalars['Int'];
  color: Scalars['String'];
  descendants: Array<Team>;
  headcount: Scalars['Int'];
  id: Scalars['ID'];
  /** Personas that are members of this team */
  members: Array<Persona>;
  name: Scalars['String'];
  /** Organization that contains this Team */
  organization: Organization;
  /** Permissions where this Team is a Subject */
  permissions: Array<Permission>;
  /** Reservations for this Team */
  reservations: Array<Reservation>;
  /** Seats assigned to this Team */
  seats: Array<Seat>;
  /** The organizational lead for this team */
  teamLead?: Maybe<Persona>;
  type: TeamType;
};

export enum TeamType {
  TypeDepartment = 'TYPE_DEPARTMENT',
  TypeHcmDepartment = 'TYPE_HCM_DEPARTMENT',
  TypeHcmDivision = 'TYPE_HCM_DIVISION',
  TypeHcmGroup = 'TYPE_HCM_GROUP',
  TypeHcmReporting = 'TYPE_HCM_REPORTING',
  TypeHcmTeam = 'TYPE_HCM_TEAM',
  TypeProject = 'TYPE_PROJECT',
  TypeReporting = 'TYPE_REPORTING',
  TypeUnspecified = 'TYPE_UNSPECIFIED'
}

export type TeamsTeamInput = {
  childTeamIds: Array<Scalars['ID']>;
  parentTeamId: Scalars['ID'];
  sessionId?: InputMaybe<Scalars['ID']>;
};

export type TimeSpec = {
  __typename?: 'TimeSpec';
  name: Scalars['String'];
  time: Scalars['Time'];
  zone?: Maybe<Scalars['String']>;
};

export type TimeSpecInput = {
  name?: InputMaybe<Scalars['String']>;
  time?: InputMaybe<Scalars['Time']>;
  zone?: InputMaybe<Scalars['String']>;
};

export type UpdateAnchorInput = {
  id: Scalars['ID'];
  /** GeoJSON that represents the location of this object */
  location?: InputMaybe<PointInput>;
  name?: InputMaybe<Scalars['String']>;
  /** Session ID to use during update. */
  sessionId?: InputMaybe<Scalars['ID']>;
};

export type UpdateAreaInput = {
  /** GeoJSON bounding polygon for this area */
  boundary?: InputMaybe<PolygonInput>;
  capacity?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  /** Session ID to use during update. */
  sessionId?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<AreaType>;
};

export type UpdateBuildingInput = {
  address?: InputMaybe<AddressInput>;
  id: Scalars['ID'];
  landlord?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<PointInput>;
  type?: InputMaybe<BuildingType>;
};

export type UpdateCommentInput = {
  id: Scalars['ID'];
  text?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CommentType>;
};

export type UpdateCustomerInput = {
  address?: InputMaybe<AddressInput>;
  configuration?: InputMaybe<CustomerConfigurationInput>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type UpdateFloorInput = {
  boundary?: InputMaybe<PolygonInput>;
  id: Scalars['ID'];
  index?: InputMaybe<Scalars['Int']>;
};

export type UpdateFloorplanInput = {
  boundary?: InputMaybe<PolygonInput>;
  description?: InputMaybe<Scalars['String']>;
  doors?: InputMaybe<Array<DoorInput>>;
  floorplanUrl?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  lineWalls?: InputMaybe<Array<LineWallInput>>;
  name?: InputMaybe<Scalars['String']>;
  overlayPosition?: InputMaybe<OverlayPositionInput>;
  previewUrl?: InputMaybe<Scalars['String']>;
  referencePoints?: InputMaybe<Array<ReferencePointInput>>;
  scale?: InputMaybe<Scalars['Float']>;
  scaleLine?: InputMaybe<LineStringInput>;
  settings?: InputMaybe<FloorplanSettingsInput>;
  walls?: InputMaybe<Array<WallInput>>;
};

export type UpdateGuestInput = {
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateHcmInput = {
  authClientId?: InputMaybe<Scalars['String']>;
  authClientSecret?: InputMaybe<Scalars['String']>;
  authDataSource?: InputMaybe<Scalars['String']>;
  authName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  teamType?: InputMaybe<TeamType>;
  type?: InputMaybe<HcmVendorType>;
  version?: InputMaybe<Scalars['Int']>;
};

export type UpdateOfficeInput = {
  address?: InputMaybe<AddressInput>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  settings?: InputMaybe<OfficeSettingsInput>;
};

export type UpdateOrganizationInput = {
  address?: InputMaybe<AddressInput>;
  id: Scalars['ID'];
  logo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  settings?: InputMaybe<OrganizationSettingsInput>;
};

export type UpdatePersonInput = {
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdatePersonaInput = {
  displayName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Time']>;
  id: Scalars['ID'];
  integrations?: InputMaybe<IntegrationSettingsInput>;
  name?: InputMaybe<Scalars['String']>;
  settings?: InputMaybe<PersonaSettingsInput>;
  startDate?: InputMaybe<Scalars['Time']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PersonaType>;
};

export type UpdateReservationInput = {
  actionBy?: InputMaybe<Scalars['ID']>;
  confirmedAt?: InputMaybe<Scalars['Time']>;
  id: Scalars['ID'];
  seatId?: InputMaybe<Scalars['ID']>;
  startTime?: InputMaybe<Scalars['Time']>;
};

export type UpdateScheduleInput = {
  allDay?: InputMaybe<Scalars['Boolean']>;
  begin?: InputMaybe<Scalars['Time']>;
  end?: InputMaybe<Scalars['Time']>;
  exclusions?: InputMaybe<Array<TimeSpecInput>>;
  id: Scalars['ID'];
  inclusions?: InputMaybe<Array<TimeSpecInput>>;
  name?: InputMaybe<Scalars['String']>;
  weekdays?: InputMaybe<Array<Scalars['Int']>>;
};

export type UpdateSeatInput = {
  /** GeoJSON bounding polygon for this object */
  boundary?: InputMaybe<PolygonInput>;
  /** Dimensions of the seat (width x height x depth) */
  dimensions?: InputMaybe<DimensionsInput>;
  disabled?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  /** GeoJSON location for the seat with respect to the desk */
  location?: InputMaybe<PointInput>;
  name?: InputMaybe<Scalars['String']>;
  /** Rotation of the seat */
  rotation?: InputMaybe<Scalars['Float']>;
  /** Session ID to use during update. */
  sessionId?: InputMaybe<Scalars['ID']>;
};

export type UpdateSurveyInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
  ttl?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<SurveyType>;
};

export type UpdateTagInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateTeamInput = {
  color?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TeamType>;
};

export type UpdateUserInput = {
  id: Scalars['ID'];
  type: UserType;
};

export type User = Entity & {
  __typename?: 'User';
  customer: Customer;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  name: Scalars['String'];
  nickname: Scalars['String'];
  organizations: Array<Organization>;
  pending: Scalars['Boolean'];
  persona?: Maybe<Persona>;
  photoUrl: Scalars['String'];
  type: UserType;
};

export enum UserType {
  TypeAdmin = 'TYPE_ADMIN',
  TypeEmployee = 'TYPE_EMPLOYEE',
  TypeUnspecified = 'TYPE_UNSPECIFIED'
}

export type VisitorEvent = {
  __typename?: 'VisitorEvent';
  answeredSurvey: Scalars['Boolean'];
  employee: PersonaEventData;
  eventTime: Scalars['Time'];
  floorplan?: Maybe<FloorplanEventData>;
  id: Scalars['ID'];
  office: OfficeEventData;
  pass: Scalars['Boolean'];
  reservationEvent?: Maybe<VisitorReservationEvent>;
  seat?: Maybe<SeatEventData>;
};

export type VisitorReservationEvent = {
  __typename?: 'VisitorReservationEvent';
  actionBy?: Maybe<PersonaEventData>;
  canceled?: Maybe<Scalars['Time']>;
  confirmed?: Maybe<Scalars['Time']>;
  reservation: Reservation;
  status: ReservationEventStatus;
};

export type Wall = {
  __typename?: 'Wall';
  boundary: Polygon;
  id: Scalars['ID'];
};

export type WallInput = {
  boundary?: InputMaybe<PolygonInput>;
  id: Scalars['ID'];
};
