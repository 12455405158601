import * as React from 'react';
import { Text, BoxProps } from '@chakra-ui/react';

type OverflowTextProps = {
  lineCount: number;
} & BoxProps;

const OverflowText: React.FC<OverflowTextProps> = (props) => {
  const { lineCount, ...rest } = props;
  return (
    <Text
      {...rest}
      // NOTE: limited browser support
      // supported everywhere except ie11
      // https://css-tricks.com/almanac/properties/l/line-clamp/
      style={{ display: '-webkit-box', WebkitLineClamp: lineCount, WebkitBoxOrient: 'vertical', overflow: 'hidden' }}
    ></Text>
  );
};

export default OverflowText;
