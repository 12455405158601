import { Flex, Text } from '@chakra-ui/react';
import * as React from 'react';

import { colors, presets } from '../theme';

const OrgImageFallback: React.FC<{ name?: string; size: number | string; textSize: number | string }> = ({
  name,
  size,
  textSize,
}) => {
  return (
    <Flex
      color={colors.grayscale[50]}
      minWidth={size}
      alignItems="center"
      justifyContent="center"
      mr={2}
      borderRadius={5}
      minHeight={size}
      background={colors.grayscale[600]}
      textTransform="uppercase"
      borderColor={presets.BORDER_COLOR}
      borderWidth={1}
      borderStyle="solid"
    >
      <Text fontSize={textSize}>{name?.charAt(0)}</Text>
    </Flex>
  );
};

export default OrgImageFallback;
