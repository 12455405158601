import * as React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

import { useIntersect } from '../hooks/userIntersect';
import { presets } from '../theme';

type StickyProps = {
  offsetX?: string;
  offsetY?: string;
  hideStuckBorder?: boolean;
} & BoxProps;

const Sticky = React.forwardRef<HTMLDivElement, StickyProps>((props, ref) => {
  const { children, offsetX, offsetY, hideStuckBorder, ...rest } = props;

  const [intersectRef, entry] = useIntersect({
    threshold: [0, 1],
  });

  const isStuck = entry?.intersectionRatio === 0;

  const borderProps =
    isStuck && !hideStuckBorder
      ? { borderBottomWidth: '1px', borderStyle: 'solid', borderColor: presets.BORDER_COLOR }
      : { borderBottomWidth: '1px', borderStyle: 'solid', borderColor: 'transparent' };

  return (
    <>
      {/* NOTE: sentinel node */}
      <Box ref={intersectRef} transform={`translate(${offsetX || 0},${offsetY || 0})`} />
      <Box ref={ref} position="sticky" {...borderProps} {...rest}>
        {children}
      </Box>
    </>
  );
});

export default Sticky;
