import * as React from 'react';
import { useLazyLoadQuery } from 'react-relay/hooks';
import graphql from 'babel-plugin-relay/macro';
import { MenuItem, Text, Image } from '@chakra-ui/react';
import _sortBy from 'lodash.sortby';

import { presets, colors } from '../theme';

import OrgImageFallback from './OrgImageFallback';
import Separator from './Separator';
import { OrgChooserListAllOrganizationsQuery } from './__generated__/OrgChooserListAllOrganizationsQuery.graphql';

type OrgChooserListProps = {
  onSelectOrganization: (id: string) => void;
};

const OrgChooserList: React.FC<OrgChooserListProps> = (props) => {
  const { allOrganizations } = useLazyLoadQuery<OrgChooserListAllOrganizationsQuery>(
    graphql`
      query OrgChooserListAllOrganizationsQuery {
        allOrganizations {
          id
          name
          logo
        }
      }
    `,
    {}
  );

  return (
    <>
      {_sortBy(allOrganizations, 'name').map((o) => (
        <MenuItem
          key={o.id}
          onClick={() => {
            props.onSelectOrganization(o.id);
          }}
        >
          <Image
            boxSize={6}
            objectFit="cover"
            src={o.logo}
            borderColor={presets.BORDER_COLOR}
            borderWidth={1}
            borderStyle="solid"
            borderRadius={5}
            mr={2}
            fallback={<OrgImageFallback name={o.name} size={6} textSize="sm" />}
          />
          <Text color={colors.grayscale[800]}>{o.name}</Text>
        </MenuItem>
      ))}

      <Separator />
    </>
  );
};

export default OrgChooserList;
