/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type OrgDropdown_organization = {
    readonly logo: string;
    readonly name: string;
    readonly " $refType": "OrgDropdown_organization";
};
export type OrgDropdown_organization$data = OrgDropdown_organization;
export type OrgDropdown_organization$key = {
    readonly " $data"?: OrgDropdown_organization$data;
    readonly " $fragmentRefs": FragmentRefs<"OrgDropdown_organization">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrgDropdown_organization",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "logo",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
        }
    ],
    "type": "Organization",
    "abstractKey": null
} as any;
(node as any).hash = '7425c4b443797347032540a3734741d7';
export default node;
