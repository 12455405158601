import * as React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { fetchQuery, useRelayEnvironment } from 'react-relay/hooks';
import { createOperationDescriptor, getRequest } from 'relay-runtime';
import { Environment } from 'react-relay';
import useAsyncFn from 'react-use/lib/useAsyncFn';

import { useAuth } from './contexts/authContext';
import { TreatmentsProvider } from './contexts/treatmentsContext';
import App from './App';
import { AppDataRootUserQuery } from './__generated__/AppDataRootUserQuery.graphql';

// TODO(ww): fetchUserAsync and fetchOrganizationAsync are not using useLazyLoadQuery because App cannot suspend since it renders PrivateRoute
// which in turns starts the login flow by calling auth.login. However, we've noticed that all the routes rendered here currently are authed.
// so in the future we can possibly call auth.login within App.tsx (or a wrapping container thats under the auth context), preload these queries
// and pass them in directly to App.tsx to be consumed using usePreloadedQuery
// diagram: https://excalidraw.com/#json=4652068061052928,drFWU0amJJTVEKEafxnDUg
const fetchUserAsync = async (environment: Environment) => {
  const query = graphql`
    query AppDataRootUserQuery {
      user {
        id
        email

        ...App_user

        organizations {
          id
        }
      }
    }
  `;

  const variables = {};

  const request = getRequest(query);
  const operation = createOperationDescriptor(request, variables);
  environment.retain(operation);

  const response = await fetchQuery<AppDataRootUserQuery>(environment, query, variables).toPromise();

  return response?.user;
};

type AppDataRootProps = {};

const AppDataRoot: React.FC<AppDataRootProps> = (props) => {
  const auth = useAuth();
  const environment = useRelayEnvironment();

  const [userState, startFetchUser] = useAsyncFn(fetchUserAsync, [], { loading: true });

  const firstOrganizationId = userState.value?.organizations[0]?.id;

  React.useEffect(
    function fetchUser() {
      if (auth.isAuthenticated) {
        startFetchUser(environment);
      }
    },
    [auth.isAuthenticated, environment, startFetchUser]
  );

  React.useEffect(
    function throwError() {
      if (userState.error) {
        throw userState.error;
      }
    },
    [userState.error]
  );

  return (
    // TreatmentsProvider is set here because we need access to the user's id and email to identify
    <TreatmentsProvider splitKey={userState.value?.id} email={userState.value?.email}>
      <App user={userState.value} firstOrganizationId={firstOrganizationId} />
    </TreatmentsProvider>
  );
};

export default React.memo(AppDataRoot);
