import * as React from 'react';
import { Flex, Box, BoxProps, Collapse, Text } from '@chakra-ui/react';
import { useRouteMatch } from 'react-router-dom';
import { FaCaretDown, FaCaretRight, FaPlus } from 'react-icons/fa';
import _sortBy from 'lodash.sortby';
import { useFragment } from 'react-relay/hooks';
import graphql from 'babel-plugin-relay/macro';

import { colors, presets } from '../theme';
import * as routes from '../routes';
import Menu from '../icons/Menu';
import MenuHovered from '../icons/MenuHovered';
import MenuExpanded from '../icons/MenuExpanded';
import { useTreatment_DEPRECATED } from '../contexts/treatmentsContext';
import { useNav } from '../contexts/navContext';
import OverflowText from '../components/OverflowText';
import { SPLITS } from '../constants/featureFlipperConstants';
import {
  getRawHiddenOfficeIds,
  getSideNavSubsectionCollapsedForFloorplan,
  hasHiddenOfficeId,
  setSideNavSubsectionCollapsed,
} from '../utils/localStorageUtils';
import { UserType } from '../graphql/generated';
import { useApp } from '../App';
import { getTouchAwareResponsiveValues } from '../utils/mobileUtils';

import Sticky from './Sticky';
import OrgDropdown from './OrgDropdown';
import MaybeLink from './MaybeLink';
import IconButton from './IconButton';
import { SideNav_organization$key } from './__generated__/SideNav_organization.graphql';
import Sentinel from './Sentinel';
import { SideNav_user$key } from './__generated__/SideNav_user.graphql';

import CommentIcon from '@app/icons/CommentIcon';
import { EngagementService } from '@app/services/engagement';
import { Analytics } from '@app/services/analytics';
import { useRect } from '@app/hooks/useRect';
import { isMovingTowardsElement } from '@app/utils/navUtils';
import { NAV_OFFICE_LINK, NAV_OFFICE_LINK_TITLE } from '@app/constants/dataIdConstants';

const NAV_ITEM_HEIGHT = '32px';
const NAV_CLASS = 'SideNav';
const NAV_MENU_CLASS = 'SideNavMenu';
const NAV_BUTTON_CLASS = 'SideNavButton';

const IMPOSSIBLE_ROUTE = '/impossible-route-to-match';

type MenuLinkProps = {
  to?: string;
  title: string;
  additionalMatches?: string[];
} & BoxProps;

const MenuLink: React.FC<MenuLinkProps> = (props) => {
  const { to, title, ...rest } = props;

  const match = useRouteMatch(to || IMPOSSIBLE_ROUTE);

  return (
    <MaybeLink to={to}>
      <Flex
        flexDirection="row"
        alignItems="center"
        borderRadius={2}
        mr={4}
        pl="1.75rem"
        height={NAV_ITEM_HEIGHT}
        backgroundColor={match ? colors.indigo[600] : 'none'}
        color={match ? presets.BACKGROUND_COLOR : colors.grayscale[800]}
        _hover={{
          backgroundColor: match ? colors.indigo[600] : presets.HOVER_BACKGROUND,
        }}
        {...rest}
      >
        <OverflowText fontWeight="normal" lineCount={1}>
          {title}
        </OverflowText>
      </Flex>
    </MaybeLink>
  );
};

type MenuLinkSubSectionProps = {
  id: string;
  heading: MenuLinkProps;
  items: MenuLinkProps[];
};

const MenuLinkSubSection: React.FC<MenuLinkSubSectionProps> = (props) => {
  const [isCollapsed, setIsCollapsed] = React.useState(getSideNavSubsectionCollapsedForFloorplan(props.id));

  const match = useRouteMatch(props.heading.to || IMPOSSIBLE_ROUTE);

  React.useEffect(() => {
    setSideNavSubsectionCollapsed(props.id, isCollapsed);
  }, [isCollapsed, props.id]);

  const renderCaret = () => {
    if (!props.items?.length) return null;
    const caretColor = match ? colors.grayscale[50] : undefined;
    return isCollapsed ? <FaCaretRight fill={caretColor} /> : <FaCaretDown fill={caretColor} />;
  };

  const handleCaretClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (props.items.length) {
      setIsCollapsed(!isCollapsed);
    }
  };

  const selectedStyles: BoxProps = {
    backgroundColor: match ? colors.indigo[600] : 'none',
    color: match ? presets.BACKGROUND_COLOR : colors.grayscale[800],
    _hover: {
      backgroundColor: match ? colors.indigo[600] : 'none',
    },
  };

  let hoverStyles: BoxProps = {};
  if (props.heading.to) {
    hoverStyles = {
      _hover: {
        backgroundColor: !match ? presets.HOVER_BACKGROUND : undefined,
      },
    };
  }

  return (
    <Flex flexDirection="column" mt={2} width="100%" data-id={NAV_OFFICE_LINK}>
      <MaybeLink to={props.heading.to}>
        <Flex
          alignItems="center"
          height={NAV_ITEM_HEIGHT}
          mr={4}
          overflow="hidden"
          borderRadius={2}
          {...selectedStyles}
          {...hoverStyles}
        >
          <Box
            cursor="pointer"
            m={1}
            borderRadius={2}
            onClick={handleCaretClick}
            _hover={{
              backgroundColor: match ? colors.indigo[600] : colors.grayscale[400],
            }}
            _focus={{
              outline: 'none',
              boxShadow: `0 0 0 2px ${match ? presets.HOVER_BACKGROUND : colors.indigo[600]}`,
            }}
          >
            {renderCaret()}
          </Box>

          <OverflowText fontWeight="normal" lineCount={1} pl={1} data-id={NAV_OFFICE_LINK_TITLE}>
            {props.heading.title}
          </OverflowText>
        </Flex>
      </MaybeLink>

      <Flex flexDirection="column">
        <Collapse in={!isCollapsed}>
          {props.items.map((item, i) => (
            <MenuLink {...item} key={item.title || i} />
          ))}
        </Collapse>
      </Flex>
    </Flex>
  );
};

type MaybeLinkType = {
  to?: string;
  title?: string;
  additionalMatches?: string[];
};

type MenuLinkSectionProps = {
  heading: MaybeLinkType;
  items: MenuLinkSubSectionProps[];
  action?: React.ReactElement;
};

const MenuLinkSection: React.FC<MenuLinkSectionProps> = (props) => {
  const routeMatch = useRouteMatch(props.heading.additionalMatches || props.heading.to || IMPOSSIBLE_ROUTE);
  let match = !!routeMatch;

  if (!!props.heading.additionalMatches) {
    match = !!routeMatch?.isExact;
  }

  let hoverStyles: BoxProps = {};
  if (props.heading.to) {
    hoverStyles = {
      _hover: {
        backgroundColor: !match ? presets.HOVER_BACKGROUND : undefined,
      },
    };
  }

  return (
    <Flex flexDirection="column" mt={2}>
      <Flex justifyContent="space-between" alignItems="center" mr="1rem">
        <MaybeLink to={props.heading.to} style={{ marginRight: props.action ? '1rem' : undefined, width: '100%' }}>
          <OverflowText
            lineCount={1}
            fontWeight="semibold"
            color={match ? presets.BACKGROUND_COLOR : colors.grayscale[800]}
            padding={1}
            borderRadius={2}
            backgroundColor={match ? colors.indigo[600] : undefined}
            {...hoverStyles}
          >
            {props.heading.title}
          </OverflowText>
        </MaybeLink>

        {!!props.action && props.action}
      </Flex>

      <Flex flexDirection="column">
        {props.items?.map((i) => (
          <MenuLinkSubSection key={i.heading?.title || i.items?.[0].title} {...i} />
        ))}
      </Flex>
    </Flex>
  );
};

export enum SideNavStates {
  HIDDEN = 'HIDDEN',
  HOVERED = 'HOVERED',
  EXPANDED = 'EXPANDED',
}

type SideNavButtonProps = {};

export const SideNavButton: React.FC<SideNavButtonProps> = (props) => {
  const { displayState, onMouseEnter, onMouseLeave, onToggle } = useNav();
  const buttonRef = React.useRef<HTMLDivElement | null>(null);
  const buttonRect = useRect(buttonRef);

  let content = null;
  switch (displayState) {
    case SideNavStates.HIDDEN:
      content = Menu;
      break;
    case SideNavStates.HOVERED:
      content = MenuHovered;
      break;
    case SideNavStates.EXPANDED:
      content = MenuExpanded;
      break;
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onToggle();
    }
  };

  return (
    <Box
      className={NAV_BUTTON_CLASS}
      ref={buttonRef}
      onClick={onToggle}
      onKeyDown={handleKeyDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={(e) => {
        // If leaving towards the nav menu don't trigger mouse leave
        if (e.clientX < buttonRect.right && isMovingTowardsElement(e, NAV_MENU_CLASS, 40)) {
          return;
        }
        onMouseLeave();
      }}
      tabIndex={0}
      borderRadius={2}
      display={getTouchAwareResponsiveValues(['none', null, 'block'])}
      _hover={{
        backgroundColor: presets.HOVER_BACKGROUND,
      }}
    >
      <IconButton icon={content} />
    </Box>
  );
};

type SideNavProps = {
  organization: SideNav_organization$key;
  user: SideNav_user$key;
};

const SIDE_NAV_Y_PX = 50;
const SIDE_NAV_WIDTH_PX = 240;
const SIDE_NAV_HOVER_PEEK_PX = 20;
const SIDE_NAV_X_PX = SIDE_NAV_WIDTH_PX - SIDE_NAV_HOVER_PEEK_PX;

const SideNav: React.FC<SideNavProps> = (props) => {
  const { displayState, onMouseEnter, onMouseLeave } = useNav();
  const [containerWidth, setContainerWidth] = React.useState(displayState === SideNavStates.EXPANDED ? SIDE_NAV_WIDTH_PX : 0);
  const [yTranslate, setYTranslate] = React.useState(displayState === SideNavStates.EXPANDED ? 0 : SIDE_NAV_Y_PX);
  const [xTranslate, setXTranslate] = React.useState(displayState === SideNavStates.EXPANDED ? 0 : SIDE_NAV_X_PX);
  const [showFeedbackBorder, setShowFeedbackBorder] = React.useState(false);

  const app = useApp();

  const organization = useFragment(
    graphql`
      fragment SideNav_organization on Organization {
        ...OrgDropdown_organization

        offices {
          id
          name

          floorplans {
            id
            name

            floor {
              index
            }

            boundary {
              lineStrings {
                __typename
              }
            }
          }
        }
      }
    `,
    props.organization
  );

  const user = useFragment(
    graphql`
      fragment SideNav_user on User {
        ...OrgDropdown_user
      }
    `,
    props.user
  );

  const isPlaygroundOn = useTreatment_DEPRECATED(SPLITS.GRAPHQL_PLAYGROUND);
  const isLaunchSequenceOn = useTreatment_DEPRECATED(SPLITS.LAUNCH_SEQUENCE);
  const isHideOfficeOn = useTreatment_DEPRECATED(SPLITS.HIDE_OFFICE);
  const isAreaAsLayersAndWallsOn = useTreatment_DEPRECATED(SPLITS.AREA_AS_LAYERS_AND_WALLS);
  const isEEV2HomeOn = useTreatment_DEPRECATED(SPLITS.EEV2_HOME);
  const isEEV2ReservationOn = useTreatment_DEPRECATED(SPLITS.EEV2_RESERVATION);

  // Use of this variable is just a hack to rerender the items on update to this local storage item
  const rawHiddenOfficeIds = getRawHiddenOfficeIds();

  React.useEffect(
    function sendResizeEvent() {
      // https://github.com/react-grid-layout/react-grid-layout/issues/933

      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      });
    },
    [displayState]
  );

  const transitions = React.useMemo(() => {
    return {
      [SideNavStates.EXPANDED]: {
        funcs: [
          {
            setter: setContainerWidth,
            endState: SIDE_NAV_WIDTH_PX,
          },
          {
            setter: setYTranslate,
            endState: 0,
          },
          {
            setter: setXTranslate,
            endState: 0,
          },
        ],
      },
      [SideNavStates.HOVERED]: {
        funcs: [
          {
            setter: setXTranslate,
            endState: 0,
          },
          {
            setter: setYTranslate,
            endState: SIDE_NAV_Y_PX,
          },
          {
            setter: setContainerWidth,
            endState: 0,
          },
        ],
      },
      [SideNavStates.HIDDEN]: {
        funcs: [
          {
            setter: setXTranslate,
            endState: SIDE_NAV_X_PX,
          },
          {
            setter: setYTranslate,
            endState: SIDE_NAV_Y_PX,
          },
          {
            setter: setContainerWidth,
            endState: 0,
          },
        ],
      },
    };
  }, []);

  React.useEffect(() => {
    if (!displayState) {
      return;
    }

    const tween = transitions[displayState];

    tween.funcs.forEach((f) => {
      const newValue = f.endState;
      f.setter((oldVal) => {
        return newValue;
      });
    });
  }, [transitions, displayState]);

  const items: MenuLinkSectionProps[] = React.useMemo(() => {
    const offices = organization.offices;

    const items: MenuLinkSectionProps[] = [];

    if (!isEEV2ReservationOn || app.currentAccount === UserType.TypeAdmin) {
      items.push({
        heading: { title: 'Spaces' },
        action:
          isLaunchSequenceOn && app.currentAccount === UserType.TypeAdmin ? (
            <MaybeLink to={`${routes.ONBOARDING}?flow=create-office&organizationId=${app.selectedOrganizationId}`}>
              <Box
                _hover={{
                  backgroundColor: presets.HOVER_BACKGROUND,
                }}
                padding={1}
              >
                <FaPlus />
              </Box>
            </MaybeLink>
          ) : undefined,
        items: _sortBy(offices, 'name')
          .filter((o) => {
            const isOfficeHidden = isHideOfficeOn && rawHiddenOfficeIds && hasHiddenOfficeId(o.id);
            if (isOfficeHidden) {
              return false;
            }

            const isAllFloorplansInProgress = o.floorplans.every(
              (f) => f.boundary.lineStrings.length === 0 && !isAreaAsLayersAndWallsOn
            );
            const hasNoFloorplans = !o.floorplans.length;
            if (isAllFloorplansInProgress || hasNoFloorplans) {
              return isLaunchSequenceOn && app.currentAccount === UserType.TypeAdmin;
            }

            return true;
          })
          .map((o) => {
            return {
              id: o.id,
              heading: {
                title: o.name,
                to: app.currentAccount === UserType.TypeAdmin ? routes.OFFICE(o.id) : undefined,
              },
              items: _sortBy(o.floorplans, (f) => f.floor.index)
                .filter((f) => {
                  const isInProgess = f.boundary.lineStrings.length === 0 && !isAreaAsLayersAndWallsOn;
                  if (isInProgess) {
                    return isLaunchSequenceOn && app.currentAccount === UserType.TypeAdmin;
                  }

                  return true;
                })
                .map((f) => {
                  return {
                    title: f.name,
                    to: app.currentAccount === UserType.TypeAdmin ? routes.FLOORPLAN(f.id) : routes.EMPLOYEE_EXPERIENCE(f.id),
                    id: f.id,
                  };
                }),
            };
          }),
      });
    }

    if (isPlaygroundOn && app.currentAccount === UserType.TypeAdmin) {
      items.push({
        heading: { title: 'Playground', to: routes.PLAYGROUND },
        items: [],
      });
    }

    if (app.currentAccount === UserType.TypeAdmin) {
      items.unshift({
        heading: { title: 'Insights', to: routes.INSIGHTS },
        items: [],
      });
    }

    if (isEEV2HomeOn) {
      items.unshift({
        // NOTE: order matters for additionalMatches, more specific should be first
        heading: { title: 'Home', to: routes.ROOT, additionalMatches: [routes.HOME_RESERVATIONS, routes.ROOT] },
        items: [],
      });
    }

    return items;
  }, [
    organization.offices,
    isEEV2ReservationOn,
    isPlaygroundOn,
    app.currentAccount,
    app.selectedOrganizationId,
    isEEV2HomeOn,
    isLaunchSequenceOn,
    isHideOfficeOn,
    rawHiddenOfficeIds,
    isAreaAsLayersAndWallsOn,
  ]);

  const visibilityStyle = React.useMemo(() => {
    let styles: { [key: string]: BoxProps } = {};

    switch (displayState) {
      case SideNavStates.HIDDEN:
        styles = {
          container: {
            width: containerWidth,
          },
          menu: {
            width: SIDE_NAV_WIDTH_PX,
            height: '80vh',
            boxShadow: 'md',
            transform: `translate3d(-${xTranslate}px, ${yTranslate}px, 0px)`,
            opacity: xTranslate === SIDE_NAV_X_PX ? 0 : 1,
          },
        };
        break;
      case SideNavStates.HOVERED:
        styles = {
          container: {
            width: containerWidth,
          },
          menu: {
            width: SIDE_NAV_WIDTH_PX,
            height: '80vh',
            transform: `translate3d(-${xTranslate}px, ${yTranslate}px, 0px)`,
            boxShadow: 'md',
          },
        };
        break;
      case SideNavStates.EXPANDED:
        styles = {
          container: {
            width: containerWidth,
          },
          menu: {
            // subtract px of container border
            width: SIDE_NAV_WIDTH_PX - 1,
            transform: `translate3d(-${xTranslate}px, ${yTranslate}px, 0px)`,
          },
        };
        break;
    }

    return styles;
  }, [containerWidth, displayState, xTranslate, yTranslate]);

  const feedbackBorderProps = showFeedbackBorder
    ? { borderTopWidth: '1px', borderStyle: 'solid', borderColor: presets.BORDER_COLOR }
    : { borderTopWidth: '1px', borderStyle: 'solid', borderColor: 'transparent' };

  return (
    <Flex
      className={NAV_CLASS}
      border="none"
      borderRight={1}
      borderStyle="solid"
      borderRadius={0}
      borderColor={presets.BORDER_COLOR}
      height="100%"
      backfacevisbility="hidden"
      flexDirection="column"
      {...visibilityStyle.container}
    >
      <Flex height="100%" flexDirection="column" position="relative">
        <Flex
          className={NAV_MENU_CLASS}
          flexDirection="column"
          position="absolute"
          // subtract px of container border
          minWidth={SIDE_NAV_WIDTH_PX - 1}
          maxWidth={360}
          height="100%"
          onMouseEnter={onMouseEnter}
          onMouseLeave={(e) => {
            // If leaving towards the nav button don't trigger mouse leave
            if (isMovingTowardsElement(e, NAV_BUTTON_CLASS, -30)) {
              return;
            }
            onMouseLeave();
          }}
          overflowY="auto"
          onClick={(e) => e.stopPropagation()}
          onContextMenu={(e) => e.stopPropagation()}
          backfacevisbility="hidden"
          backgroundColor={presets.BACKGROUND_COLOR}
          transition="width .2s, transform .35s, opacity .35s"
          {...visibilityStyle.menu}
        >
          <Sticky
            display="flex"
            top="0px"
            minHeight={presets.HEADER_HEIGHT}
            backgroundColor={presets.BACKGROUND_COLOR}
            justifyContent="space-between"
            alignItems="center"
            pl={6}
            pr={4}
            hideStuckBorder={displayState !== SideNavStates.EXPANDED}
          >
            <OrgDropdown organization={organization} user={user} />

            {displayState === SideNavStates.EXPANDED && <SideNavButton />}
          </Sticky>

          <Box ml={6}>
            {items.map((item, i) => (
              <MenuLinkSection key={item.heading?.title || i} {...item} />
            ))}
          </Box>

          <Sentinel
            threshold={[0]}
            onThresholdMet={(threshold) => {
              setShowFeedbackBorder(threshold === 0);
            }}
          ></Sentinel>
        </Flex>
      </Flex>

      {displayState === SideNavStates.EXPANDED && (
        <Flex height={presets.HEADER_HEIGHT} role="button" alignItems="center" {...feedbackBorderProps}>
          <Flex
            width="100%"
            m={2}
            mr={4}
            py={1}
            px={1}
            alignItems="center"
            borderRadius={2}
            fontWeight="normal"
            _hover={{ background: presets.HOVER_BACKGROUND }}
            color={colors.grayscale[600]}
            onClick={() => {
              EngagementService.show();
              Analytics.track('Give Feedback Clicked', {
                fullstorySessionId: Analytics.getCurrentFSSessionURL(),
              });
            }}
          >
            <CommentIcon fill={colors.grayscale[600]} />
            <Text fontSize="sm" fontWeight={presets.BOLD_FONT} pl={2}>
              Give Nashi Feedback
            </Text>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default React.memo(SideNav);
