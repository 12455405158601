export const AREA_OVERLAY_TEXT = 'area_overlay_text';
export const AREA_OVERLAY = 'area_overlay';
export const SEAT_OVERLAY = 'seat_overlay';
export const TEAM_EDITOR_BUTTON = 'team_editor_button';
export const FLOORPLAN_DETAILS_PANE = 'floorplan_details_pane';
export const FLOORPLAN_NAME = 'floorplan_name';
export const SETTINGS_COG = 'settings_cog';
export const FORM_SECTION = 'form_section';
export const QR_CODE = 'qr_code';
export const REMOVE_INPUT = 'remove_input';
export const QR_GENERATED_TIME = 'qr_generated_time';
export const SECTION_SELECT = 'section_select';
export const SESSION_BAR = 'session_bar';
export const NAV_OFFICE_LINK = 'nav_office_link';
export const NAV_OFFICE_LINK_TITLE = 'nav_office_link_title';
export const SECTION_SWITCH = 'section_switch';
export const TILE = 'tile';
export const CLOSE_TOAST = 'close_toast';
