const NODE_ENV = process.env.NODE_ENV!;

const NASHI_API_URL = process.env.REACT_APP_NASHI_API_URL!;
const NASHI_INSIGHTS_URL = process.env.REACT_APP_NASHI_INSIGHTS_URL!;
const NASHI_GUEST_URL = process.env.REACT_APP_NASHI_GUEST_URL!;
const NASHI_AUTH_CONFIG_URL = process.env.REACT_APP_NASHI_AUTH_CONFIG_URL!;

const SPLIT_KEY = process.env.REACT_APP_SPLIT_KEY!;

const HEAP_KEY = process.env.REACT_APP_HEAP_KEY!;

const INTERCOM_ID = process.env.REACT_APP_INTERCOM_ID!;

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

const VERSION = process.env.REACT_APP_VERSION!;

const APP_ENV = process.env.REACT_APP_ENV!;

const AUTH_AUDIENCE = process.env.REACT_APP_AUTH_AUDIENCE!;
const AUTH_CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID!;
const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN!;

(window as any).__NASHI_APP_VERSION__ = VERSION;

export {
  VERSION,
  NODE_ENV,
  NASHI_API_URL,
  SPLIT_KEY,
  HEAP_KEY,
  INTERCOM_ID,
  SENTRY_DSN,
  NASHI_INSIGHTS_URL,
  NASHI_GUEST_URL,
  NASHI_AUTH_CONFIG_URL,
  APP_ENV,
  AUTH_AUDIENCE,
  AUTH_CLIENT_ID,
  AUTH_DOMAIN,
};
