/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type App_initializeServices_user = {
    readonly id: string;
    readonly firstName: string;
    readonly name: string;
    readonly email: string;
    readonly organizations: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly headcount: number;
    }>;
    readonly " $refType": "App_initializeServices_user";
};
export type App_initializeServices_user$data = App_initializeServices_user;
export type App_initializeServices_user$key = {
    readonly " $data"?: App_initializeServices_user$data;
    readonly " $fragmentRefs": FragmentRefs<"App_initializeServices_user">;
};



const node: ReaderInlineDataFragment = {
    "kind": "InlineDataFragment",
    "name": "App_initializeServices_user"
} as any;
(node as any).hash = 'ee4ea75a0ad34e2fa1d5189d48abb055';
export default node;
