import * as React from 'react';
import { Text } from '@chakra-ui/react';

import { colors } from '../theme';

const ErrorMessage_DEPRECATED: React.FC = (props) => {
  return (
    <Text fontSize="xl" fontWeight="semibold" color={colors.indigo[600]} textAlign="center">
      {props.children}
    </Text>
  );
};

export default ErrorMessage_DEPRECATED;
