import Perfume from 'perfume.js';
import * as FullStory from '@fullstory/browser';

import { HEAP_KEY, NODE_ENV } from '../config';

import { ErrorReporting } from './errorReporting';

const MAX_LOAD_COUNT = 4;
export class Analytics {
  private static loadCount = 0;
  private static ignoredEvents: string[] = [];

  static addIgnoredEvents(eventNames: string[]) {
    Analytics.ignoredEvents = Analytics.ignoredEvents.concat(eventNames);
  }

  static identify(id: string) {
    if (NODE_ENV === 'development') {
      return;
    }

    try {
      Analytics.load();
      (window as any).heap.identify(id);
    } catch (e) {
      ErrorReporting.report('heap failed to identify user', 'info');
    }
  }

  static addUserProperties(properties: object) {
    if (NODE_ENV === 'development') {
      return;
    }

    try {
      Analytics.load();
      (window as any).heap.addUserProperties(properties);
    } catch (e) {
      ErrorReporting.report('heap failed to add user properties', 'info');
    }
  }

  static load() {
    if (NODE_ENV === 'development') {
      return;
    }

    if (!(window as any).heap) {
      ErrorReporting.report('heap did not load on window', 'info');
    }

    if ((window as any).heap && !(window as any).heap.loaded) {
      (window as any).heap.load(HEAP_KEY);

      if (Analytics.loadCount > MAX_LOAD_COUNT) {
        ErrorReporting.report('Heap tried to load too many times, appears to be blocked by client', 'info');
      }
      Analytics.loadCount++;
    }
  }

  static formatProperties(props: object) {
    return Object.entries(props).reduce((accum, [k, v]) => {
      if (typeof v === 'object') {
        const val = JSON.stringify(v);
        accum[k] = val;
      } else {
        accum[k] = v;
      }
      return accum;
    }, {} as { [key: string]: any });
  }

  static track(name: string, properties?: object) {
    if (NODE_ENV === 'development') {
      return;
    }

    if (Analytics.ignoredEvents.includes(name)) {
      return;
    }

    try {
      Analytics.load();
      (window as any).heap.track(name, properties);
    } catch (e) {
      ErrorReporting.report('heap failed to track event', 'info', { error: e });
    }
  }

  static getCurrentFSSessionURL(now?: boolean): null | string {
    if (NODE_ENV === 'development') {
      return null;
    }

    try {
      return FullStory.getCurrentSessionURL(now);
    } catch (e) {
      // FIXME: potential infinite loop here
      // ErrorReporting.report('heap failed to get current fs session url', 'info', { error: e });
    }

    return null;
  }
}

export const perfume = new Perfume({
  analyticsTracker: (options) => {
    const { metricName, data } = options;
    switch (metricName) {
      case 'navigationTiming':
        // @ts-ignore
        if (data && data.timeToFirstByte && typeof data !== 'number') {
          Analytics.track('navigationTiming', data);
        }
        break;
      case 'networkInformation':
        // @ts-ignore
        if (data && data.effectiveType && typeof data !== 'number') {
          Analytics.track('networkInformation', data);
        }
        break;
      case 'fp':
        Analytics.track('firstPaint', { duration: data });
        break;
      case 'fcp':
        Analytics.track('firstContentfulPaint', { duration: data });
        break;
      case 'fid':
        Analytics.track('firstInputDelay', { duration: data });
        break;
      case 'lcp':
        Analytics.track('largestContentfulPaint', { duration: data });
        break;
      case 'lcpFinal':
        Analytics.track('largestContentfulPaintFinal', { duration: data });
        break;
      case 'cls':
        Analytics.track('cumulativeLayoutShift', { duration: data });
        break;
      case 'clsFinal':
        Analytics.track('cumulativeLayoutShiftFinal', { duration: data });
        break;
      case 'tbt':
        Analytics.track('totalBlockingTime', { duration: data });
        break;
      case 'tbt5S':
        Analytics.track('totalBlockingTime5S', { duration: data });
        break;
      case 'tbt10S':
        Analytics.track('totalBlockingTime10S', { duration: data });
        break;
      case 'tbtFinal':
        Analytics.track('totalBlockingTimeFinal', { duration: data });
        break;
      default:
        Analytics.track(metricName, { duration: data });
        break;
    }
  },
});

// FIXME: fix this type error, wrapSend should return T if given T
export function wrapSend<T extends (...args: any) => any>(s: T): T {
  // @ts-ignore
  return (...args: any) => {
    const [first] = args;

    try {
      if (typeof first === 'string') {
        Analytics.track(first);
      } else {
        const {
          type,

          // NOTE: the following fields are ginormous relay ref objects, don't serialize them
          organization,
          floorplan,
          tagsForEntities,

          ...rest
        } = first;
        const props = Analytics.formatProperties(rest);
        Analytics.track(type, props);
      }
    } catch (e) {}

    return s(...args);
  };
}
