import * as React from 'react';

import { SPLITS } from './constants/featureFlipperConstants';
import { UserType } from './graphql/generated';
import { AppRouteType } from './types/appRouteType';
import GenericErrorBoundary from './components/GenericErrorBoundary';
import GuestPassErrorBoundary from './components/GuestPassErrorBoundary';

const EditFloorplan = React.lazy(() => import(/* webpackChunkName: "EditFloorplan" */ './pages/EditFloorplan/EditFloorplan'));
const EditFloorplanV2 = React.lazy(
  () => import(/* webpackChunkName: "EditFloorplanV2" */ './pages/EditFloorplanV2/EditFloorplanV2')
);
const Playground = React.lazy(() => import(/* webpackChunkName: "Playground" */ './pages/Playground'));
const FloorplanRelayPreload = React.lazy(
  () => import(/* webpackChunkName: "FloorplanRelayPreload" */ './pages/FloorplanRelay/FloorplanRelayPreload')
);
const HomePreload = React.lazy(() => import(/* webpackChunkName: "HomePreload" */ './pages/Home/HomePreload'));
const SettingsPreload = React.lazy(() => import(/* webpackChunkName: "SettingsPreload" */ './pages/Settings/SettingsPreload'));
const Callback = React.lazy(() => import(/* webpackChunkName: "Callback" */ './pages/Callback'));
const InsightsPreload = React.lazy(() => import(/* webpackChunkName: "InsightsPreload" */ './pages/Insights/InsightsPreload'));
const OfficePreload = React.lazy(() => import(/* webpackChunkName: "OfficePreload" */ './pages/Office/OfficePreload'));
const Onboarding = React.lazy(() => import(/* webpackChunkName: "Onboarding" */ './pages/Onboarding/Onboarding'));
const EmployeeExperiencePreload = React.lazy(
  () => import(/* webpackChunkName: "EmployeeExperiencePreload" */ './pages/EmployeeExperience/EmployeeExperiencePreload')
);
const ReservationRootPreload = React.lazy(
  () => import(/* webpackChunkName: "ReservationRootPreload" */ './pages/Reservation/ReservationRootPreload')
);
const QRCodePreload = React.lazy(() => import(/* webpackChunkName: "QRCodePreload" */ './pages/QRCode/QRCodePreload'));
const SurveyPreload = React.lazy(() => import(/* webpackChunkName: "SurveyPreload" */ './pages/Survey/SurveyPreload'));
const SurveySpeedbump = React.lazy(() => import(/* webpackChunkName: "SurveySpeedbump" */ './pages/SurveySpeedbump'));
const SurveyOfficeChooser = React.lazy(() => import(/* webpackChunkName: "SurveyOfficeChooser" */ './pages/SurveyOfficeChooser'));
const SurveyFromReservation = React.lazy(
  () => import(/* webpackChunkName: "SurveyFromReservation" */ './pages/SurveyFromReservation')
);

const Guest = React.lazy(() => import(/* webpackChunkName: "Guest" */ './pages/Guest'));
const Root = React.lazy(() => import(/* webpackChunkName: "Root" */ './pages/Root'));

export const ANON_ROOT = '/a';
export const ROOT = '/';
export const PLAYGROUND = '/playground';
export const LOGIN = '/login';
export const SETTINGS_INDEX = '/settings';
export const CALLBACK = '/callback';
export const INSIGHTS = '/insights';
export const ONBOARDING = '/onboarding';
export const GUEST = '/guest';
export const HOME_RESERVATIONS = '/reservations';
export const RESERVATION = '/reservation';

export const FLOORPLAN = (slug: string) => `/floorplans/${slug}`;
export const EDIT_FLOORPLAN = (slug: string) => `/floorplans/${slug}/edit`;
export const EDIT_FLOORPLAN_V2 = (slug: string) => `/floorplans/${slug}/editv2`;
export const OFFICE = (slug: string) => `/offices/${slug}`;
export const OFFICE_SETTINGS = (slug: string, subsettingSlug = '') =>
  `/offices/${slug}/settings${subsettingSlug ? '/' : ''}${subsettingSlug}`;
export const SETTINGS = (subsetting: string) => `${SETTINGS_INDEX}/${subsetting}`;

export const EMPLOYEE_EXPERIENCE = (slug: string) => `/ee/${slug}`;
export const EMPLOYEE_EXPERIENCE_RESERVATIONS = (slug: string) => `${EMPLOYEE_EXPERIENCE(slug)}/reservations`;
export const EMPLOYEE_EXPERIENCE_RESERVATIONS_NEW = (slug: string) => `${EMPLOYEE_EXPERIENCE_RESERVATIONS(slug)}/new`;
export const EMPLOYEE_EXPERIENCE_RESERVATION = (slug: string, reservationId: string) =>
  `${EMPLOYEE_EXPERIENCE(slug)}/reservation/${reservationId}`;
export const EMPLOYEE_EXPERIENCE_RESERVATION_EDIT = (slug: string, reservationId: string) =>
  `${EMPLOYEE_EXPERIENCE(slug)}/reservation/${reservationId}/edit`;

// TODO: all the survey routes should idealy be inside the survey page
// TODO: separate authed and unauthed survey flows
export const SURVEY_OFFICE_CHOOSER = '/surveys/office-chooser';
export const SURVEY_FROM_RESERVATION = (slug: string) => `/surveys/reservation/${slug}`;
export const SURVEY = (slug: string) => `/surveys/${slug}`;
export const SURVEY_SPEEDBUMP = (slug: string) => `${SURVEY(slug)}/speedbump`;
export const QR_CODE = (slug: string) => `${OFFICE(slug)}/qr`;

// page error boundaries https://stackoverflow.com/questions/59056211/error-boundaries-disables-routing-inside-of-a-switch

export const floorplan: AppRouteType = {
  exact: true,
  auth: true,
  path: FLOORPLAN(':floorplanId'),
  render: (props) => (
    <GenericErrorBoundary key={`floorplan-${props.match.params.floorplanId}`}>
      <FloorplanRelayPreload />
    </GenericErrorBoundary>
  ),
};

export const editFloorplan: AppRouteType = {
  exact: true,
  auth: true,
  path: EDIT_FLOORPLAN(':floorplanId'),
  render: (props) => (
    <GenericErrorBoundary key={props.match.params.floorplanId}>
      <EditFloorplan />
    </GenericErrorBoundary>
  ),
  ff: SPLITS.EDIT_FLOORPLAN,
};

export const editFloorplanV2: AppRouteType = {
  exact: true,
  auth: true,
  path: EDIT_FLOORPLAN_V2(':floorplanId'),
  render: (props) => (
    <GenericErrorBoundary key={props.match.params.floorplanId}>
      <EditFloorplanV2 />
    </GenericErrorBoundary>
  ),
  ff: SPLITS.EDIT_FLOORPLAN,
};

export const playground: AppRouteType = {
  exact: true,
  auth: true,
  path: PLAYGROUND,
  component: Playground,
  ff: SPLITS.GRAPHQL_PLAYGROUND,
};

export const settings: AppRouteType = {
  exact: true,
  auth: true,
  path: [SETTINGS_INDEX, SETTINGS(':subpage')],
  render: (props) => (
    <GenericErrorBoundary key={props.match.params.subpage}>
      <SettingsPreload />
    </GenericErrorBoundary>
  ),
  allowedUserTypes: [UserType.TypeAdmin],
};

export const office: AppRouteType = {
  exact: false,
  auth: true,
  path: [OFFICE(':officeId')],
  render: (props) => (
    <GenericErrorBoundary key={props.match.params.officeId}>
      <OfficePreload />
    </GenericErrorBoundary>
  ),
  allowedUserTypes: [UserType.TypeAdmin],
};

export const insights: AppRouteType = {
  exact: true,
  auth: true,
  path: INSIGHTS,
  render: (props) => (
    <GenericErrorBoundary key="insights">
      <InsightsPreload />
    </GenericErrorBoundary>
  ),
  allowedUserTypes: [UserType.TypeAdmin],
};

export const home: AppRouteType = {
  exact: true,
  auth: true,
  path: [HOME_RESERVATIONS],
  render: (props) => (
    <GenericErrorBoundary key="home">
      <HomePreload />
    </GenericErrorBoundary>
  ),
  ff: SPLITS.EEV2_HOME,
};

export const onboarding: AppRouteType = {
  exact: true,
  auth: true,
  path: ONBOARDING,
  render: (props) => (
    <GenericErrorBoundary key={`onboarding-${props.location.search}`}>
      <Onboarding />
    </GenericErrorBoundary>
  ),
  ff: SPLITS.LAUNCH_SEQUENCE,
  allowedUserTypes: [UserType.TypeAdmin],
};

export const employeeExperience: AppRouteType = {
  exact: true,
  auth: true,
  path: [
    EMPLOYEE_EXPERIENCE(':floorplanId'),
    EMPLOYEE_EXPERIENCE_RESERVATIONS(':floorplanId'),
    EMPLOYEE_EXPERIENCE_RESERVATIONS_NEW(':floorplanId'),
    EMPLOYEE_EXPERIENCE_RESERVATION(':floorplanId', ':reservationId'),
    EMPLOYEE_EXPERIENCE_RESERVATION_EDIT(':floorplanId', ':reservationId'),
  ],
  render: (props) => (
    <GenericErrorBoundary key={props.match.params.floorplanId}>
      <EmployeeExperiencePreload />
    </GenericErrorBoundary>
  ),
};

export const reservation: AppRouteType = {
  exact: false,
  auth: true,
  hideSideNav: true,
  path: RESERVATION,
  render: () => (
    <GenericErrorBoundary key="reservation">
      <ReservationRootPreload />
    </GenericErrorBoundary>
  ),
  ff: SPLITS.EEV2_RESERVATION,
};

export const surveySpeedbump: AppRouteType = {
  exact: true,
  auth: true,
  hideSideNav: true,
  path: SURVEY_SPEEDBUMP(':surveyId'),
  render: (props) => (
    <GuestPassErrorBoundary key={props.match.params.surveyId}>
      <SurveySpeedbump />
    </GuestPassErrorBoundary>
  ),
};

export const guest: AppRouteType = {
  exact: true,
  auth: false,
  hideSideNav: true,
  path: GUEST,
  render: (props) => (
    <GuestPassErrorBoundary key="guest">
      <Guest />
    </GuestPassErrorBoundary>
  ),
};

export const authedSurvey: AppRouteType = {
  exact: false,
  auth: true,
  hideSideNav: true,
  path: SURVEY(':surveyId'),
  ff: SPLITS.EEV2_PASS,
  render: (props) => (
    <GuestPassErrorBoundary key={props.match.params.surveyId}>
      <SurveyPreload />
    </GuestPassErrorBoundary>
  ),
};

export const surveyOfficeChooser: AppRouteType = {
  exact: true,
  auth: true,
  hideSideNav: true,
  path: SURVEY_OFFICE_CHOOSER,
  render: (props) => (
    <GenericErrorBoundary key="survey-office-chooser">
      <SurveyOfficeChooser />
    </GenericErrorBoundary>
  ),
};

export const surveyFromReservation: AppRouteType = {
  exact: true,
  auth: true,
  hideSideNav: true,
  path: SURVEY_FROM_RESERVATION(':reservationId'),
  ff: SPLITS.EEV2_PASS,
  render: (props) => (
    <GenericErrorBoundary key="survey-from-reservation">
      <SurveyFromReservation />
    </GenericErrorBoundary>
  ),
};

export const survey: AppRouteType = {
  exact: false,
  auth: false,
  hideSideNav: true,
  path: SURVEY(':surveyId'),
  render: (props) => (
    <GuestPassErrorBoundary key={props.match.params.surveyId}>
      <SurveyPreload />
    </GuestPassErrorBoundary>
  ),
};

export const qRCode: AppRouteType = {
  exact: true,
  auth: true,
  hideSideNav: true,
  path: QR_CODE(':officeId'),
  render: (props) => (
    <GenericErrorBoundary key={`qRCode-${props.match.params.officeId}`}>
      <QRCodePreload />
    </GenericErrorBoundary>
  ),
};

export const callback: AppRouteType = {
  exact: true,
  auth: false,
  path: CALLBACK,
  component: Callback,
};

export const root: AppRouteType = {
  auth: true,
  path: ROOT,
  render: (props) => (
    <GenericErrorBoundary key="home">
      <HomePreload />
    </GenericErrorBoundary>
  ),
  ff: SPLITS.EEV2_HOME,
  ffFallback: (props) => (
    <GenericErrorBoundary key="root">
      <Root />
    </GenericErrorBoundary>
  ),
};

export const routes = [
  floorplan,
  editFloorplanV2,
  editFloorplan,
  playground,
  settings,
  home,
  // FIXME: Not using exact on office breaks qr, so we need to order qr before for now
  // qr should be a subpage of office in the future
  qRCode,
  office,
  insights,
  onboarding,
  employeeExperience,
  surveyFromReservation,
  surveyOfficeChooser,
  authedSurvey,
  reservation,
  callback,
  root,
];

export const anonRoutes = [surveySpeedbump, survey, guest];
