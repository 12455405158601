/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type SideNav_user = {
    readonly " $fragmentRefs": FragmentRefs<"OrgDropdown_user">;
    readonly " $refType": "SideNav_user";
};
export type SideNav_user$data = SideNav_user;
export type SideNav_user$key = {
    readonly " $data"?: SideNav_user$data;
    readonly " $fragmentRefs": FragmentRefs<"SideNav_user">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SideNav_user",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrgDropdown_user"
        }
    ],
    "type": "User",
    "abstractKey": null
} as any;
(node as any).hash = '26bffb8498dc686f8a297871fdb78b6c';
export default node;
