import * as React from 'react';
import { Button, Flex } from '@chakra-ui/react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

import { ErrorReporting } from '../services/errorReporting';

import FillCenter from './FillCenter';
import ErrorMessage_DEPRECATED from './ErrorMessage_DEPRECATED';

import { getRefreshCount, incrementRefreshCount, removeRefreshCount } from '@app/utils/localStorageUtils';
import { RelayError, STATUS_CODE } from '@app/RelayEnvironment';

const ErrorFallback: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  return (
    <FillCenter>
      <Flex flexDirection="column" alignItems="center" justifyContent="center">
        <ErrorMessage_DEPRECATED>An unexpected error has occurred. We're on it!</ErrorMessage_DEPRECATED>
        <Button colorScheme="buttonPrimary" variant="outline" mt={4} onClick={resetErrorBoundary}>
          Try Again
        </Button>
      </Flex>
    </FillCenter>
  );
};

const GenericErrorBoundary: React.FC = (props) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={async (error) => {
        ErrorReporting.report(error, 'fatal');

        if (error.name.includes('ChunkLoadError')) {
          // Until we reenable service worker, adding this handling to deal with navigating to missing chunks
          const refreshCount = getRefreshCount();
          // The refresh count almost certainly is superfluous, but as a precaution it's here as a circuit breaker
          // for a chunk error that isn't fixed by a refresh
          if (refreshCount < 3) {
            incrementRefreshCount();
            // eslint-disable-next-line no-self-assign
            window.location.href = window.location.href;
          }
        } else {
          removeRefreshCount();
        }

        // this is most likely a user that isn't authorized
        // we immediately log them out
        if ([STATUS_CODE(401), STATUS_CODE(403)].some((code) => error.message.includes(code))) {
          (window as any).__NASHI_AUTH__?.logout({
            returnTo: window.location.origin,
          });
        }

        // failed to upsert user error, the user logging in doesn't have access
        if (error instanceof RelayError && error.extensions?.some((ext) => ext?.code === 403)) {
          (window as any).__NASHI_AUTH__?.logout({
            returnTo: window.location.origin,
          });
        }
      }}
    >
      {props.children}
    </ErrorBoundary>
  );
};

export default GenericErrorBoundary;
