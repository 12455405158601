import { Flex, Box } from '@chakra-ui/react';
import * as React from 'react';
import { Slide, toast as toastify, ToastContentProps, ToastOptions } from 'react-toastify';
import { FaTimes } from 'react-icons/fa';

import Success from '../icons/Success';
import { colors } from '../theme';
import Info_DEPRECATED from '../icons/Info_DEPRECATED';
import WarningToast from '../icons/WarningToast';
import ErrorIcon from '../icons/Error';

import { CLOSE_TOAST } from '@app/constants/dataIdConstants';

type ToastTypes = 'info' | 'warning' | 'success' | 'error' | 'default';

const TOAST_ICONS: Record<ToastTypes, React.ComponentType | null> = {
  info: Info_DEPRECATED,
  warning: WarningToast,
  success: Success,
  error: ErrorIcon,
  default: null,
};

type ToastProps = {
  type: ToastTypes;
} & Partial<ToastContentProps>;

const Toast: React.FC<ToastProps> = (props) => {
  const Icon = TOAST_ICONS[props.type];

  return (
    <Flex alignItems="flex-start">
      {!!Icon && (
        <Box width="20px" height="20px" mt="2px" color="transparent">
          {<Icon />}
        </Box>
      )}

      <Box color={colors.grayscale[50]} mx={4}>
        {props.children}
      </Box>
    </Flex>
  );
};

export const DEFAULT_TOAST_OPTIONS: ToastOptions = {
  position: 'top-center',
  autoClose: 6000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  transition: Slide,
  progress: undefined,
};

export const toast = (
  type: ToastTypes,
  content: React.ReactElement | string | ((props: { closeToast?: () => void }) => React.ReactNode),
  options?: ToastOptions
) => {
  return toastify(
    (props) => <Toast type={type}>{typeof content === 'function' ? content({ closeToast: props.closeToast }) : content}</Toast>,
    {
      ...DEFAULT_TOAST_OPTIONS,
      closeButton: () => (
        <Box mt="2px" width="1em" height="1em">
          <FaTimes color={colors.grayscale[50]} data-id={CLOSE_TOAST} />
        </Box>
      ),
      ...options,
    }
  );
};
