import * as React from 'react';
import { Box, Text, BoxProps, TypographyProps } from '@chakra-ui/react';

import AvatarIcon from '../icons/AvatarIcon';
import { presets, colors } from '../theme';
import { initializeName, shortenName } from '../utils/stringUtils';

import Zoomable from './Zoomable';

type TextAvatarProps = {
  text: string;
  size: number;
  noComputeZoomable?: boolean;
} & BoxProps;

const TextAvatar: React.FC<TextAvatarProps> = (props) => {
  const { size, text, noComputeZoomable, ...rest } = props;
  const defaultBoxProps = {
    borderRadius: '50%',
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'center',
    fontSize: 12,
    backgroundColor: presets.BACKGROUND_COLOR,
    borderColor: colors.BLACK,
  } as BoxProps;

  return props.text ? (
    <Box {...defaultBoxProps} height={`${props.size}px`} width={`${props.size}px`} {...rest}>
      <ZoomableText size={props.size} text={props.text} fontSize={props.fontSize} noComputeZoomable={!!noComputeZoomable} />
    </Box>
  ) : (
    <AvatarIcon size={props.size} />
  );
};

const ZoomableText: React.FC<{ size: number; text: string; fontSize?: TypographyProps['fontSize']; noComputeZoomable: boolean }> =
  (props) => {
    const initializedRef = React.useRef<HTMLDivElement | null>(null);
    const fullTextRef = React.useRef<HTMLDivElement | null>(null);
    const fontSize = props.fontSize || 'xs';

    const nodes = React.useMemo(() => {
      return [
        {
          element: (
            <Text fontSize={fontSize} lineHeight={fontSize} whiteSpace="nowrap">
              {shortenName(props.text)}
            </Text>
          ),
          ref: initializedRef,
        },
        {
          element: (
            <Text fontSize={fontSize} lineHeight={fontSize} whiteSpace="nowrap">
              {initializeName(props.text)}
            </Text>
          ),
          ref: fullTextRef,
        },
      ];
    }, [fontSize, props.text]);

    return <Zoomable nodes={nodes} height={props.size} padding={0} width={props.size} noCompute={props.noComputeZoomable} />;
  };

export default TextAvatar;
