/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UserType = "TYPE_ADMIN" | "TYPE_EMPLOYEE" | "TYPE_UNSPECIFIED" | "%future added value";
export type OrgDropdown_user = {
    readonly name: string;
    readonly photoUrl: string;
    readonly type: UserType;
    readonly " $refType": "OrgDropdown_user";
};
export type OrgDropdown_user$data = OrgDropdown_user;
export type OrgDropdown_user$key = {
    readonly " $data"?: OrgDropdown_user$data;
    readonly " $fragmentRefs": FragmentRefs<"OrgDropdown_user">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrgDropdown_user",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "photoUrl",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
        }
    ],
    "type": "User",
    "abstractKey": null
} as any;
(node as any).hash = '1386f7c2a96f7a7965e0432fde76b404';
export default node;
