import * as React from 'react';

export function useOnOutsideClick(ref: React.RefObject<HTMLElement | null>, cb?: () => void) {
  React.useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb?.();
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [cb, ref]);
}
