import * as React from 'react';

import { wrapIcon } from '../utils/reactUtils';

function MenuHovered(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path fill="none" d="M0,0h24v24H0V0z" />
      <path d="M21,16H8v2h13V16z M21,11H11v2h10V11z M8,6v2h13V6H8z M4.4,17l5-5l-5-5L3,8.4L6.6,12L3,15.6L4.4,17z" />
    </svg>
  );
}

export default wrapIcon(MenuHovered);
