import * as React from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

import { ErrorReporting } from '../services/errorReporting';

import styles from './RootErrorBoundary.module.scss';

const ErrorFallback: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  return (
    <div className={styles.container}>
      <p className={styles.message}>An unexpected error has occurred. We're on it!</p>
      <button className={styles.button} onClick={resetErrorBoundary}>
        Try Again
      </button>
    </div>
  );
};

const RootErrorBoundary: React.FC = (props) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={async (error) => {
        ErrorReporting.report(error, 'fatal');
      }}
    >
      {props.children}
    </ErrorBoundary>
  );
};

export default RootErrorBoundary;
