import * as React from 'react';
import { useTreatments as useSplitTreatments, SplitFactory } from '@splitsoftware/splitio-react';
import { readInlineData } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { createLocalStorageStateHook } from 'use-local-storage-state';

import { SPLIT_KEY, NODE_ENV } from '../config';
import { LOCAL_FEATURES, SPLITS } from '../constants/featureFlipperConstants';

import { treatmentsContext_useTreatment_user$key } from './__generated__/treatmentsContext_useTreatment_user.graphql';

import { FF_OVERRIDES } from '@app/constants/localStorageConstants';

const UNKNOWN_IDENTIFIER = 'unknown';
const DEFAULT_ON_VALUE = 'on';

export const useFFOverrides = createLocalStorageStateHook<{ [key: string]: 'on' | 'off' }>(FF_OVERRIDES, {});

// a context used to pass an email down to useTreatment and useTreatment_DEPRECATED
type CompatContextType = { email?: string };

const CompatContext = React.createContext<CompatContextType | undefined>(undefined);

const useCompat = () => {
  const context = React.useContext(CompatContext);
  if (!context) {
    throw new Error('useCompat must be used within a CompatProvider');
  }
  return context;
};

type TreatmentsProviderProps = {
  splitKey?: string;
  children: JSX.Element;

  email?: string;
};

export const TreatmentsProvider: React.FC<TreatmentsProviderProps> = (props) => {
  return (
    <SplitFactory
      config={{
        core: {
          authorizationKey: SPLIT_KEY,
          key: props.splitKey || UNKNOWN_IDENTIFIER,
        },
        features: NODE_ENV === 'development' ? LOCAL_FEATURES : undefined,
      }}
    >
      <CompatContext.Provider value={{ email: props.email }}>{props.children}</CompatContext.Provider>
    </SplitFactory>
  );
};

const useOverrideTreatment = (feature?: string) => {
  const [fFOverrides] = useFFOverrides();

  const overrideValue = feature ? fFOverrides[feature] : undefined;

  return overrideValue;
};

const useTreatmentForEmail = (email?: string, featureName?: string) => {
  const treatments = React.useMemo(() => {
    if (featureName) {
      return { [featureName]: DEFAULT_ON_VALUE };
    }

    return {};
  }, [featureName]);

  const features = React.useMemo(() => {
    return Object.keys(treatments);
  }, [treatments]);

  const treatmentResults = useSplitTreatments(features, {
    email: email || UNKNOWN_IDENTIFIER,
  });

  const ffOverrideTreatmentResults = useSplitTreatments([SPLITS.FF_OVERRIDES], {
    email: email || UNKNOWN_IDENTIFIER,
  });

  const isFFOverrideOn = ffOverrideTreatmentResults[SPLITS.FF_OVERRIDES].treatment === DEFAULT_ON_VALUE;
  const overrideValue = useOverrideTreatment(featureName);
  if (isFFOverrideOn && overrideValue) {
    return overrideValue === DEFAULT_ON_VALUE;
  }

  return features.every((f) => treatmentResults[f].treatment === treatments[f]);
};

// for use on components that aren't ready for relay yet
export const useTreatment_DEPRECATED = (featureName?: string) => {
  const { email } = useCompat();

  return useTreatmentForEmail(email, featureName);
};

// only used by FeatureFlipperToggle to get current values
export const useTreatments_DEPRECATED = (featureNames: string[]) => {
  const { email } = useCompat();

  const treatments = React.useMemo(() => {
    return featureNames.reduce((acc, curr) => {
      acc[curr] = DEFAULT_ON_VALUE;

      return acc;
    }, {} as { [key: string]: 'on' | 'off' });
  }, [featureNames]);

  const features = React.useMemo(() => {
    return Object.keys(treatments);
  }, [treatments]);

  const treatmentResults = useSplitTreatments(features, {
    email: email || UNKNOWN_IDENTIFIER,
  });

  return features.reduce((acc, curr) => {
    acc[curr] = treatmentResults[curr].treatment as 'on' | 'off';

    return acc;
  }, {} as { [key: string]: 'on' | 'off' });
};

export const useTreatment = (userRef: treatmentsContext_useTreatment_user$key | null, featureName?: string) => {
  const user = readInlineData(
    graphql`
      fragment treatmentsContext_useTreatment_user on User @inline {
        email
      }
    `,
    userRef
  );

  return useTreatmentForEmail(user?.email, featureName);
};
