import { APP_ENV } from '@app/config';

const prefix = (key: string) => `__NASHI__${APP_ENV}__${key}`;

export const ACCESS_TOKEN_KEY = prefix('access_token');
export const ID_TOKEN_KEY = prefix('id_token');
export const LOGIN_COUNT_KEY = prefix('login_count');
export const REFRESH_COUNT_KEY = prefix('refresh_count');
export const SIDE_NAV_KEY = prefix('side_nav');
export const LAST_FLOORPLAN_KEY = prefix('last_office');
export const SIDE_NAV_SECTIONS_COLLAPSED_KEY = prefix('nav_sections_collapsed');
export const APP_VERSION_KEY = prefix('app_version');
export const CURRENT_ACCOUNT = prefix('current_account');
export const CURRENT_ORG = prefix('current_org');
export const FLOORPLAN_PAGE_STATE_KEY = prefix('floorplan_page_state');
export const HIDDEN_OFFICE_IDS_KEY = prefix('hidden_office_ids');
export const FF_OVERRIDES = prefix('ff_overrides');
export const RESERVATION_SEAT_SELECTION_TIPS_OPEN = prefix('reservation_seat_selection_tips_open');
