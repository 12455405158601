import { Environment, FetchFunction, Network, RecordSource, Store, Variables } from 'relay-runtime';

import { NASHI_API_URL } from './config';
import { getAuthHeaders } from './utils/networkUtils';

type Extension = {
  code: number;
  globalErrorMessage: string;
  httpErrorCode: number;
  localErrorMessage: string;
};

export const STATUS_CODE = (code: number) => `Status Code: ${code}`;

export class RelayError extends Error {
  public extensions?: Extension[];
  public operationName?: string;
  public variables?: Variables;

  constructor(m: string, operationName?: string, variables?: Variables, extensions?: Extension[]) {
    super(m);

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, RelayError.prototype);

    this.operationName = operationName;
    this.variables = variables;
    this.extensions = extensions;
  }
}

const fetchQuery: FetchFunction = async (operation, variables) => {
  const response = await fetch(`${NASHI_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...getAuthHeaders(),
    },
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
  });

  if (!response.ok) {
    const text = await response.text();
    console.log(text);
    throw new RelayError(
      `Error fetching GraphQL query '${operation.name}' with variables '${JSON.stringify(variables)}': ${text}, ${STATUS_CODE(
        response.status
      )}`,
      operation.name,
      variables
    );
  }

  // Get the response as JSON
  const json = await response.json();

  // GraphQL returns exceptions (for example, a missing required variable) in the "errors"
  // property of the response. If any exceptions occurred when processing the request,
  // throw an error to indicate to the developer what went wrong.
  if (Array.isArray(json.errors)) {
    console.log(json.errors);
    throw new RelayError(
      `Error fetching GraphQL query '${operation.name}' with variables '${JSON.stringify(variables)}': ${JSON.stringify(
        json.errors
      )}`,
      operation.name,
      variables,
      json.errors?.map((e: any) => e.extensions)
    );
  }

  // Otherwise, return the full payload.
  return json;
};

const credentialedFetchQuery: FetchFunction = async (operation, variables) => {
  const response = await fetch(`${NASHI_API_URL}/graphql`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
  });

  if (!response.ok) {
    const text = await response.text();
    console.log(text);
    throw new RelayError(
      `Error fetching GraphQL query '${operation.name}' with variables '${JSON.stringify(variables)}': ${text}, ${STATUS_CODE(
        response.status
      )}`,
      operation.name,
      variables
    );
  }

  // Get the response as JSON
  const json = await response.json();

  // GraphQL returns exceptions (for example, a missing required variable) in the "errors"
  // property of the response. If any exceptions occurred when processing the request,
  // throw an error to indicate to the developer what went wrong.
  if (Array.isArray(json.errors)) {
    console.log(json.errors);
    throw new RelayError(
      `Error fetching GraphQL query '${operation.name}' with variables '${JSON.stringify(variables)}': ${JSON.stringify(
        json.errors
      )}`,
      operation.name,
      variables,
      json.errors?.map((e: any) => e.extensions)
    );
  }

  // Otherwise, return the full payload.
  return json;
};

export const environment = new Environment({
  network: Network.create(fetchQuery),
  store: new Store(new RecordSource(), {
    // This property tells Relay to not immediately clear its cache when the user
    // navigates around the app. Relay will hold onto the specified number of
    // query results, allowing the user to return to recently visited pages
    // and reusing cached data if its available/fresh.
    gcReleaseBufferSize: 10,
  }),
});

export const anonEnvironment = new Environment({
  network: Network.create(credentialedFetchQuery),
  store: new Store(new RecordSource(), {
    gcReleaseBufferSize: 10,
  }),
});
