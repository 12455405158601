export function camelPad(str: string) {
  return (
    str
      // Look for long acronyms and filter out the last letter
      .replace(/([A-Z]+)([A-Z][a-z])/g, ' $1_$2')
      // Look for lower-case letters followed by upper-case letters
      .replace(/([a-z\d])([A-Z])/g, '$1_$2')
      // Look for lower-case letters followed by numbers
      .replace(/([a-zA-Z])(\d)/g, '$1_$2')
      .replace(/^./, function (str: string) {
        return str.toUpperCase();
      })
      // Remove any white space left around the word
      .trim()
  );
}

// initialize a word
// if its only 1 word then ignore
export const initialize = (s: string) => {
  if (s.split(' ').length <= 1) {
    return s;
  }

  return s
    .toUpperCase()
    .split(' ')
    .map((s) => s[0])
    .join(' ');
};

export function titleize(sentence: string) {
  if (!sentence.split) return sentence;
  var _titleizeWord = function (string: string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },
    result: any[] = [];
  camelPad(sentence)
    .split('_')
    .forEach(function (w) {
      result.push(_titleizeWord(w));
    });
  return result.join(' ');
}

// https://stackoverflow.com/questions/610406/javascript-equivalent-to-printf-string-format/4673436#4673436
export function formatString(format: string, ...args: any) {
  return format.replace(/{(\d+)}/g, function (match, number) {
    return typeof args[number] != 'undefined' ? args[number] : match;
  });
}

// format seat counts for StatTypes
export const formattedAvailableSeats = (count: number = 0) => {
  if (isNaN(count)) return '0';
  if (count < 0) {
    return `0 (+${Math.abs(count)})`;
  }
  return `${count}`;
};

// initialize last name, use first name
export const initializeName = (s: string) => {
  const name = s
    .toUpperCase()
    .split(' ')
    .map((s) => s[0])
    .join('');
  return name.length === 1 ? name.charAt(0) : `${name.charAt(0)}${name.charAt(name.length - 1)}`;
};

export const shortenName = (s: string) => {
  return s
    .split(' ')
    .map((s, i) => (i === 0 ? s : `${s[0]}.`))
    .join(' ');
};

// https://github.com/visgl/deck.gl/blob/6.3-release/modules/layers/src/text-layer/font-atlas.js#L15
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/fromCharCode
function getCharacterSet() {
  const charSet = [];
  for (let i = 32; i < 128; i++) {
    charSet.push(String.fromCharCode(i));
  }
  return charSet;
}

export const CHAR_SET = getCharacterSet();
