import * as React from 'react';

// NOTE: from https://medium.com/the-non-traditional-developer/how-to-use-an-intersectionobserver-in-a-react-hook-9fb061ac6cb5
export const useIntersect = ({
  root = null,
  rootMargin,
  threshold = 0,
}: {
  root?: HTMLDivElement | null;
  rootMargin?: string;
  threshold: number | number[];
}): [(node: HTMLDivElement | null) => void, IntersectionObserverEntry | undefined] => {
  const [entry, updateEntry] = React.useState<IntersectionObserverEntry>();
  const [node, setNode] = React.useState<HTMLDivElement | null>(null);

  const observer = React.useRef(
    new window.IntersectionObserver(([entry]) => updateEntry(entry), {
      root,
      rootMargin,
      threshold,
    })
  );

  React.useEffect(() => {
    const { current: currentObserver } = observer;
    currentObserver.disconnect();

    if (node) currentObserver.observe(node);

    return () => currentObserver.disconnect();
  }, [node]);

  return [setNode, entry];
};
