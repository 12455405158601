import * as React from 'react';
import { Link } from 'react-router-dom';

type MaybeLinkProps = { to?: string; style?: React.CSSProperties };

const MaybeLink: React.FC<MaybeLinkProps> = (props) => {
  if (props.to) {
    return (
      <Link to={props.to} style={props.style}>
        {props.children}
      </Link>
    );
  }

  return <>{props.children}</>;
};

export default MaybeLink;
