import * as React from 'react';
import { Flex, Box, FlexProps } from '@chakra-ui/react';

import Sentinel from './Sentinel';

// render all permutations in invisible Box and then use refs to measure the height and width
// and then choose the node that fits the best
const Zoomable: React.FC<
  {
    height: number;
    width: number;
    nodes: { ref: React.MutableRefObject<HTMLDivElement | null>; element: JSX.Element }[];
    padding?: number;
    noCompute?: boolean;
  } & FlexProps
> = (props) => {
  const { height, width, nodes, padding = 10, noCompute, ...rest } = props;
  const [level, setLevel] = React.useState(nodes.length + 1);
  // We want to recalculate if visibility changes
  const [isVisible, setIsVisible] = React.useState(false);

  React.useLayoutEffect(() => {
    if (noCompute) {
      return;
    }
    for (let i = 0; i < nodes.length; i += 1) {
      const rect = nodes[i].ref.current?.getBoundingClientRect();
      // NOTE: add a bit of padding so the text and icons are not snug against the side of the rendered areas
      if (rect && rect.width + padding < width && rect.height + padding < height) {
        setLevel(i);
        return;
      }
    }

    setLevel(nodes.length + 1);
  }, [height, width, nodes, padding, isVisible, noCompute]);

  return (
    <Flex flexDirection="column" alignItems="center" justifyContent="center" height="100%" width="100%" {...rest}>
      <Box className="zoomableContent" textAlign="center">
        {nodes[level]?.element}
      </Box>

      {/* for rendering the different permutations of nodes */}
      <Box position="relative" visibility="hidden" pointerEvents="none">
        {nodes.map((node, index) => (
          <Box position="absolute" top={0} left={0} height="max-content" width="max-content" ref={nodes[index]?.ref} key={index}>
            {node.element}
          </Box>
        ))}
      </Box>
      <Sentinel
        threshold={[1]}
        onThresholdMet={(threshold) => {
          setIsVisible(threshold === 1);
        }}
      />
    </Flex>
  );
};

export default Zoomable;
