import { useTheme as useChakraTheme, theme as chakraTheme, extendTheme } from '@chakra-ui/react';

import { AreaType } from './graphql/generated';

// customizing themes: https://chakra-ui.com/getting-started

// TODO: need full color swatches from design
export const colors = {
  grayscale: {
    50: '#FFFFFF',
    100: '#F7F7F7',
    200: '',
    300: '',
    400: '#D9D9D9',
    500: '#8A8A8A',
    600: '#6E6E6E',
    700: '',
    800: '#222222',
    900: '#000000',
  },

  indigo: {
    50: '#F5F9FF',
    100: '',
    200: '',
    300: '',
    400: '',
    500: '#3563B4',
    600: '#3563B4',
    700: '#204B93',
    800: '#12336B',
    900: '#081E40',
  },

  ruby: {
    50: '#FFF5F7',
    100: '',
    200: '',
    300: '',
    400: '',
    500: '',
    600: '#D20023',
    700: '',
    800: '',
    900: '',
  },

  blue: {
    50: '#E0F1FF',
    100: '',
    200: '',
    300: '',
    400: '',
    500: '',
    600: '#2196F3',
    700: '',
    800: '#216FAE',
    900: '',
  },

  purple: {
    50: '#E8DBFF',
    100: '',
    200: '',
    300: '',
    400: '',
    500: '',
    600: '#673AB7',
    700: '',
    800: '',
    900: '',
  },

  slate: {
    50: '#E7F7FF',
    100: '',
    200: '',
    300: '',
    400: '',
    500: '',
    600: '#607D8B',
    700: '#405F6E',
    800: '',
    900: '',
  },

  teal: {
    50: '#DBFFFB',
    100: '',
    200: '',
    300: '',
    400: '',
    500: '',
    600: '#009688',
    700: '#007469',
    800: '',
    900: '',
  },

  royalBlue: {
    50: '',
    100: '',
    200: '',
    300: '',
    400: '',
    500: '',
    600: '#304FFE',
    700: '',
    800: '',
    900: '',
  },

  goldenrod: {
    50: '',
    100: '',
    200: '',
    300: '',
    400: '',
    500: '',
    600: '#FFC107',
    700: '',
    800: '',
    900: '',
  },

  // Legacy swatch, DEPRECATED
  WHITE: '#fdfdfd',
  SNOW: '#f5f5f5',
  SLATE: '#595f6a',
  BLACK: '#000206',
  MOON_WHITE: '#f7f9fc',
  MOON_SHADOW: '#d4dae5',
  SUCCESS: '#47b381',
  FAILURE: '#b34747',
  WARNING: '#eed202',
};

Object.assign(colors, {
  success: {
    500: colors.indigo[600],
    600: colors.indigo[600],
  },
  successGreen: {
    500: '#176039',
    600: '#176039',
  },
  failure: {
    50: colors.ruby[50],
    500: colors.ruby[600],
    600: colors.ruby[600],
  },
  buttonPrimary: {
    50: colors.indigo[50],
    500: colors.indigo[500],
    600: colors.indigo[600],
  },
  buttonDark: {
    50: colors.grayscale[400],
    500: colors.grayscale[400],
    600: colors.grayscale[500],
  },
});

type AreaColorOptions = {
  // top border for area polygon
  topBar: string;
  // clickable action in FloorplanSummary
  action: string;
  // fill for area polygon
  background: string;
};

export const areaColors: Record<AreaType, AreaColorOptions> = {
  TYPE_UNSPECIFIED: {
    topBar: colors.grayscale[900],
    action: colors.grayscale[900],
    background: colors.grayscale[900],
  },
  TYPE_DEADSPACE: {
    topBar: colors.grayscale[800],
    action: colors.grayscale[800],
    background: colors.grayscale[800],
  },
  TYPE_UTILITY: {
    topBar: colors.grayscale[600],
    action: '#6d7278',
    background: colors.grayscale[100],
  },
  TYPE_MEETING_ROOM: {
    topBar: colors.teal[600],
    action: colors.teal[700],
    background: colors.teal[50],
  },
  TYPE_COLLABORATION_AREA: {
    topBar: colors.purple[600],
    action: colors.purple[600],
    background: colors.purple[50],
  },
  TYPE_PRIVATE_OFFICE: {
    topBar: colors.slate[600],
    action: colors.slate[700],
    background: colors.slate[50],
  },
  TYPE_WORKSPACE: {
    topBar: colors.blue[600],
    action: colors.blue[800],
    background: colors.blue[50],
  },
};

export const disabledAreaColorOptions: AreaColorOptions = {
  topBar: colors.grayscale[400],
  action: colors.grayscale[600],
  background: colors.grayscale[100],
};

export const presets = {
  BORDER_COLOR: colors.grayscale[400],
  DISABLED: colors.grayscale[400],
  HOVER_BACKGROUND: '#f2f2f2',
  HEADER_HEIGHT: '48px',
  ICON_SIZE: 20,
  BOLD_FONT: 600,
  SEMI_BOLD_FONT: 500,
  BACKGROUND_COLOR: '#ffffff',
  ACTIVE: colors.indigo[600],
  TABLE_PAGINATION_TEXT: '#797979',
  WARNING: '#fea409',
  SOCIAL_DISTANCE_CONFLICT: colors.ruby[600],
  SOCIAL_DISTANCE_NO_CONFLICT: '#2d925b',
  SOCIAL_DISTANCE_NO_ASSIGNMENTS: colors.grayscale[500],
  RESERVABLE_SEAT: colors.grayscale[400],
  HOVERED_RESERVABLE_SEAT: '#223E88',
  BRAND_PRIMARY: '#FF7663',
  BRAND_SECONDARY: colors.indigo[600],
  CHART_PRIMARY: colors.indigo[500],
  CHART_SECONDARY: colors.blue[600],
  CHART_TERTIARY: colors.grayscale[500],
  CHART_COLOR_SCALE: [colors.indigo[500], colors.blue[600], colors.grayscale[500]],
  WARNING_BACKGROUND: '#fffdf1',
  ERROR_BACKGROUND: '#fff5f7',
  PSUEDO_TEAM_COLOR: colors.royalBlue[600],
  SUCCESS_FONT_COLOR: '#006336',
  SUCCESS_BACKGROUND: '#EEFEF7',
  FAILURE_BACKGROUND: '#FEF4F7',
  FAILURE_FONT_COLOR: '#E91531',
};

function baseStyleControl() {
  return {
    _checked: {
      bg: colors.indigo[600],
      borderColor: colors.indigo[600],
      color: colors.grayscale[50],

      _hover: {
        bg: colors.indigo[800],
        borderColor: colors.indigo[800],
      },

      _disabled: {
        borderColor: colors.grayscale[100],
        bg: colors.grayscale[100],
        color: colors.grayscale[50],
      },
    },
  };
}

function outlineInput() {
  return {
    field: {
      border: '1px solid',
      borderColor: colors.grayscale[400],
      bg: 'inherit',
      _hover: {
        borderColor: colors.grayscale[400],
      },
      _focus: {
        zIndex: 1,
        borderColor: colors.indigo[600],
        boxShadow: `0 0 0 1px ${colors.indigo[600]}`,
      },
    },
  };
}

function baseStyleRadio() {
  return {
    _checked: {
      bg: colors.grayscale[50],
      borderColor: colors.indigo[600],
      color: colors.indigo[600],

      _before: {
        content: `""`,
        display: 'inline-block',
        pos: 'relative',
        w: '10px',
        h: '10px',
        borderRadius: '50%',
        bg: 'currentColor',
      },

      _hover: {
        bg: colors.grayscale[50],
        borderColor: colors.indigo[600],
        color: colors.indigo[600],

        _before: {
          content: `""`,
          display: 'inline-block',
          pos: 'relative',
          w: '10px',
          h: '10px',
          borderRadius: '50%',
          bg: 'currentColor',
        },
      },

      _disabled: {
        borderColor: colors.grayscale[100],
        bg: colors.grayscale[100],
        color: colors.grayscale[50],

        _before: {
          content: `""`,
          display: 'inline-block',
          pos: 'relative',
          w: '10px',
          h: '10px',
          borderRadius: '50%',
          bg: 'currentColor',
        },
      },
    },
  };
}

export const theme = extendTheme({
  colors,

  fonts: {
    heading: 'Avenir Next',
    body: 'Avenir Next',
    mono: 'Avenir Next',
  },

  components: {
    Checkbox: {
      ...chakraTheme.components.Checkbox,
      baseStyle: () => {
        return {
          control: {
            ...baseStyleControl(),
          },
        };
      },
    },
    Radio: {
      ...chakraTheme.components.Radio,
      baseStyle: () => {
        return {
          control: {
            ...baseStyleRadio(),
          },
        };
      },
    },
    Input: {
      variants: {
        outline: outlineInput,
      },
    },
    Textarea: {
      variants: {
        outline: () => outlineInput().field,
      },
    },
  },
});

export const useTheme = () => {
  return useChakraTheme() as typeof theme;
};

export const TEAM_COLORS = [
  '#FF5722',
  '#E91E63',
  '#9C27B0',
  '#651FFF',
  '#304FFE',
  '#00BCD4',
  '#00C853',
  '#FFEB3B',
  '#FFC107',
  '#FF9800',
  '#BF360C',
  '#880E4F',
  '#4A148C',
  '#AA00FF',
  '#283593',
  '#00E5FF',
  '#69F0AE',
  '#AEEA00',
  '#827717',
  '#795548',
];
