let _isTouchDevice: boolean | undefined = undefined;

export const isTouchDevice = () => {
  if (_isTouchDevice !== undefined) {
    return _isTouchDevice;
  }
  try {
    document.createEvent('TouchEvent');
    _isTouchDevice = true;
  } catch (e) {
    _isTouchDevice = false;
  }
  return _isTouchDevice;
};

export function isMobileDevice() {
  try {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  } catch (e) {
    return false;
  }
}

export const getResponsiveValues = (vals: [any, any] | [any, any, any]) => {
  if (vals.length === 2) {
    const [mob, web] = vals;
    return [mob, mob, web];
  } else {
    const [mob, land, web] = vals;
    return [mob, land, web];
  }
};

// This exists separately because there are certain styles you want to always show a certain way
// when we know it's a mobile device (e.g. display none on certain buttons)
export const getTouchAwareResponsiveValues = (vals: [any, any] | [any, any, any]) => {
  if (vals.length === 2) {
    const [mob, web] = vals;
    return isTouchDevice() && isMobileDevice() ? [mob] : [mob, mob, web];
  } else {
    const [mob, land, web] = vals;
    return isTouchDevice() && isMobileDevice() ? [mob] : [mob, land, web];
  }
};
