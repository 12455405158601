import * as React from 'react';
import { Box, BoxProps, Menu, Flex, Text, Portal, Image, MenuItem, MenuList, MenuDivider } from '@chakra-ui/react';
import { usePopper } from 'react-popper';
import { FaCheck, FaChevronDown } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useFragment } from 'react-relay/hooks';
import graphql from 'babel-plugin-relay/macro';
import { ErrorBoundary } from 'react-error-boundary';

import { useOnOutsideClick } from '../hooks/useOnOutsideClick';
import * as routes from '../routes';
import { colors, presets, theme } from '../theme';
import { useAuth } from '../contexts/authContext';
import { mergeRefs } from '../utils/reactUtils';
import { useApp } from '../App';
import { useTreatment_DEPRECATED } from '../contexts/treatmentsContext';
import { SPLITS } from '../constants/featureFlipperConstants';
import { UserType } from '../graphql/generated';
import { ErrorReporting } from '../services/errorReporting';

import OrgChooserList from './OrgChooserList';
import { OrgDropdown_organization$key } from './__generated__/OrgDropdown_organization.graphql';
import OrgImageFallback from './OrgImageFallback';
import { OrgDropdown_user$key } from './__generated__/OrgDropdown_user.graphql';
import Avatar from './Avatar';
import OverflowText from './OverflowText';
import FeatureFlipperToggle from './FeatureFlipperToggle';

type OrgDropdownProps = {
  organization: OrgDropdown_organization$key;
  user: OrgDropdown_user$key;
};

// TODO: need to update styles to match
// https://share.goabstract.com/3972c752-f1a7-42ae-8bcb-b6c99fa8880d?collectionLayerId=2cbf1c30-88e3-462a-b83b-c44ed455e59a&mode=design
const OrgDropdown: React.FC<OrgDropdownProps> = (props) => {
  const auth = useAuth();
  const app = useApp();

  const user = useFragment(
    graphql`
      fragment OrgDropdown_user on User {
        name
        photoUrl
        type
      }
    `,
    props.user
  );

  const isAdmin = app.currentAccount === UserType.TypeAdmin;
  const isUserTypeAdmin = user.type === UserType.TypeAdmin;

  const isOrgChooserOn = useTreatment_DEPRECATED(SPLITS.ORGANIZATION_CHOOSER);
  const isLaunchSequenceOn = useTreatment_DEPRECATED(SPLITS.LAUNCH_SEQUENCE);
  const isFFOverridesOn = useTreatment_DEPRECATED(SPLITS.FF_OVERRIDES);
  const isEEV2ReservationOn = useTreatment_DEPRECATED(SPLITS.EEV2_RESERVATION);

  const boxRef = React.useRef<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null);
  const [referenceElement, setReferenceElement] = React.useState<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const organization = useFragment(
    graphql`
      fragment OrgDropdown_organization on Organization {
        logo
        name
      }
    `,
    props.organization
  );

  const popper = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
  });

  useOnOutsideClick(boxRef, () => setIsOpen(false));

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      setIsOpen(!isOpen);
    }
  };

  React.useEffect(() => {
    popper.update?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, popper.update]);

  const activeStyles: BoxProps = {
    _hover: {
      backgroundColor: presets.HOVER_BACKGROUND,
      borderRadius: '2px',
    },
    cursor: 'pointer',
  };

  return (
    <>
      <Flex
        flexDirection="row"
        alignItems="center"
        ref={setReferenceElement}
        onClick={() => setIsOpen(!isOpen)}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        padding={1}
        {...activeStyles}
      >
        <Image
          boxSize={6}
          borderRadius={5}
          objectFit="cover"
          src={organization.logo}
          borderColor={presets.BORDER_COLOR}
          borderWidth={1}
          borderStyle="solid"
          loading="lazy"
          mr={2}
          fallback={<OrgImageFallback name={organization.name} size={6} textSize="sm" />}
        />
        <Text fontSize="md" fontWeight="semibold" color={colors.grayscale[800]}>
          {organization.name}
        </Text>

        <Box ml={2}>
          <FaChevronDown />
        </Box>
      </Flex>

      <Portal>
        <Box
          {...popper.attributes.popper}
          ref={mergeRefs([boxRef, setPopperElement])}
          style={popper.styles.popper}
          onClick={(e) => e.stopPropagation()}
          zIndex={10}
        >
          <Menu isOpen={isOpen} isLazy={true}>
            <MenuList onMouseLeave={() => setIsOpen(false)} maxHeight="600px" overflowY="auto">
              {isUserTypeAdmin && !isEEV2ReservationOn && (
                <>
                  <Flex flexDirection="row" alignItems="center" py={1} px={3}>
                    <Avatar src={user.photoUrl} fallbackText={user.name} size={24} fontSize="10px" />
                    <OverflowText fontSize="md" fontWeight="medium" ml={2} color={theme.colors.grayscale[800]} lineCount={1}>
                      {user.name}
                    </OverflowText>
                  </Flex>
                  <MenuDivider />
                  <MenuItem
                    display="flex"
                    justifyContent="space-between"
                    onClick={() => app.switchAccounts?.(UserType.TypeAdmin)}
                  >
                    <Text>Admin Account</Text>

                    {app.currentAccount === UserType.TypeAdmin && <FaCheck />}
                  </MenuItem>

                  <MenuItem
                    display="flex"
                    justifyContent="space-between"
                    onClick={() => app.switchAccounts?.(UserType.TypeEmployee)}
                  >
                    <Text>Employee Account</Text>

                    {app.currentAccount === UserType.TypeEmployee && <FaCheck />}
                  </MenuItem>

                  <MenuDivider />
                </>
              )}

              {isFFOverridesOn && (
                <>
                  <MenuItem display="flex" justifyContent="space-between">
                    <FeatureFlipperToggle />
                  </MenuItem>

                  <MenuDivider />
                </>
              )}

              {isOrgChooserOn && isAdmin && (
                <ErrorBoundary
                  fallbackRender={() => null}
                  onError={(error) => {
                    ErrorReporting.report(error, 'fatal');
                  }}
                >
                  <React.Suspense fallback={null}>
                    <OrgChooserList
                      onSelectOrganization={(id) => {
                        setIsOpen(false);
                        app.selectOrganization(id);
                      }}
                    />
                  </React.Suspense>
                </ErrorBoundary>
              )}

              {isAdmin && (
                <MenuItem as={Link} to={routes.SETTINGS_INDEX}>
                  <Text>Organization Settings</Text>
                </MenuItem>
              )}

              {isLaunchSequenceOn && isAdmin && (
                <MenuItem as={Link} to={`${routes.ONBOARDING}?flow=create-org`}>
                  <Text>Create Organization</Text>
                </MenuItem>
              )}

              <MenuItem onClick={auth.logout}>
                <Text>Logout of Nashi</Text>
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </Portal>
    </>
  );
};

export default OrgDropdown;
