import React from 'react';
import { Image } from '@chakra-ui/react';

import { colors, presets } from '../theme';

import TextAvatar from './TextAvatar';

type AvatarProps = {
  src?: string | null;
  fallbackText?: string;
  size: number;
  fontSize?: string;
};

const Avatar: React.FC<AvatarProps> = (props) => {
  const { src, fallbackText, size, fontSize } = props;

  let renderedFontSize = fontSize;
  if (!renderedFontSize) {
    renderedFontSize = size > 48 ? '5xl' : 'xl';
  }

  return (
    <Image
      src={src || ''}
      boxSize={`${size}px`}
      objectFit="cover"
      rounded="full"
      borderColor={presets.BORDER_COLOR}
      borderWidth={1}
      borderStyle="solid"
      fallback={
        <TextAvatar
          size={size}
          fontSize={renderedFontSize}
          text={fallbackText || ''}
          color={colors.grayscale[50]}
          background={colors.grayscale[600]}
          borderColor={presets.BORDER_COLOR}
        />
      }
    />
  );
};

export default Avatar;
