// split.io split names
export const SPLITS = {
  GRAPHQL_PLAYGROUND: 'graphql_playground',
  FPS_TRACKER: 'fps_tracker',
  EDIT_FLOORPLAN: 'edit_office',
  ORGANIZATION_CHOOSER: 'organization_chooser',
  FLOORPLAN_SQ_FT: 'office_sq_ft',
  EDIT_SIT_POSITION: 'edit_sit_position',
  DEBUG_MODE: 'debug_mode',
  LAUNCH_SEQUENCE: 'launch_sequence',
  // https://nashi.atlassian.net/browse/ENG-1336
  HIDE_SURVEY_RESPONSES: 'hide_survey_responses',
  // ff for allowing office hiding for demos
  HIDE_OFFICE: 'hide_office',
  SHOW_IMAGE_BITMAP_LAYER: 'show_image_bitmap_layer',
  AREA_AS_LAYERS_AND_WALLS: 'area_as_layers_and_walls',
  EEV2_HOME: 'eev2_home',
  EEV2_HOME_SCHEDULE: 'eev2_home_schedule',
  FF_OVERRIDES: 'ff_overrides',
  EEV2_PASS: 'eev2_pass',
  EEV2_RESERVATION: 'eev2_reservation',
  OFFICE_SCHEDULE: 'office_schedule',
  SEAT_LABEL_LAYER: 'seat_label_layer',
};

// features to override in split.io localhost mode
export const LOCAL_FEATURES = {
  ff_overrides: 'on',
};
