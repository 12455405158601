import * as React from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

import { ErrorReporting } from '../services/errorReporting';

import FillCenter from './FillCenter';
import ErrorMessage_DEPRECATED from './ErrorMessage_DEPRECATED';

const ErrorFallback: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  return (
    <FillCenter>
      <ErrorMessage_DEPRECATED>
        Oops! Something went wrong. Please reach out to your facilities team for assistance.
      </ErrorMessage_DEPRECATED>
    </FillCenter>
  );
};

const GuestPassErrorBoundary: React.FC = (props) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(error) => {
        ErrorReporting.report(error, 'fatal');
      }}
    >
      {props.children}
    </ErrorBoundary>
  );
};

export default GuestPassErrorBoundary;
