import * as React from 'react';
import useSWR from 'swr';

import FillCenter from '../components/FillCenter';
import Loading from '../components/Loading';
import { NODE_ENV, NASHI_AUTH_CONFIG_URL, AUTH_CLIENT_ID, AUTH_DOMAIN, AUTH_AUDIENCE } from '../config';
import { fetcher } from '../utils/networkUtils';

// for test env
const DEV_AUTH_CONFIG = {
  clientId: AUTH_CLIENT_ID,
  domain: AUTH_DOMAIN,
  audience: AUTH_AUDIENCE,
  redirectUrl: `${window.location.origin}/callback`,
};

type AuthConfigResponse = {
  auth0ClientId: string;
  auth0Domain: string;
  auth0Audience: string;
};

type HRDContextType = {
  isLoading: boolean;
  error?: Error;

  authConfig?: {
    clientId: string;
    domain: string;
    audience: string;
    redirectUrl: string;
  };
};

const HRDContext = React.createContext<HRDContextType | undefined>(undefined);

export const useHRD = () => {
  const context = React.useContext(HRDContext);
  if (!context) {
    throw new Error('useHRD must be used within a HRDProvider');
  }
  return context;
};

export const HRDProvider: React.FC<{
  // use the dev configs
  __INTERNAL_USE_DEV__?: boolean;
}> = ({ children, __INTERNAL_USE_DEV__ }) => {
  const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';

  // if in development always return the DEV_AUTH_CONFIG
  const { data, error } = useSWR<AuthConfigResponse>(
    NODE_ENV === 'production' && !isLocalhost && !__INTERNAL_USE_DEV__
      ? `${NASHI_AUTH_CONFIG_URL}?domain=${encodeURIComponent(`https://${window.location.hostname}`)}`
      : null,
    fetcher
  );

  const authConfig = React.useMemo(() => {
    if (NODE_ENV === 'development' || isLocalhost || __INTERNAL_USE_DEV__) {
      return { ...DEV_AUTH_CONFIG };
    }

    if (!data) {
      return;
    }

    return {
      clientId: data.auth0ClientId,
      // NOTE: make sure to strip any leading https://
      // and the trailing /
      domain: data.auth0Domain.replace(/(^\w+:|^)\/\//, '').replace('/', ''),
      audience: data.auth0Audience,
      redirectUrl: `https://${window.location.hostname}/callback`,
    };
  }, [__INTERNAL_USE_DEV__, data, isLocalhost]);

  const isLoading = !data && !error && NODE_ENV !== 'development';

  React.useEffect(
    function throwError() {
      if (error) {
        throw error;
      }
    },
    [error]
  );

  const renderChildren = React.useCallback(() => {
    if (authConfig) {
      return children;
    }

    return (
      <FillCenter>
        <Loading />
      </FillCenter>
    );
  }, [authConfig, children]);

  return (
    <HRDContext.Provider
      value={{
        isLoading,
        error,
        authConfig,
      }}
    >
      {renderChildren()}
    </HRDContext.Provider>
  );
};
