import * as React from 'react';
import { Flex, Box } from '@chakra-ui/react';

import { getTouchAwareResponsiveValues } from '../utils/mobileUtils';

type LayoutProps = {
  sideNav?: React.ReactNode;
};

export const BOTTOM_BAR_ID = 'BOTTOM_BAR_ID';

const Layout: React.FC<LayoutProps> = (props) => {
  return (
    // Hack for mobile safari because it considers area under control bar in viewport
    <Flex position="relative" flexDirection="column" height="100vh" width="100vw" style={{ maxHeight: '-webkit-fill-available' }}>
      <Flex position="relative" flexDirection="row" flexGrow={1} flexShrink={1} flexBasis="auto" overflow="hidden">
        {/* styles are for getting gpu acceleration and optimizing layout to not calculate z when doing layout changes */}
        <Box
          zIndex={1}
          display={getTouchAwareResponsiveValues(['none', 'block'])}
          transform="translate3d(0, 0, 0)"
          style={{ backfaceVisibility: 'hidden' }}
          position="sticky"
          top="0px"
        >
          {props.sideNav}
        </Box>

        <Box zIndex={0} flexGrow={1} transform="translate3d(0, 0, 0)" overflow="hidden" style={{ backfaceVisibility: 'hidden' }}>
          {props.children}
        </Box>
      </Flex>

      {/* This element exists in the layout for pages to portal to */}
      <Box id={BOTTOM_BAR_ID} />
    </Flex>
  );
};

export default Layout;
