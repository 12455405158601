// Rewritten from https://github.com/auth0-samples/auth0-react-samples/blob/master/01-Login/src/components/PrivateRoute.js

import * as React from 'react';
import { Route, RouteProps, useLocation } from 'react-router-dom';

import { useAuth } from '../contexts/authContext';

// wrapper around route to make sure that the component is authenticated before rendering
const PrivateRoute: React.FC<RouteProps> = (props) => {
  const location = useLocation();
  const { isAuthenticated, login } = useAuth();

  React.useEffect(() => {
    if (isAuthenticated) {
      return;
    }

    login({
      appState: {
        targetUrl: location.pathname + location.search,
      },
    });
  }, [isAuthenticated, location.pathname, location.search, login]);

  return <Route {...props} />;
};

export default PrivateRoute;
