/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type treatmentsContext_useTreatment_user = {
    readonly email: string;
    readonly " $refType": "treatmentsContext_useTreatment_user";
};
export type treatmentsContext_useTreatment_user$data = treatmentsContext_useTreatment_user;
export type treatmentsContext_useTreatment_user$key = {
    readonly " $data"?: treatmentsContext_useTreatment_user$data;
    readonly " $fragmentRefs": FragmentRefs<"treatmentsContext_useTreatment_user">;
};



const node: ReaderInlineDataFragment = {
    "kind": "InlineDataFragment",
    "name": "treatmentsContext_useTreatment_user"
} as any;
(node as any).hash = 'ff29bdaac314fbfa6c852a75aa930168';
export default node;
