import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import SentryFullStory from '@sentry/fullstory';
import * as FullStory from '@fullstory/browser';
import { matchPath } from 'react-router-dom';

import { RelayError } from '@app/RelayEnvironment';
import { APP_ENV, NODE_ENV, SENTRY_DSN, VERSION } from '@app/config';
import { truthy } from '@app/utils/truthy';
import { history } from '@app/history';
import { anonRoutes, routes } from '@app/routes';

// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
// NOTE: we set this to [] in storybook because of a webpack circular dependency that isn't handled correctly via storybook
// https://github.com/webpack/webpack/issues/9173#issuecomment-494903242
const routeConfigs =
  APP_ENV === 'development' ? [] : [...routes.map((r) => ({ path: r.path })), ...anonRoutes.map((r) => ({ path: r.path }))];

export class ErrorReporting {
  static load() {
    if (NODE_ENV === 'development') {
      return;
    }

    FullStory.init({ orgId: 'VH33W' });

    Sentry.init({
      dsn: SENTRY_DSN,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history, routeConfigs, matchPath),
        }),
        new SentryFullStory('nashi'),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,

      environment: APP_ENV,
      release: VERSION,

      maxValueLength: 5000,

      // https://docs.sentry.io/platforms/javascript/data-management/event-grouping/sdk-fingerprinting/
      beforeSend: (event, hint) => {
        const exception = hint?.originalException;

        if (exception instanceof RelayError) {
          event.fingerprint = ['{{ default }}', exception.operationName, JSON.stringify(exception.variables)].filter(truthy);
        }

        return event;
      },

      // ignore updating service worker errors
      ignoreErrors: ['InvalidStateError'],
    });
  }

  static identify(user: { id: string; email: string; name: string }) {
    if (NODE_ENV === 'development') {
      return;
    }

    Sentry.setUser({ id: user.id, email: user.email, name: user.name });

    FullStory.identify(user.id, { email: user.email, displayName: user.name });
  }

  static report(
    error: string | Error,
    severity: 'info' | 'warning' | 'error' | 'fatal' = 'error',
    metadata: {
      [key: string]: any;
    } = {}
  ) {
    if (NODE_ENV === 'development') {
      if (severity === 'error' || severity === 'fatal') {
        console.error(error, metadata);
      } else if (severity === 'warning') {
        console.warn(error, metadata);
      } else {
        console.info(error, metadata);
      }

      return;
    }

    const level =
      severity === 'info'
        ? Sentry.Severity.Info
        : severity === 'warning'
        ? Sentry.Severity.Warning
        : severity === 'error'
        ? Sentry.Severity.Error
        : Sentry.Severity.Fatal;

    if (typeof error === 'string') {
      Sentry.captureMessage(error, {
        level,
        extra: metadata,
      });
    } else {
      Sentry.captureException(error, {
        level,
        extra: metadata,
      });
    }
  }
}
