import { INTERCOM_ID } from '../config';

export class EngagementService {
  static validate() {
    if (!window.Intercom) {
      throw new Error('Intercom not installed on window');
    }
  }

  public static identify(properties: object) {
    EngagementService.validate();
    window.Intercom('boot', {
      app_id: INTERCOM_ID,
      hide_default_launcher: true,
      ...properties,
    });
  }

  public static show() {
    EngagementService.validate();
    window.Intercom('show');
  }
}
