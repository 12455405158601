import * as React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

import { useIntersect } from '../hooks/userIntersect';

type SentinelProps = {
  threshold: number[];
  onThresholdMet: (thresholdValue: number) => void;
} & BoxProps;

const Sentinel: React.FC<SentinelProps> = (props) => {
  const { children, onThresholdMet, threshold, ...rest } = props;

  const [intersectRef, entry] = useIntersect({
    threshold,
  });

  const intersectionRatio = entry?.intersectionRatio;

  React.useEffect(() => {
    if (intersectionRatio !== undefined) {
      onThresholdMet(intersectionRatio);
    }
  }, [intersectionRatio, onThresholdMet]);

  return <Box className="sentinel" ref={intersectRef} {...rest} />;
};

export default Sentinel;
