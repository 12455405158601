import {
  Switch,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import * as React from 'react';

import { SPLITS } from '@app/constants/featureFlipperConstants';
import { useFFOverrides, useTreatments_DEPRECATED } from '@app/contexts/treatmentsContext';

const featureNames = Object.values(SPLITS);

type FeatureFlipperToggleProps = {};

const FeatureFlipperToggle: React.FC<FeatureFlipperToggleProps> = (props) => {
  const [fFOverrides, setFFOverrides] = useFFOverrides();

  const originalFFValues = useTreatments_DEPRECATED(featureNames);

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Text onClick={onOpen}>Feature Flipper Overrides</Text>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Feature Flipper Overrides</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table>
              <Thead>
                <Tr>
                  <Th>Feature Name</Th>
                  <Th>Value</Th>
                </Tr>
              </Thead>

              <Tbody>
                {featureNames.map((fn) => {
                  const originalValue = originalFFValues[fn];
                  const overrideValue = fFOverrides?.[fn];
                  const value = overrideValue || originalValue;
                  const isActive = value === 'on';

                  return (
                    <Tr key={fn}>
                      <Td>{fn}</Td>

                      <Td>
                        <Switch
                          colorScheme="buttonPrimary"
                          isChecked={isActive}
                          onChange={(e) => {
                            setFFOverrides((prev) => {
                              if (!prev) {
                                return {};
                              }

                              const newIsActive = e.target.checked;
                              const originalIsActive = originalValue === 'on';

                              if (
                                // if is the same as the value set in split. delete override
                                newIsActive === originalIsActive
                              ) {
                                delete prev[fn];
                              } else {
                                prev[fn] = newIsActive ? 'on' : 'off';
                              }

                              return prev;
                            });
                          }}
                        />
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FeatureFlipperToggle;
