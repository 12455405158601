import { useBreakpointValue } from '@chakra-ui/media-query';
import * as React from 'react';

import { SideNavStates } from '../components/SideNav';
import { getSideNav, hideSideNav, showSideNav } from '../utils/localStorageUtils';

type NavContextType = {
  displayState: SideNavStates;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onToggle: () => void;
  onClose: () => void;
  forceUpdate: () => void;
};

const useSideNav = (): [SideNavStates, () => void, () => void, () => void, () => void] => {
  const wasSideNavHidden = getSideNav();

  const [displayState, setDisplayState] = React.useState(wasSideNavHidden ? SideNavStates.HIDDEN : SideNavStates.EXPANDED);

  const isTooNarrow = useBreakpointValue([true, true, true, false]) || false;

  const handleMouseEnter = () => {
    if (displayState === SideNavStates.HIDDEN) {
      setDisplayState(SideNavStates.HOVERED);
    }
  };

  const handleMouseLeave = () => {
    if (displayState === SideNavStates.HOVERED) {
      setDisplayState(SideNavStates.HIDDEN);
    }
  };

  const handleToggle = () => {
    if (displayState !== SideNavStates.EXPANDED) {
      setDisplayState(SideNavStates.EXPANDED);
      showSideNav();
    }

    if (displayState === SideNavStates.EXPANDED) {
      setDisplayState(SideNavStates.HOVERED);
      hideSideNav();
    }
  };

  const handleClose = () => {
    setDisplayState(SideNavStates.HIDDEN);
    hideSideNav();
  };

  React.useEffect(() => {
    if (isTooNarrow) {
      setDisplayState(SideNavStates.HIDDEN);
      hideSideNav();
    }
  }, [isTooNarrow]);

  return [displayState, handleMouseEnter, handleMouseLeave, handleToggle, handleClose];
};

const NavContext = React.createContext<NavContextType | undefined>(undefined);

export const useNav = () => {
  const context = React.useContext(NavContext);
  if (!context) {
    throw new Error('useNav must be used within a NavProvider');
  }
  return context;
};

export const NavProvider: React.FC = ({ children }) => {
  const [displayState, onMouseEnter, onMouseLeave, onToggle, onClose] = useSideNav();

  const [, setUpdateKey] = React.useState(0);
  const forceUpdate = React.useCallback(() => setUpdateKey((p) => p + 1), []);

  return (
    <NavContext.Provider
      value={{
        displayState,
        onMouseEnter,
        onMouseLeave,
        onToggle,
        onClose,
        forceUpdate,
      }}
    >
      {children}
    </NavContext.Provider>
  );
};
