import * as React from 'react';
import { IconType } from 'react-icons';
import { Box, BoxProps } from '@chakra-ui/react';

export type IconButtonProps = {
  icon: IconType;
} & BoxProps;

const ICON_BOX_SIZE_PX = 24;
const ICON_BOX_SIZE = `${ICON_BOX_SIZE_PX}px`;

const IconButton: React.FC<IconButtonProps> = (props) => {
  const { icon: Icon, ...rest } = props;
  return (
    <Box height={ICON_BOX_SIZE} width={ICON_BOX_SIZE} minWidth={ICON_BOX_SIZE} {...rest} cursor="pointer">
      <Icon size="95%" />
    </Box>
  );
};

export default IconButton;
