/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type SideNav_organization = {
    readonly offices: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly floorplans: ReadonlyArray<{
            readonly id: string;
            readonly name: string;
            readonly floor: {
                readonly index: number;
            };
            readonly boundary: {
                readonly lineStrings: ReadonlyArray<{
                    readonly __typename: string;
                }>;
            };
        }>;
    }>;
    readonly " $fragmentRefs": FragmentRefs<"OrgDropdown_organization">;
    readonly " $refType": "SideNav_organization";
};
export type SideNav_organization$data = SideNav_organization;
export type SideNav_organization$key = {
    readonly " $data"?: SideNav_organization$data;
    readonly " $fragmentRefs": FragmentRefs<"SideNav_organization">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any;
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "SideNav_organization",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "Office",
                "kind": "LinkedField",
                "name": "offices",
                "plural": true,
                "selections": [
                    (v0 /*: any*/),
                    (v1 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "Floorplan",
                        "kind": "LinkedField",
                        "name": "floorplans",
                        "plural": true,
                        "selections": [
                            (v0 /*: any*/),
                            (v1 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "Floor",
                                "kind": "LinkedField",
                                "name": "floor",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "index",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "Polygon",
                                "kind": "LinkedField",
                                "name": "boundary",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "LineString",
                                        "kind": "LinkedField",
                                        "name": "lineStrings",
                                        "plural": true,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "__typename",
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "OrgDropdown_organization"
            }
        ],
        "type": "Organization",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '800194659b7b7101db9ecb2b03f356e9';
export default node;
