import * as React from 'react';

import { wrapIcon } from '../utils/reactUtils';

function WarningToast(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-255.000000, -896.000000)">
          <g transform="translate(255.000000, 896.000000)">
            <polygon points="0 0 24 0 24 24 0 24"></polygon>
            <path
              d="M12,5.99 L4.47,19 L19.53,19 L12,5.99 Z M13,18 L11,18 L11,16 L13,16 L13,18 Z M11,14 L11,10 L13,10 L13,14 L11,14 Z"
              fill="transparent"
              fillRule="nonzero"
            ></path>
            <path
              d="M12,2 L1,21 L23,21 L12,2 Z M12,5.99 L19.53,19 L4.47,19 L12,5.99 Z M11,16 L13,16 L13,18 L11,18 L11,16 Z M11,10 L13,10 L13,14 L11,14 L11,10 Z"
              fill="#ECD540"
              fillRule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default wrapIcon(WarningToast);
