import * as React from 'react';

import { wrapIcon } from '../utils/reactUtils';

function Comment(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M20,2H4C2.897,2,2,2.897,2,4v18l4-4h14c1.103,0,2-0.897,2-2V4C22,2.897,21.103,2,20,2z" />
    </svg>
  );
}

export default wrapIcon(Comment);
