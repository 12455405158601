/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AppDataRootUserQueryVariables = {};
export type AppDataRootUserQueryResponse = {
    readonly user: {
        readonly id: string;
        readonly email: string;
        readonly organizations: ReadonlyArray<{
            readonly id: string;
        }>;
        readonly " $fragmentRefs": FragmentRefs<"App_user">;
    };
};
export type AppDataRootUserQuery = {
    readonly response: AppDataRootUserQueryResponse;
    readonly variables: AppDataRootUserQueryVariables;
};



/*
query AppDataRootUserQuery {
  user {
    id
    email
    ...App_user
    organizations {
      id
    }
  }
}

fragment App_initializeServices_user on User {
  id
  firstName
  name
  email
  organizations {
    id
    name
    headcount
  }
}

fragment App_user on User {
  id
  type
  customer {
    id
  }
  ...App_initializeServices_user
  ...SideNav_user
}

fragment OrgDropdown_user on User {
  name
  photoUrl
  type
}

fragment SideNav_user on User {
  ...OrgDropdown_user
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
    } as any, v2 = [
        (v0 /*: any*/)
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "AppDataRootUserQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Organization",
                            "kind": "LinkedField",
                            "name": "organizations",
                            "plural": true,
                            "selections": (v2 /*: any*/),
                            "storageKey": null
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "App_user"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "AppDataRootUserQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Customer",
                            "kind": "LinkedField",
                            "name": "customer",
                            "plural": false,
                            "selections": (v2 /*: any*/),
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstName",
                            "storageKey": null
                        },
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Organization",
                            "kind": "LinkedField",
                            "name": "organizations",
                            "plural": true,
                            "selections": [
                                (v0 /*: any*/),
                                (v3 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "headcount",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "photoUrl",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "f4b1d9bce15449ac21f3148e55779f1e",
            "id": null,
            "metadata": {},
            "name": "AppDataRootUserQuery",
            "operationKind": "query",
            "text": "query AppDataRootUserQuery {\n  user {\n    id\n    email\n    ...App_user\n    organizations {\n      id\n    }\n  }\n}\n\nfragment App_initializeServices_user on User {\n  id\n  firstName\n  name\n  email\n  organizations {\n    id\n    name\n    headcount\n  }\n}\n\nfragment App_user on User {\n  id\n  type\n  customer {\n    id\n  }\n  ...App_initializeServices_user\n  ...SideNav_user\n}\n\nfragment OrgDropdown_user on User {\n  name\n  photoUrl\n  type\n}\n\nfragment SideNav_user on User {\n  ...OrgDropdown_user\n}\n"
        }
    } as any;
})();
(node as any).hash = '6f5101cce60b0541fe3efa4e61e5aab4';
export default node;
