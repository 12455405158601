import * as React from 'react';
import { Flex } from '@chakra-ui/react';

const FillCenter: React.FC = (props) => (
  <Flex height="100%" width="100%" justifyContent="center" alignItems="center">
    {props.children}
  </Flex>
);

export default FillCenter;
