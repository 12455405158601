/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type OrgChooserListAllOrganizationsQueryVariables = {};
export type OrgChooserListAllOrganizationsQueryResponse = {
    readonly allOrganizations: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly logo: string;
    }>;
};
export type OrgChooserListAllOrganizationsQuery = {
    readonly response: OrgChooserListAllOrganizationsQueryResponse;
    readonly variables: OrgChooserListAllOrganizationsQueryVariables;
};



/*
query OrgChooserListAllOrganizationsQuery {
  allOrganizations {
    id
    name
    logo
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "allOrganizations",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "logo",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "OrgChooserListAllOrganizationsQuery",
            "selections": (v0 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "OrgChooserListAllOrganizationsQuery",
            "selections": (v0 /*: any*/)
        },
        "params": {
            "cacheID": "f33ce0c25626c510dca1b4d1ed6c593d",
            "id": null,
            "metadata": {},
            "name": "OrgChooserListAllOrganizationsQuery",
            "operationKind": "query",
            "text": "query OrgChooserListAllOrganizationsQuery {\n  allOrganizations {\n    id\n    name\n    logo\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '5eb2f7969640a702d4392c4b09841199';
export default node;
